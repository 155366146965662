import {urlApp} from 'constant';
import md5 from 'md5';
import React, {useEffect, useRef, useState} from 'react';
import {AppState, Image} from 'react-native';
import {useAppSelector} from 'store/hooks';
import {getUuid, widthScreen} from 'utils';
import {getStoreData} from 'utils/storage';
import {generateSoftOTP} from './softOTP';

export function useAsyncState(t: any) {
  const [state, setState] = React.useState(t);
  const preState = useRef<any>(t);
  const funcCallback = useRef<any>(null);
  useEffect(() => {
    if (preState.current != state) {
      preState.current = state;
      if (funcCallback.current) {
        funcCallback.current(state);
      }
    }
  }, [state]);
  function handle(x: any, callback?: void) {
    setState(x);
    funcCallback.current = callback;
  }

  return [state, handle];
}

export function useImageUrl(t: string, method: 'GET' | 'POST', uri?: string) {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getImage(t);
    return () => {};
  }, [t, uri]);

  const getImage = async (url: string) => {
    const idNo = await getStoreData('idNo');
    const phone = await getStoreData('phone');
    const refCode = await getStoreData('refCode');
    const agentCode = await getStoreData('agentCode');
    const secretKey = await getStoreData('secretKey');
    const token = `${md5(`${idNo}${phone}${refCode}${agentCode}${secretKey}`)}`;
    try {
      setLoading(true);
      // const token = await getStoreToken();
      const ul = `${urlApp.APIURL}api/${url}?Origin=${
        urlApp.DomainName
      }&token=${token || 'abc'}&uri=${uri || `${getUuid()}.png`}`;
      Image.getSize(
        ul,
        (width, height) => {
          setData({
            url: ul,
            width: widthScreen,
            height: (widthScreen / width) * height,
          });
        },
        failure => {},
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return [data, loading];
}

export function useSoftOTP(idOrder: string) {
  const [softOtp, setSoftOtp] = useState<string>('');
  const [timingOut, setTimingOut] = useState<number>(0);
  const timer = useRef<any>(null);

  useEffect(() => {
    startTimer();
    getSoftOTP();
    return () => {
      stopTimer();
    };
  }, [idOrder]);

  useEffect(() => {
    if (timingOut == 0 || timingOut == 30) {
      getSoftOTP();
    }
    return () => {};
  }, [timingOut]);

  const getSoftOTP = () => {
    const requestotp = generateSoftOTP(idOrder, timeout =>
      setTimingOut(timeout),
    );
    setSoftOtp(requestotp);
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimingOut(t => t - 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };
  return [softOtp, timingOut];
}

export function useAppState() {
  const appState = useRef(AppState.currentState);
  const [state, setState] = useState<string>('');
  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        setState(nextAppState);
      }
      setState(nextAppState);
      appState.current = nextAppState;
    });
    return () => {
      subscription.remove();
    };
  }, []);
  return [state];
}

export function useHandlePINvsOTP(flowApp: string) {
  const [state, setState] = useState<'PIN' | 'OTP'>('PIN');
  const isSmartOTP = useAppSelector(state => state.authen.isSmartOTP);

  useEffect(() => {
    if (!!isSmartOTP && flowApp != 'Register' && flowApp != 'ForgotPassword') {
      setState('PIN');
    } else {
      setState('OTP');
    }
    return () => {};
  }, []);

  return [state, setState];
}
