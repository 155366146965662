import { stringApp } from 'constant';
export default {
  footerapp: {
    OverviewScreen: `Dashboard`,
    TransactionScreen: `Transactions`,
    InvestmentScreen: `Products`,
    AssetScreen: `Asset`,
    ProfileScreen: `Account`,
  },
  splashscreen: {
    hi: `Welcome to ${stringApp.companyName}`,
    continue: `Next`,
  },
  loginscreen: {
    login: `Log in`,
    saveusername: `Save`,
    forgotpass: `Forgot Password?`,
    signuptitle: `You do you have an account? `,
    signup: `Register now!`,
    accountplacehoder: `Phone number`,
    passwordplacehoder: `Password`,
    fincorp: `${stringApp.companyName}`,
    thefintechdeveloper: `thefintechdeveloper`,
    welcome: `Welcome to ${stringApp.appName}`,
    tendangnhap: `Username`,
    matkhau: `Password`,
    kichhoattaikhoan: `Account activation`,
    warning: ``,
  },
  reviewinfoscreen: {
    thongtinbosung: `Additional Information`,
    hosocanhan: `Profile`,
    thongtincanhan: `Personal information`,
    hoantathosodangky: `Full submission`,
    cccd: `ID Card`,
    //
    thongtintaikhoannganhang: `Bank account information`,
    tenchutaikhoan: `Account name`,
    sotaikhoan: `Account number`,
    tennganhang: `Bank name`,
    chinhanh: `Branch`,
    //
    thongtinnhadautu: `1. Investor information`,
    hoten: `Full Name`,
    gioitinh: `Gender`,
    ngaysinh: `Date of birth`,
    quoctich: `Country`,
    email: `Email`,
    sodienthoai: `Phone`,
    thongtingiayto: `2. ID Card / Securities Trading code`,
    note: `Note: The stock trading code is only for foreigners.`,
    loaigiayto: `Type of document`,
    sohieugiayto: `Document number`,
    ngaycap: `Issued date`,
    noicap: `Issued by`,
    taihinhanh: `Upload your ID `,
    //
    thongtindiachi: `Address information`,
    diachithuongtru: `1. Residential address`,
    quocgia: `Country`,
    tinhthanhpho: `City/Province`,
    quanhuyen: `District`,
    phuongxa: `Ward`,
    sonhatenduong: `Street name, House No.`,
    diachilienhe: `2. Mailing address`,
    giongdiachithuongtru: `Same as residential address`,
    diachikhac: `Other address`,
    trong: ``,
    //
    dieukhoandieukienmotaikhoan: `Terms and Conditions for opening new account`,
    dieukhoansudung: `Terms and Conditions`,
    content: ` `,
    //
    hoantat: `Complete`,
    danhgiamucdoruiro: `Risk assessment`,
  },
  forgotpasswordscreen: {
    forgotpassword: `Forgot Password`,
    content: `Please enter your registered username and follow the instructions`,
    continue: `Next`,
    tendangnhap: `Username`,
  },
  registerscreen: {
    createaccount: `Create new account`,
    fullname: `Full Name`,
    email: `Email`,
    phonenumber: `Phone number`,
    userrefcode: `Referral code`,
    continue: `Next`,
    tieptuc: `Next`,
    signintitle: `Have you got account? `,
    signin: `Log in`,
    dangnhap: `Login!`,
    dangki: `Register`,
    hoantoanmienphivaratdongian: `Free - Simple - Easy`,
    hotendaydu: `Full Name`,
    magioithieucuanguoibanhang: `Reference code`,
    thongtinbatbuoc: `Required information`,
    tendaydubatbuocnhap: `Full name is required`,
    saidinhdangemail: `Error- invalid email address`,
    saisodienthoai: `Error- invalid mobile number`,
    thongtinkhongduocdetrong: `This field is required`,
    titletrong: ``,
    emailkhongduocdetrong: `Email is required `,
    sodienthoaikhongduocdetrong: `Phone number is required `,
    khachhanghienhuu: `Existing customer belongs to ${stringApp.tellerCode}`,
    khachhangchuaphai: `This customer is not a Client of ${stringApp.tellerCode}`,
    vidu: 'Exp',
  },
  activeaccountscreen: {
    kichhoattaikhoan: `Account activation`,
    titletrong: ``,
    sodienthoaidadangky: `Registered phone number`,
    placehoder: `Phone number, exp: 09123456789`,
    tieptuc: `Next`,
    contentactive: `Please provide the correct phone number registered for trading account`,
  },
  setpasswordscreen: {
    craetepass: `Create Password`,
    baomatthongtincanhan: `Personal information security`,
    rule: `Minimum length is 6 characters, include lowercase (a-z) and uppercase (A-Z), contain at least 1 number (0-9) or special symbols`,
    namelogin: `Username`,
    password: `Password`,
    repassword: `Retype password`,
    accept: `Accept`,
    create: `Register`,
    dangnhap: `Log in`,
    taomatkhau: `Create Password`,
    tendangnhap: `Username`,
    rule1: `Minimum length is 6 characters and maximum is 40 characters`,
    rule2: `Include lowercase (a-z) and uppercase (A-Z)`,
    rule3: `Contain at least 1 number (0-9) or special symbols`,
    matkhau: `Password`,
    nhaplaimatkhau: `Retype password`,
    matkhaubaogom: `Password included:`,
    tieptuc: `Next`,
    saidinhdangmatkhau: `Password is invalid rules!`,
    xacnhanmatkhaukhongdung: `Retype password and Password do not match!`,
    titletrong: ``,
    thongtinkhongduocdetrong: `This field is required`,
    vuilongnhapmatkhau: `Please enter your password`,
    vuilongnhaplaimatkhau: `Please re-enter your password`,
    xacthuctaikhoan: `Account verification`,
  },
  otprequestmodal: {
    confirminformation: `Confirmation Transaction`,
    content: `Please enter the verification code (OTP) sent to the phone number`,
    confirm: `Confirm`,
    resendotp: `Resend OTP`,
    contenttiming: `OTP code will expire within`,
    otptimeout: `OTP is expired`,
    vuilongnhapmapinsmartotp: `Please enter your Smart OTP PIN`,
  },
  loading: `Loading`,
  profile: {
    taikhoan: `Account`,
    file: `Profile`,
    logininfo: `Login information`,
    changepassword: `Change password`,
    hosogoc: `Original file`,
    changeemail: `Change email`,
    changepermanentaddress: `Change Permanent address`,
    changeaddress: `Change mail address`,
    logout: `Log out`,
    //trangthaitkdautu: `Investment account status:`,
    hosomotaikhoan: `Application form`,
    sotaikhoan: `Account No - `,
    contentlogininfo: `Are you sure you want to update your editing?`,
    change: `Change`,
    email: `Email`,
    phone: `Mobile phone number`,
    password: `Password`,
    userref: `Referral code`,
    cannotchange: `Cannot change`,
    chukyso: `Digital signatures`,
    // list
    trangthaihoso: `Document status`,
    thongtintaikhoan: `Profile`,
    thaydoimatkhau: `Change password`,
    thaydoisodienthoai: `Change phone number`,
    thaydoiemail: `Change email`,
    hotrokhachhang: `Call center`,
    hotline: `Hotline`,
    caidatvabaomat: `Setting & security`,
    dangxuat: `Log out`,
    //
    oldpassword: `Old Password`,
    newpassword: `New Password`,
    renewpassword: `Confirm New password`,
    save: `Save`,
    rulepassword: `Password contains \n* Minimum length is 6\n* Includes lowercase letters (a-z) and uppercase letters (A-Z)\n* Contains at least one number (0-9) or one special character`,
    //
    oldemail: `Old email`,
    newemail: `New email`,
    renewemail: `Confirm new email`,
    //
    address: `Address No  and Street`,
    placeholderaddress: `Enter address no and street name`,
    nation: `Country`,
    city: `Province/City`,
    district: `District`,
    commune: `Ward`,
    emailsaidinhdang: `Email is invalid format`,
    matkhauchuadumanh: `Your password is not strong.`,
    //
    saidinhdangmatkhau: `Password is invalid rules!`,
    xacnhanmatkhaukhongdung: `Retype password and Password do not match!`,
    titletrong: ``,
    thongtinkhongduocdetrong: `This field is required`,
  },
  // account verify screen
  accountverify: {
    thongtintaikhoan: `Account Information`,
    sothithucnhapcanh: `Visa No.`,
    thongtintochuc: `Organization information`,
    thongtintaikhoannganhang: `1. Bank account information`,
    thongtinkhac: `2. Other information`,
    thongtincanhan: `Personal information`,
    thongtinnganhang: `Bank account information`,
    thongtindiachi: `Address information`,
    xacthuchoantat: `Verification completed`,
    hopdongdientu: `Registration Form`,
    thongtinbosung: `Additional Information`,
    giaykhaosatipo: `Opinion form for investors in the IPO period`,
    // trangthaitkdautu: `Account status`,
    vuilongchonquocgia: `Please select a country`,
    vuilongchontinhthanhpho: `Please select a province/city`,
    vuilongchonquanhuyen: `Please select a county/district`,
    vuilongchonphuongxa: `Please select a ward`,
    chondiachilienhe: `Mailing address`,
    vuilongchondiachilienhe: `Select address`,
    danhgiamucdoruiro: `Risk assessment`,
    guithongtin: `Send information`,
    //
    nghenghiep: `Occupation`,
    chucvu: `Title`,
    mucthunhaphangthang: `Monthly income`,
    nguontiendautu: `Source of investment`,
    //
    sonhatenduong: `Street name, House No.`,
    hosogoc: `Original file`,
    fatca1:
      'The investor is a US citizen or an entity established or operating in the United States (FATCA)',
    fatca2:
      'The investor is NOT a US citizen or an entity established or operating in the United States (NONE FATCA)',
    quocgia: `Country`,
    tinhthanhpho: `City/Province`,
    tinhthanhphotheodiachilienhe: `City/Province (Mailing address)`,
    quanhuyen: `District`,
    phuongxa: `Ward`,
    // account info modal
    thongtinnhadautu: `1. Investor information`,
    hoten: `Full Name`,
    ngaysinh: `Date of birth`,
    gioitinh: `Gender`,
    quoctich: `Country`,
    nam: `Male`,
    nu: `Female`,
    email: `Email`,
    sodienthoai: `Phone number`,
    thongtincmnd: `2. ID Card / Securities Trading code`,
    loaigiayto: `Type of document`,
    sohieugiayto: `Document number`,
    ngaycap: `Issued date`,
    cmnd: `ID Card`,
    magiaodichchungkhoan: `Trading Code`,
    noicap: `Issued by`,
    taihinhanh: `Upload a picture of your ID Card/Securities Trading code`,
    notetaihinhanh: `The Identity paper are still valid, have enough light, are original photos, not scanned and copied`,
    taianhmattruoc: `Upload a photo of the front`,
    taianhmatsau: `Upload a photo of the back`,
    vuilongchonloaigiayto: `Please select document type`,
    chuy: `Note: The stock trading code is only for foreigners.`,
    // bank info modal
    tenchutaikhoan: `Account name`,
    sotaikhoan: `Account number`,
    tennganhang: `Bank name`,
    chinhanh: `Branch`,
    content: `This required information and account information is transferred when the sell order is executed.`,
    vuilongchonnganhang: `Please select a bank`,
    vuilongchonchinhanh: `Please select a branch`,
    // address info modal
    diachilienhe: `Mailing address`,
    giongdiachithuongtru: `Same as residential address`,
    diachikhac: `Other address`,
    diachithuongtru: `Residential address`,
    // quyyen
    dieukhoan: `Rules`,
    xacnhanhoantat: `Verification completed`,
    hoantat: `Complete`,
    toidongyvoidieukhoantren: `I agree to the terms and conditions.`,
    contentinhoso: `Please print, sign and mail to the company's address in the contact section!`,
    tongdongyvoidieukhoanfatca: `I agree to answer: No to all questions related to FATCA`,
    contentdiachi1: `Once this step is completed, Registration Form will be sent to registered email directly: `,
    titleconfirm: `To start making transactions, you need to confirm the information and agree to the terms and conditions below:`,
    //
    contentconfirm: ``,
    save: `Save`,
    thongtinphongchongruatien: `1. Additional information in compliance with the AML`,
    nhadautucophailanguoinhanuythac: `The Investor is authorized by any foreign entity/individual (*)`,
    hovaten: `Full name`,
    Email: `Email`,
    sofax: `Fax number`,
    sohohieugiaychungnhandkgd: `Passport No./Registration Certificate`,
    masothue: `Tax code`,
    quocgiadongthue: `Tax payer country`,
    diachi: `Address`,
    loaihinhnhadautu: `2. Type of investor`,
    nhadautuchungkhoankhongchuyenghiep: `Non-Professional Investor`,
    nhadautuchungkhoanchuyennghiep: `Professional Investor`,
    co: `Yes`,
    khong: `No`,
    source: `sourceEn`,
    monthlyIncom: `monthlyIncomEn`,
    chinhsachcotuc: `Dividend policy`,
    dividendcontent: `Investors can choose to transfer cash dividends to their account or reinvest. Dividends will be reinvested if no method is selected`,
  },
  transactionscreen: {
    ORDER_INIT: `Init`,
    ORDER_PENDING: `Pending`,
    ORDER_APPROVE: `Pending`,
    ORDER_CANCEL: `Cancel`,
    ORDER_ACCEPT: `Pending`,
    ORDER_RECONCILED: `Reconciled`,
    ORDER_PLEDGE: `Pledge`,
    ORDER_CANCEL_PLEDGE: `Cancel Pledge`,
    ORDER_REJECT: `Reject`,
    // sao ke
    saokegiaodich: `Fund Statement`,
    chonquy: `Select Product`,
    theothoidiemgiaodich: `Transaction time`,
    saoke: `Export`,
    tungay: `From date`,
    denngay: `as of`,
    //
    giaodich: `Transactions`,
    lenhchoxuly: `Pending \norders`,
    lichsugiaodich: `Transaction history`,
    lenhchomua: `Subscribe`,
    lenhchoban: `Redeem`,
    lenhchochuyendoi: `Switch`,
    lenhchuyendoi: `Switch`,
    tongsolenh: `Number of Order(s):`,
    quychuongtrinh: `Fund - Program`,
    soluong: `Units`,
    sotienmua: `Amount`,
    sotienmuavnd: `Subscription amount ${stringApp.currencyEn}`, // `Số tiền mua (VNĐ)`,
    ngaydatlenh: `Order date`,
    trangthai: `Status`,
    nhapmalenh: `Enter order code`,
    ngay: `Date`,
    thoidiemgiaodich: `Transaction time`,
    chuacogiaodich: `You have not created an order yet`,
    // order buy detaisl
    thongtindautu: `Investment information`,
    quydautu: `Investment fund`,
    chuongtrinh: `Program`,
    loailenh: `Order type`,
    phiengiaodich: `Trading session`,
    thongtinchuyenkhoan: `Bank transfer information`,
    taikhoanthuhuong: `Beneficiary account`,
    tenthuhuong: `Beneficiary`,
    sotaikhoan: `Account number`,
    tennganhang: `Bank name`,
    noidung: `Description`,
    phuongthucchuyenkhoan: `You are choosing a bank transfer method.`,
    soccqban: `Units`,
    soccq: `Units`,
    ngaymua: `Subscription date`,
    tgnamgiu: `Holding time`,
    slban: `Redemption units`,
    phi: `Fee`,
    quymuctieu: `Target Fund`,
    giatriuoctinh: `Estimated value`,
    socqqchuyendoi: `Units Switch`,
    tongtien: `Amount`,
    ngaygiaodich: `Trading date`,
    malenh: `Code`,
    nav: `NAV/Unit`,
    sotien: `Subscription Amount`,
    navkytruoc: `NAV of Last period`,
    locgiaodich: `Transaction Filter`,
    xoa: `Delete`,
    huy: `Cancel`,
    apdung: `Apply`,
    theoloailenh: `Order type`,
    tatca: `All`,
    lenhmua: `Subscription Order`,
    lenhban: `Redemption Order`,
    lenhmuahoandoi: `Switch-In Order`,
    lenhbanhoandoi: `Switch-Out Order`,
    lenhhoandoi: `Switching Order`,
    toingay: `To date`,
    taolenhmua: `Subscribe order`,
    taolenhban: `Redeem order`,
    taolenhchuyendoi: `Switch order`,
    // dinh ky
    dinhky: `SIP`,
    tinhtrang: `Status`,
    sotiendangkydautu: `Amount`,
    sokydaututoithieu: `Min Invested Term`,
    sokydadautu: `Succeed Term`,
    sokylientuckhongthamgia: `Missing Term`,
    kydaututieptheo: `Next term`,
    copy: `COPY`,
    nganhang: `Bank`,
    chinhanh: `Branch`,
    nguoihuongthu: `Beneficial owner`,
    lichsukhoplenh: `History Order`,
    taiphieuxacnhan: `Download confirmation slip`,
    kydautu: `Period`,
    sotiendautu: `Amount`,
    ngaykhoplenh: `Trading session`,
    danhantien: 'Received money confirm',
    choxacnhantien: 'Waiting to confirm receiving money',
  },
  // setting screen
  settingscreen: {
    caidatvabaomat: `Setting & security`,
    ngonngu: `Language`,
    nhanthongbao: `Receive notification`,
    faceid: `Face ID/Touch ID`,
    thongbao: `Notification`,
    content: `Please enter your password to activate this function`,
    matkhau: `Password`,
    huy: `Cancel`,
    xacnhan: `Confirm`,
    kichhoatthanhcong: `You have successfully activated fingerprint/face login`,
    xacnhanhuykichhoat: `Are You Sure You Want To Disactivate Touch Id/Face Id`,
    matkhaucuaquykhachkhongdung: `Password is invalid`,
    yeucauxoataikhoan: `Account delete request`,
    yeucauxoa: `Delete request`,
    content1: `<p>After deleting your account, you can't <strong>restore</strong> it.</p>`,
    content2: `<p>After deleting your account, you can't reuse the same <strong>phone number</strong> and <strong>ID number</strong> for a new account.
    </p>`,
    content3: `<p>You can only delete your account without having asset at <strong>${stringApp.companyName}</strong>.</p>`,
  },
  supportscreen: {
    hotrokhachhang: `Call center`,
    cskh: `Customer care`,
    email: `Email`,
    facebook: `Facebook`,
    trungtamchamsockhachhang: `Customer care Center`,
    chuyenvientrogiup: `Consultant`,
    hoten: `Full Name`,
  },
  investmentscreen: {
    dautu: `Invest`,
    sanpham: `Products`,
    giadonviquykytruoc: `Current NAV`,
    ngay: `Date`,
    dautungay: `Invest now`,
    giatrihientai: `Market value`,
    danhsachphiengiaodich: `List of trading sessions`,
    phiengiaodich: `Trading session`,
    taolenhmua: `Subscribe order`,
    thaydoisovoidaunam: `Change to beginning of the year`,
    taingay: `to date`,
    Taingay: `at date`,
    giatriNAVCCQ: `Last unit price of Fund certificate ${stringApp.currencyEn}`, // `Giá đơn vị quỹ gần nhất (VNĐ)`,
    NAVtaingay: `NAV as at`,
    tangtruongNAVCCQ: `NAV/Fund certificate growth - `,
    NAVcaonhat: `Highest value`,
    NAVthapnhat: `Lowest value`,
    khongconoidunghienthi: `No Data`,
    thang: `Month`,
    nam: `Year`,
    tatca: `All`,
    NAV: `NAV/Unit`,
    navccq: `NAV/Unit`,
    thongtinquy: `Fund information`,
    xemchitiet: `See details`,
    xemthem: `Show more`,
    trangthai: `Status `,
    phieulayykienndt: `Opinion form for investores in the IPO period: `,
  },
  createordermodal: {
    thanhtoan: "Payment",
    sotiendangkymualaboisocua: `The subscription amount is a multiple of`,
    huongdanthanhtoanbangqrcode: `Instructions for payment by QR Code`,
    giatrimua: `Subscription Value`,
    nhapmatuvanvien: `Input referral code`,
    tudongtieptucdautu: `Monthly automatic order`,
    ngaythanhtoanhangthang: `Start date`,
    datlenhban: `Redeem order`,
    datlenhmua: `Subscribe order`,
    datlenhchuyendoi: `Switch order`,
    matuvanvien: `Referral code`,
    xacnhan: `Confirm`,
    datlenh: `Place Order`,
    ketqua: `Result`,
    hoac: `Or`,
    thanhtoannhanhquaqrcode: `Scan QR to transfer`,
    huongdanchuyenkhoan: `Transfer instructions`,
    xemqrcode: `View QR code`,
    taive: `Download`,
    motsonganhangchuahotroquetmaqr: `Note: Some banks do not support QR payment yet`,
    quykhachvuilongdoichieuthongtintruockhichuyenkhoan: `Please check the information before transferring`,
    quaylai: `Back`,
    bieuphimua: `Subscription fee`,
    thoigiandautulientuctoithieu: `Minimum continuous investment period`,
    khoplenhtheosotienchuyentienthucthe: `Execution of orders according to actual remittance amount`,
    thang: `Month`,
    bieuphichuyendoi: `Switching fee`,
    bieuphiban: `Redemption fee`,
    thoigiannamgiu: `Holding time`,
    // order buy
    ngaybatdauthamgia: `Start date`,
    ccq: `Units`,
    tudongtaolenhdinhkyhangthang: `Automatically generate monthly recurring orders`,
    chonsanpham: `Select product`,
    chonchuongtrinh: `Select program`,
    nhapsotienmua: `Please input subscription amount`,
    sotiendaututoithieu: `Minimum investment amount`,
    phimua: `Fee`,
    phichuyendoi: `Switching fee`,
    xembieuphi: `View fee scheme`,
    thoidiemdongsolenh: `Cut-off time`,
    nhadautuvuilongchuyentientruoc: `Please transfer money before`,
    thoidiemdongsolenhnhantien: `Investor's purchase money must be credited to the Fund's account before`,
    phiengiaodich: `Trading session`,
    thongtindautu: `Investment information`,
    quydautu: `Investment fund`,
    chuongtrinh: `Program`,
    loailenh: `Order type`,
    ngaydatlenh: `Order date`,
    sotienmua: `Subscription amount`,
    phuongphapthanhtoan: `Payment methods`,
    chuyenkhoanquanganhang: `Bank transfer`,
    vuilongchuyentientruoc: `Investor should transfer money before trading date`,
    thongtinchuyenkhoan: `Bank transfer information`,
    luuyttck: `Please copy the correct information to ensure the order is executed.`,
    tenthuhuong: `Beneficial Owner`,
    sotaikhoan: `Account number`,
    nganhang: `Bank`,
    noidung: `Description`,
    mua: `Subscribe`,
    datlenhmuathanhcong: `Your subscription order was successful`,
    camonquykhachdadautuvao: `Thank you for your investment in`,
    muathem: `Subscribe Continue`,
    hoantat: `Complete`,
    xacnhanthanhtoan: `Payment confirmation`,
    contentxacnhanthanhtoan: `Investors confirm the payment information for the investment with the following transfer information:`,
    // order sell
    nhapsoluongban: `Please input redemption units`,
    soluongtoithieukhongduoi: `Minimum units not less than`,
    soluongkhadung: `Available Quantity`,
    ngaymua: `Subscription date`,
    tgnamgiu: `Holding time`,
    slban: `Redemption units`,
    phi: `Fee`,
    phiban: `Redemption fee`,
    soluongban: `Redemption units`,
    ban: `Redeem`,
    giatriuoctinhsauthuephi: `Estimated value after taxes, fees`,
    datlenhbanthanhcong: `You have successfully placed a sell order`,
    camonquykhach: `Thank you`,
    // create order transder
    thongtinccqchuyendoi: `Source Fund Information`,
    chonccqchuyendoi: `Select the switch fund`,
    navkitruoc: `NAV of Last period`,
    soluongchuyendoi: `Units`,
    thongtinccqmuctieu: `Target Fund Information`,
    chonccqmuctieu: `Select target fund`,
    thongtinchuyendoi: `Switch information`,
    chuyendoi: `Switch`,
    datlenhchuyendoithanhcong: `You have successfully placed a switch order`,
    navccqkitruoc: `Current NAV/Units`,
    tatca: `All`,
    tieptuc: `Next`,
    thoigianchuyentien:
      'Investors transfer money to the account before 14h30 on the 15th of every month',
    co: `Yes`,
  },
  assetscreen: {
    tongquantaisan: `Property Overview`,
    sl: `Quantity :`,
    chitiet: `Detail`,
    ccq: `Units`,
    giamua: `Buying NAV`,
    danhsachlenhmuadangnamgiu: `List of holding subsciption orders`,
    tongsodanhsach: `Total list:`,
    quychuongtrinh: `Fund - Program`,
    phiengiaodich: `Trading session`,
    tonggiatridangnamgiu: `Total holding value`,
    tonggiatridangchokhop: `Total pending redemption amount`,
    tongtaisan: `Total asset`,
    soluong: `Units`,
    giatridaututb: `AVG investment`,
    navkitruoc: `Current NAV`,
    giatrihientai: `Market value`,
    loilophantram: `Gain/loss(%)`,
    loilodong: `Gain/loss(VND)`,
    lenh: `Command`,
    chuongtrinh: `Program`,
    // details
    sotkdautu: `Account number`,
    giatrithitruong: `Market value`,
    sldonviquy: `Units`,
    sotiendadautu: `Amount invested`,
    sotiendadautuconlai: `Remaining invested amount`,
    ngaygiaodichdautien: `First trading day`,
    lailodathuchien: `Realized Gain/loss`,
    lailochuathuchien: `Unrealized Gain/loss`,
    loilo: `Gain/loss`,
    tongdautu: `Total investment`,
  },
  overviewscreen: {
    tongquantaisan: `Property Overview`,
    tongsocqq: `Total units`,
    giatrihientai: `Market value`,
    loilo: `Gain/loss`,
    banchuacotaisan: `You don't have any assets`,
    contentnondata: `You need to participate in the investment and make transactions by going to the "Investment" section below`,
    tonggiatridaututb: `Total investment amount`,
    sotiendautu: `Total cost of capital`, // `Số tiền đầu tư (VNĐ)`,
    sotiendautuvnd: `Total cost of capital ${stringApp.currencyEn}`, // `Số tiền đầu tư (VNĐ)`,
    tongloilo: `Total gain/loss`,
    taisan: `Asset`,
    soluongCCQ: `Units`,
    NAVhientai: `NAV/Unit`,
    tonggiatridaututrungbinh: `Total cost of capital ${stringApp.currencyEn}`, // `Số tiền đầu tư (VNĐ)`,
    giamuatrungbinh: `Average buying NAV/Unit`,
    tonggiatrithitruong: `Total Market Value ${stringApp.currencyEn}`, // `Tổng giá trị thị trường (VNĐ)`,
    ccq: 'Units',
  },
  notificationscreen: {
    thongbao: `Notification`,
    huy: `Cancel`,
    xoamucdachon: `Delete selected item`,
  },
  MiniEkycModal: {
    xacthucthongtin: `Verify information`,
  },
  controlekyc: {
    dangnhap: `Log in`,
    xacthucthongtin: `Verify information`,
    content: `To complete the account opening on ${stringApp.appName}, please follow the steps below to verify your information`,
    chuphinhmattruoc: `1. Take pictures of the front and back of your ID Card`,
    chuphinhchandung: `2. Take a portrait photo`,
    batdau: `Start`,
    thuchiensau: `Do it later`,
  },
  digitalsignature: {
    chukyso: `Digital signatures`,
    xemgiaydkgd: `View BUSINESS REGISTRATION`,
    uploadanh: `Upload image`,
    kyso: `Digital signature`,
    xoa: `Delete`,
    xacnhan: `Confirm`,
    contentnoti: `Please sign in the area above`,
    giaydkgd: `BUSINESS REGISTRATION`,
    mayanh: `Camera`,
    thuvien: `Library`,
    huy: `Cancel`,
    chonhinhanh: `Select image`,
    taive: `Download`,
    hopdongdientu: `Registration Form`,
    hopdongdientutype2: `Registration Form`,
    xemtruoc: `Preview`,
    kydientu: `Sign now`,
    taichuky: `Upload signature`,
    chukycuaban: `Your signature`,
    taihopdongdaky: `Download signed Account Opening Contract.`,
    taihopdongmotaikhoan: `Download Account Opening Contract.`,
    contentdownload: `Please effectuate eSign to complete the opening trading account process.`,
    contentdownload2: `Your registration for opening a trading account has been confirmed electronically. Investors can download the signed trading account registration form by clicking the button below`,
  },
  digitalsignatureipo: {
    giaydangkymuaipo: `Opinion form for investors in the IPO period`,
    taigiaydangkymuaipo: `Download the IPO period investor opinion form`,
    contentdownload: `Your IPO investor opinion form is confirmed and signed electronically. Investors can download the IPO Investor Opinion Form by clicking the button below`,
    taigiaydangkymuaipo2: `Please preview the information on the IPO Investor Opinion Form and sign it electronically by clicking the 'Electronic Sign' button below.`,
  },
  investmentlibraryscreen: {
    thuviendautu: 'Investment Library',
    xemthem: 'Show more',
    baivietlienquan: 'Related Posts',
  },
  feetable: {
    bieuphi: `Tariffs`,
  },
  softotpscreen: {
    dangkysmartotp: 'Register for smart-OTP',
    dangkymapin: `Register your PIN`,
    vuilongnhapmapin: `Please enter your PIN`,
    nhaplaimapin: `Re-enter the PIN`,
    xacnhan: `Confirm`,
    maxacthucgiaodich: `Authentication code`,
    smartotp: `Smart OTP`,
    smartotptimeout: `Mã OTP sẽ tự động cập nhật The OTP code will automatically update later`,
  },
  alert: {
    hetphiengiaodich: `This session has ended. Please log in again !`,
    ngaycap: `The date of issue must not be less than the date of birth`,
    xoataikhoanthanhcong: `Deactivate account is completed`,
    vuilongchondayduthongtin: `Please fill in full information `,
    vuilongnhapdayduthongtinbosung: `Please enter full additional information`,
    kythanhcong: `Esign complete`,
    capnhatmucdoruirothanhcong: `Update Risk assessment success`,
    taochukysothanhcong: `Esign complete`,
    logout: `Log out`,
    dinhdanganhkhongphuhop: `The signature image you uploaded is malformed. Please choose image files with the format: .png,.jpg, .jpeg`,
    desau: `Later`,
    taithanhcong: `Download complete`,
    kydientu: `Sign now`,
    contentlogout: `Are you sure you want to log out?`,
    dong: `Close`,
    xoalenh: `Delete order successfully`,
    doimatkhauthanhcong: `Change password success`,
    doiemailthanhcong: `Change email success`,
    capnhatdiachithanhcong: `Update address successfully`,
    vuilongnhapdayduthongtindiachi: `Please enter full address information`,
    vuilongnhapdayduthongtincanhan: `Please enter full personal information`,
    capnhatthongtincanhanthanhcong: `Personal information updated successful`,
    dangxuat: `Log out`,
    lichxong: `Done`,
    // thong bao
    thongbao: `Notification`,
    dungluongtoida: `Maximum file to upload is 5MB`,
    vuilongnhapdayduthongtintaikhoannganhang: `Please enter full information of beneficiary account`,
    dongy: `Ok`,
    huybo: `Cancel`,
    thulai: `Retry`,
    vuilongnhapmatkhau: `Please enter your password`,
    vuilongnhaptendangnhap: `Please enter your username`,
    dasaochepvaobonhodem: `Copied to cache`,
    dangnhapvantaythatbai: `Fingerprint/Face login is not installed. Please login with password and check again`,
    thatbai: `Touch ID sign in Fail`,
    daxayraloi: `Error! Please try again later`,
    dangkytaikhoanthanhcongekyc: `You have successfully registered an account. Your profile has been approved.`,
    dangkytaikhoanthanhcong: `You have successfully registered an account. Your profile will be approved according to the time specified by the Fund Management Company.`,
    // order
    matkhaucuphaikhacmatkhaumoi: `The old password must be different from the new password`,
    emailcuphaikhacemailmoi: `Old email must be different from New email`,
    xacnhanxoalenhgiaodich: `Confirm delete transaction order?`,
    capnhattaikhoannganhang: `Successfully updated bank account information`,
    capnhatthongtintaikhoannganhangthanhcong: `Investors contact the Fund Management Company to update the bank account`,
    chuadutuoi: `According to regulations on age to be traded, the Fund must be at least 18 years old. We regret to reject investor registration information.`,
    nhapotpquasoluong: `Wrong OTP more than 3 times. For security reasons please login again.`,
    registernhapquaotp: `Your transaction has expired. Please create a new transaction`,
    dangnhapsai: `Invalid username or password. Please try again`,
    ekycfail: `We are unable to verify the information you are providing because the image quality is not good. Please do it again!`,
    ekycfail3: `Currently, we cannot verify your information because of bad quality pictures. Please enter your personal information manually and submit to create an account.`,
    capnhatthongtinbosungthanhcong: `Update Additional Information success !`,
    capnhatungdung: `Please update to the latest version to use ${stringApp.appName}`,
    chungminhhethan: `Your ID Card is expired `,
    USING_OTHER_DEVICE: `Investors log in on another device. Do you want to receive notifications on this device`,
    vuilongdangnhapdexemthongbao: `Please login to view notification`,
    chucnangthongbao: `Please go to setting to turn on notification!`,
    chucnangvantay: ``,
    mapinkhongdung: `PIN code is incorrect. Please re-enter!`,
    vuilongtruycapvaocaidat: `Please go to app-setting!`,
    huythanhcongsmartotp: `You have successfully canceled Smart OTP`,
    sudungchucnangsmartotp: `To use this function, please go to Settings to turn on notifications!`,
    dangkythanhcongsmartotp: `You have successfully registered for Smart OTP!`,
    thietbikhonghople: `Application is not support this device ! Please use Website`,
  },
  //
  component: {
    ngay: `Date`,
    thang: `Month`,
    nam: `Year`,
    goiy: `Hint`,
    currency: `${stringApp.currencyEn}`,
    tren: 'Above',
    tu: 'From',
    ngaytrolen: 'days above',
    duoi: 'Below',
    nhohonhoacbang: 'Less than or equal',
    trolen: 'above',
    ngay1: 'days',
    chuaxacthuc: 'Not authenticated',
    INVESTMENT_PROFILE_PENDING: 'Pending',
    INVESTMENT_PROFILE_APPROVE: 'Approved',
    INVESTMENT_PROFILE_ACCEPT: 'Approved',
    INVESTMENT_PROFILE_REJECT: 'Reject',
    dangnhapbangvantay: `Use your Touch ID/Face ID to unlock ${stringApp.appName}`,
    dangnhapvaoapp: `Login ${stringApp.appName}`,
    daky: 'Full submission',
    chuaky: 'No submission',
    timkiemquydautu: 'Search fund',
    chuongtrinhdaututudongvalientuc:
      'programme is an automatic and continuous investment program',
    lenhmuadinhky: `- You do not need to create a buy order, but only transfer to the Funds receiving account to perform periodic buy orders`,
    luuy: 'Note',
    thoidiemnhantien: `You need to transfer money to invest for SIP of term `,
    truoc: 'before',
    kichhoatvantay: `Enable your Touch ID/Face ID for app login`,
    vantaykhuonmat: `Touch ID/Face ID for ${stringApp.appName}`,
    goi: `Call`,
    dangthamgia: `Active`,
    dadung: 'Stop',
    riskAssessment: `riskAssessmentEn`,
    huysmartotp: `Delete smart-OTP`,
    Dividend: `DividendEn`,
    thaydoivantay: `Biometric in device has been changed, please log in again with your password !`,
  },
  ERROR_BE: {
    NOT_DELETE: `This is Root system account, you cannot delete`,
    CREATED: `You have registered account successfully`,
    NO_CONTENT: `Cannot found data`,
    NOT_FOUND: `Cannot found data`,
    BAD_NEW_PASSWORD: `Password includes lowercase (a-z) and uppercase  (A-Z) letters, contain at least some numbers (0-9) or special symbols and Minimum length is 6 characters`,
    CONFIRM_PASSWORD_NOT_MATCH: `The re-entered password does not match.`,
    USER_IS_INACTIVE: `Your account has not been activated. Please seek customer service for support`,
    USER_IS_WAITING_ADMIN_APPROVE: `Your account is pending KYC`,
    INVALID_OLD_EMAIL: `The current email is not match`,
    BAD_REQUEST: `The access link is not found`,
    INVALID_USER: `Username or Password is invalid`,
    INVALID_IP: `Invalid IP address. Please use the system authorized ID address`,
    CAN_NOT_SAVE_USER: `Registration account is failed, Please try again`,
    INVALID_USER_TYPE: `Invalid User Type`,
    INVALID_STATUS: `Invalid status`,
    INVALID_INVESTOR_TYPE: `Invalid Investor Type`,
    INVALID_ISSUER_TYPE: `Issued Place is invalid`,
    INVALID_DATA_PROVINCE: `Selected Province is invalid`,
    INVALID_DATA_COUNTRY: `Selected Country is invalid`,
    INVALID_ADMIN_BANK: `Bank is invalid`,
    CONFLICT_ADMIN_BANK_NUMBER: `Bank account is invalid`,
    INVALID_DATA_BANK: `Bank is invalid`,
    CONFLICT_DATA_BANK_STOCK_NAME: `Bank Code is invalid`,
    INVALID_PRODUCT: `Fund is not exist in system`,
    CONFLICT_PRODUCT_CODE: `Fund Code is not exist in system`,
    UPLOAD_CONTRACT_FAIL: `Uploading file occurred error`,
    INVALID_SEARCH_TIME: `Searching Period is invalid`,
    INVALID_MAXIMUM_PERIOD: `Maximun Period is 180 days`,
    INVALID_ORDER: `Transaction cannot found`,
    CONFLICT_ORDER_CODE: `Transaction Code cannot found`,
    INVALID_ORDER_TYPE: `Transaction Type cannot found`,
    INVALID_OTP: `OTP is invalid`,
    EXPIRED_OTP: `OTP is expired. Please click Resend OTP to get a new OTP`,
    OTP_LOCKED: `Your session is expired, please refresh and create a new transaction`,
    OTP_IS_SENT: `The one-time authentication code (OTP) that has been sent to you`,
    OTP_IS_NOT_SENT: `OTP is not sent`,
    OTP_INVALID_TRANS_ID: `Invalid OTP code`,
    INVALID_USER_STATUS: `Your session is timeout, please re-login and try again`,
    PASSWORD_NOT_MATCH: `The old password is incorrect`,
    INVALID_PRODUCT_STATUS: `Status of Fund is invalid`,
    INVALID_EMAIL_SUBJECT: `Email subject is incorrect`,
    INVALID_EMAIL: `Email format is invalid`,
    NOT_EXISTS_EMAIL: `Email is not exist`,
    EMAIL_ALREADY_REGISTERED: `The email is exist, please try with another email address`,
    INVALID_PHONE_POSTAL: `Area Code is invalid`,
    PHONE_IS_NOT_VIETNAM: 'Please use Vietnam`s Phone number',
    EMAIL_NOT_REGISTER: `Email is not exist`,
    CONFLICT_BUY_AUTO: `Your SIP transaction has registered auto-investment`,
    INVALID_ID_TYPE: `Document Type is invalid`,
    INVALID_PHOTO: `Document information is invalid`,
    INVESTMENT_PROFILE_CANT_CHANGE: `Investment Account is pending KYC or KYCED`,
    INVALID_BANK: `Beneficiary Bank is not exist`,
    INVALID_DATA_DISTRICT: `District is invalid`,
    INVALID_DATA_WARD: `Ward is invalid`,
    INVALID_USER_INFO: `Cannot found user information`,
    INVALID_ID_TYPE_FOR_USER_TYPE: `Document Type is not match with Account Type`,
    INVALID_PRODUCT_PROGRAM: `Fund Program is invalid`,
    INVALID_BUY_MONEY: `The amount must be greater or equal than the minimum subscription amount.`,
    INVALID_SELL_PROGRAM: `Fund Program is inactived`,
    INVALID_SELL_PRODUCT: `Inactive Product`,
    INVALID_SWITCH_PROGRAM:
      'This fund`s investment program does not allow conversion to other funds.',
    INVALID_BUY_SCHEME: `This Fund Program does not allow to register auto-investment`,
    INVALID_SOURCE: `Source is invalid`,
    INVALID_VOLUME: `Available Units does not enough to redeem`,
    INVALID_VOLUME_HOLDING_MIN: `Limit holding units must be equal or more than minimum holiding units`,
    INVALID_CUT_OFF_TIME: `You cannot delete this transaction because system already closed trading session`,
    INVALID_ORDER_STATUS: `Transaction already approved or submitted`,
    INVALID_TRANSACTION: `Transaction is not exist`,
    INVALID_DEST_PRODUCT: `The target fund is invalid. Please try with another target fund`,
    INVALID_SELL_DEST_PRODUCT: `Target Fund is inactivated`,
    INVALID_DES_PRODUCT_PROGRAM: `Target Fund Program is invalid`,
    INVALID_SELL_DES_PROGRAM: `Target Fund Program is inactivated`,
    INVALID_SWITCH_SAME_PROGRAM: `For switching, source program and target program must be program`,
    INVALID_VOLUME_BALANCE: `The remaining number of fund units is less than the minimum of redemption amount. Investor can sell all existing Fund units`,
    INVALID_PASSWORD: `Old password is invalid`,
    INVALID_VOLUME_SELL: `Invalid number of current Fund units.`,
    EXPORT_ERROR: `Occur error during process`,
    INVALID_APPROVE_RECEIVED_MONEY_ERROR: `Transaction Id is not exist`,
    INVALID_INVESTMENT_HARD_PROFILE: `Fund Company still not receive your hardcopy document. Please contact salesperson or call center for help`,
    INVALID_TEMPLATE: `Report template is not exist`,
    INVALID_ACCOUNT_TYPE: `Account Type is not exist`,
    INVALID_EXCEL_DATA: `File Data is not exist`,
    INVALID_TRADING_TIME: `Trading session is invalid. Please check data in import file`,
    INVALID_TRADING_TIME_FORMAT: `Trading session is invalid format`,
    INVALID_R44_APPROVE: `Occur error during process R44`,
    EXECUTED_R44: `This Fund is already proceed R44. Please check and try again`,
    INVALID_ID_NO_ACCOUNT_NO: `Document No or Account no is invalid`,
    ID_NO_ALREADY_REGISTERED: `Duplicate document information. Please check and try again`,
    INVALID_CHANNEL: `Can not delete. Invalid distribution channel`,
    INVALID_IMAGE_TYPE: `The signature image you uploaded is not in the correct format. Please select image files with the format extention (.png,.jpg, .gif, .jpeg)`,
    SIGN_ERROR: `Error occurs during processing. Please try again`,
    SIGNATURE: `FULL SUBMISSION`,
    INVALID_SWTICH_BUY_MONEY: `Subscription amount is less than the minimum subscription amount of the Target Fund`,
    INVALID_VOLUME_BALANCE_LIMO: `Invalid number of current Fund units. Investor can only sell all or partial amount (minimum balance at least is at 100 Fund units)`,
    INVALID_SWITCH_VOLUME_BALANCE_LIMO: `Invalid number of current Fund units.`,
    CLIENT_ID_NOT_EXISTED: `Cliend ID Not Existed`,
    INVALID_TRANSACTION_FILTER: `Invalid transaction filter. Select the source and period of time for the upcoming transaction`,
    INVALID_TRADING_TIME_EXPORT_FORMAT: `Invalid Trading Time Export Format`,
    INVALID_SWITCH_SAME_PRODUCT: `Invalid swtiching order`,
    INVALID_PERMANENT_ADDRESS_INFO: `Permanent address is invalid`,
    INVALID_MAILING_ADDRESS_INFO: `Contact address is invalid`,
    INVALID_DEVICE_INFO: `Device INVALID code`,
    REQUIRED_PRODUCT: `Product is invalid`,
    REQUIRED_PROGRAM: `Program is invalid`,
    REQUIRED_FEE_TYPE_CODE: `Fee type code is invalid`,
    UNAUTHENTICATED: `Unauthorized`,
    UNAUTHORIZED: `Unauthorized`,
    FORBIDDEN: `No permission to access`,
    CONFLICT_USER_EMAIL: `This email is already exist`,
    INVALID_FORMAT_FILE: `Invalid Format File`,
    DELETED_BUY_AUTO: `Your SIP is expired`,
    RECONCILED_BUY_AUTO: `Your SIP already summit for this current trading session`,
    VALIDATE_KYC: `Account has not been approved`,
    INVALID_HOLDING_PEROID: `Invalid Holding Peroid`,
    INVALID_OBJECT: `Invalid Object`,
    INVALID_INVESTMENT_POLICY: `Invalid Investment Policy`,
    POLICY_REQUEST_CANCEL: `Cancel request  submitted `,
    INTERNAL_SERVER_ERROR: `Occur error during loading data`,
    OTP_DELIVERY_ERROR: `OTP Delivery Error`,
    EXTERNAL_SERVICE_FAIL: `External Service Fail`,
    INVALID_USERNAME: `Invalid Username`,
    FIRE_BASE_CONFIG_NOT_FOUND: `The configuration file of FireBase not found or invalid`,
    TIMER_DATE_MUST_GREATER_CURRENT_DATE: `Timer date must be greater than current date`,
    NOT_FOUND_DEVICE_TOKEN: `The device token not found`,
    NOT_FOUND_NOTIFICATION: `The notification not found`,
    CANNOT_UPDATE_NOTIFICATION_DONE: `Cannot update the notification has been done`,
    NOT_TIMER_NOTIFICATION: `This notification is not the timer notification`,
    TIMER_EXPIRED_CANNOT_UPDATE_NOTIFICATION: `The timer is expired. Cannot update the notification`,
    NOT_FOUND_USER_INBOX: `This inbox message not found`,
    IPO_FUNDS_IS_NOT_ESGIN:
      'Please sign the INVESTOR ` WRITTEN OPINION LETTER to be able to place subscription order during the IPO',
    IPO_FUNDS_IS_NOT_EXIST: `IPO Fund is Not Exits`,
    INVALID_USER_REF_CODE: `Referal code is invalid`,
    INVALID_SELL_VOLUME: `Redemption Units must be equal or more than Minimum units`,
    INVALID_USER_BANK_ACCOUNT: `Invalid bank information`,
    INVALID_CONFIRM_TOKEN: `Invalid OTP code`,
    CAN_NOT_DELETE_INVESTOR: `Can not delete. Investor `,
    INVALID_INVESTMENT_PROFILE: `Investment Account is not existed or is pending KYC`,
    CONFLICT_ID_NO: `Document No is exist`,
    PHONE_ALREADY_REGISTERED: `The phone number is exist. Please try with another phone number`,
    INVALID_ID: `Document No is invalid`,
    INVALID_PHONE: `Invalid Phone number`,
    USER_ADDITIONAL_INFO_PROFESSIONAL: `Please contact the agent for additional application`,
    FATCA_NONE_ESIGN: `Please check again the FATCA status. Lighthouse Capital will contact investors to supplement documents.`,
    TRANSACTION_VALIDATE_KYC: `Please effectuate eSign to complete the opening trading account process.`,
    TRANSACTION_VALIDATE_APPROVE: `Your account is pending approval. Investors cannot make any transactions during this period.`,
    USING_OTHER_DEVICE_SMART_OTP: `Investor login on another divice. Please registered Smart OTP on new device`,
    INVESTMENT_PROFILE_APPROVE_EKYC: `Your account has been successfully created. Your trading account opening and FATCA 9 registration information will be sent to email: `,
    hoacquykhachtaitaiday: `or you can download here`,
    INVESTMENT_PROFILE_PENDING_EKYC: `Your account is pending approval. Please check the confirmation information sent to email :`,
    INVALID_SWITCH_DES_PROGRAM: `The target fund does not apply to the Systematic Investment Plan (SIP). Please change the Target Fund program or contact our hotline at ${stringApp.hotline} for support.`,
  },
};
