import {Alert, ButtonBorder, Div, HeaderBack} from 'components';
import {Ecolors} from 'constant';
import React, {useState} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {getInfo} from 'reducer/authen';
import {getInvestmentProfile} from 'reducer/authen/selector';
import ConfirmContent from 'screens/MainScreen/AccountVerifyScreen/ConfirmContent';
import AcceptFatcaCom from 'screens/UnAuthorized/ControlEKYCScreen/AcceptFatcaCom';
import {apiAuth, navigate} from 'services';
import {useAppSelector} from 'store/hooks';

function ConfirmModal() {
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const currentUser = useAppSelector(state => state.authen.currentUser);
  const insets = useSafeAreaInsets();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [isAcceptFatca, setIsAcceptFatca] = useState<boolean>(true);

  const dispatch = useDispatch();
  const investmentProfile = useAppSelector(state =>
    getInvestmentProfile(state),
  );

  const {email} = currentUser;

  const confirm = async () => {
    try {
      setLoading(true);
      if (!isAccept) {
        return;
      }
      const res = await apiAuth.approveUser({
        fatca: isAcceptFatca,
      });
      if (res.status == 200) {
        navigate('AccountVerifyScreen');
        dispatch(getInfo({}));
      }
    } catch (error: any) {
      Alert.showError({
        multilanguage: false,
        content: I18nState == 'vi' ? error.message : error.messageEn,
        onPress: () => {
          navigate('AccountVerifyScreen');
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div height={'100%'} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={`accountverify.xacnhanhoantat`} />
      <ScrollView>
        <ConfirmContent email={email} />
        {!investmentProfile ||
        investmentProfile?.code == 'INVESTMENT_PROFILE_REJECT' ? (
          <>
            <Div paddingHorizontal={16}>
              <AcceptFatcaCom
                isAcceptFatca={isAcceptFatca}
                isAccept={isAccept}
                setIsAcceptFatca={setIsAcceptFatca}
                setIsAccept={setIsAccept}
              />
            </Div>
            <Div
              marginBottom={40}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}>
              <ButtonBorder
                type={isAccept ? 1 : 2}
                width={343}
                loading={loading}
                onPress={() => confirm()}
                title={`accountverify.hoantat`}
              />
            </Div>
          </>
        ) : (
          <>
            <Div paddingHorizontal={16}>
              <AcceptFatcaCom
                isAcceptFatca={currentUser?.investmentProfile?.fatca}
                isAccept={true}
                setIsAcceptFatca={() => {}}
                setIsAccept={() => {}}
              />
              <Div height={insets.bottom + 20} />
            </Div>
          </>
        )}
      </ScrollView>
    </Div>
  );
}

export default React.memo(ConfirmModal);
