import {
  Alert,
  ButtonBorder,
  Div,
  HeaderBack,
  ImageView,
  Label,
} from 'components';
import {Ecolors, Icons} from 'constant';
import React, {useRef, useState} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {
  apiAuth,
  checkHashPinCode,
  goBack,
  hashPinCode,
  navigate,
} from 'services';
import {getStoreData} from 'utils/storage';
import InputPin from './InputPin';

function RegisterSmartOTP() {
  const [otp, setOtp] = useState<string>('');
  const [reOtp, setReOtp] = useState<string>('');
  const inputOTPRef = useRef<any>(null);
  const reInputOTPRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitOTP = (otp: string) => {
    setOtp(otp);
    hashPinCode(otp);
    if (reInputOTPRef.current) {
      reInputOTPRef.current.focusInput();
    }
  };

  const onResubmitOTP = async (otp: string, reOtp: string) => {
    setReOtp(reOtp);
    const check = await checkHashPinCode(reOtp);
    try {
      setLoading(true);

      if (!check) {
        Alert.showError({
          content: `alert.mapinkhongdung`,
          onPress: () => {
            reInputOTPRef.current.clearOTP();
            inputOTPRef.current.clearOTP();
          },
        });
        return;
      }
      const objDataDevice = await getStoreData('objDataDevice');
      const res = await apiAuth.createSoftOTP(objDataDevice);
      if (res.data) {
        navigate('OtpRequestModal', {
          data: {
            requestOnSendOtp: res.data,
            flowApp: 'RegisterSmartOTP',
          },
          onConfirm: () => {
            Alert.showComponent({
              component: () => {
                return (
                  <Div
                    flex={1}
                    backgroundColor={Ecolors.transparentLoading}
                    alignItems="center"
                    justifyContent="center">
                    <Div
                      width={247}
                      height={237}
                      alignItems="center"
                      paddingVertical={22}
                      paddingHorizontal={26}
                      justifyContent="space-between"
                      backgroundColor={Ecolors.whiteColor}
                      borderRadius={10}>
                      <ImageView
                        marginTop={10}
                        resizeMode="contain"
                        source={Icons.otpsuccess}
                        widthHeight={50}
                      />
                      <Label textAlign="center">{`alert.dangkythanhcongsmartotp`}</Label>
                      <ButtonBorder
                        title="alert.dong"
                        width={196}
                        onPress={() => {
                          goBack().then(() => goBack());
                          return;
                          navigate('SettingScreen');
                        }}
                      />
                    </Div>
                  </Div>
                );
              },
            });
          },
        });
      }
    } catch (error: any) {
      Alert.showError({
        content: error.message,
      });
    } finally {
      setLoading(true);
    }
  };

  return (
    <Div height={'100%'} screen={true}>
      <HeaderBack type={2} title={`softotpscreen.dangkymapin`} />
      <ScrollView>
        <Div flex={1} alignItems="center" width={'100%'}>
          <InputPin
            title={`softotpscreen.vuilongnhapmapin`}
            onSubmit={res => {
              onSubmitOTP(res);
            }}
            ref={inputOTPRef}
          />
          <InputPin
            title={`softotpscreen.nhaplaimapin`}
            ref={reInputOTPRef}
            onSubmit={res => {
              onResubmitOTP(otp, res);
            }}
          />
          <ButtonBorder
            loading={loading}
            onPress={() => {
              if (loading) {
                return;
              }
              onResubmitOTP(otp, reOtp);
            }}
            marginTop={30}
            title="softotpscreen.xacnhan"
          />
        </Div>
      </ScrollView>
    </Div>
  );
}

export default React.memo(RegisterSmartOTP);
