import {Alert} from 'components';
import {RootState} from 'reducer';
import {cacheNotification, getNotificationDetails} from 'reducer/asset';
import {Middleware} from 'redux';
import {handleNotification, navigate} from 'services';

export const middlewareCatchNotification: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> = store => next => action => {
  if (action.type == `persist/REHYDRATE`) {
    handleNotification(store);
  }
  if (
    action?.type == 'authen@/doLogin/changeStatusScreen' &&
    action?.payload == 'main'
  ) {
    handleNotification(store);
    const state = store.getState();
    const notificationcache = state.asset.notificationCache;
    if (!!notificationcache && notificationcache?.typeCode == 'OTPCODE') {
      setTimeout(() => {
        Alert.show({
          content: notificationcache.detail,
          isHtml: true,
          onConfirm: () => {
            navigate('SoftOTPScreen', {
              data: notificationcache,
            });
          },
          onClose: () => {
            store.dispatch(cacheNotification(null));
          },
        });
        return;
      }, 1000);
    }
    if (!!notificationcache && !!notificationcache.id) {
      store.dispatch(
        getNotificationDetails({
          id: notificationcache.id || 0,
        }),
      );
      setTimeout(() => {
        navigate('NotificationDetailsModal');
      }, 1000);
    }
  }
  return next(action);
};
