import {Div, ImageView} from 'components';
import React from 'react';
import {useImageUrl} from 'services';
import {widthScale, widthScreen} from 'utils';

const BannerBG = () => {
  const [imgBanner, loading] = useImageUrl(`backgroup/load`, 'GET');
  return (
    <Div>
      <ImageView
        source={{
          uri: imgBanner.url,
        }}
        style={{
          width: widthScreen,
          height: widthScale(250),
        }}
        resizeMode={'cover'}
      />
    </Div>
  );
};

export default React.memo(BannerBG);
