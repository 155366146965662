import { useRoute } from '@react-navigation/native';
import {
  Alert,
  ButtonBorder,
  Div,
  HeaderBack,
  ImageView,
  Label,
} from 'components';
import { Icons } from 'constant';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { apiAuth, configEkyc, convertDaTaCCCD, goBack, navigate } from 'services';
import { useAppSelector } from 'store/hooks';
import { Log } from 'utils';
import { setStoreToken } from 'utils/storage';
import LangJson from '../../../../trueidsdk/lang.json';

function ControlEKYCScreen() {
  const route = useRoute<any>();
  const { statusScreen } = useAppSelector(state => state.authen);
  const [loading, setLoading] = useState(false);
  const currentUser = useAppSelector<any>(state => state.authen.currentUser);
  const countTime = useRef<number>(1);
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const dispatch = useDispatch();

  useEffect(() => {
    importL();
    return () => { };
  }, []);

  const importL = () => {
    setTimeout(() => {
      try {
        import('../../../../trueidsdk/TrueIDSDK')
          .then(r => {
            Log('success');
          })
          .catch(err => {
            Log('errerrerrerr', err);
          });
      } catch (error) {
        importL();
      }
    }, 1000);
  };

  const gotoEKYC = async () => {
    const res = await apiAuth.login({
      // username: route.params?.data?.phone || '0366986356',
      // password: route.params?.data?.password || 'Nha123456',
      username: route.params?.data?.phone,
      password: route.params?.data?.password,
    });
    setStoreToken(res.data.access_token);
    if (window?.['TrueIDSDK'] && window?.['TrueIDSDK'].start) {
      let configEKYC_ = {
        ...configEkyc,
        accessToken: res.data.access_token,
      };
      Log('configEKYC_', configEKYC_);
      window?.['TrueIDSDK'].setLanguageJson(I18nState, LangJson);
      window?.['TrueIDSDK'].start(configEKYC_, bindData_gotoreviewinfo);
    } else {
      Log('NULL');
    }
  };

  const bindData_gotoreviewinfo = async scanData => {
    Log('result', countTime.current);
    if (!!scanData) {
      const obj = await convertDaTaCCCD(scanData);
      if (scanData?.decision?.code == -1) {
        if (countTime.current > 2) {
          Alert.showError({
            type: 2,
            titleClose: `alert.dongy`,
            content: `alert.ekycfail3`,
            multilanguage: true,
            onClose: () => {
              if (statusScreen == 'main') {
                goBack().then(() => {
                  navigate('AccountVerifyScreen');
                });
                return;
              }
              navigate('LoginScreen');
              return;
            },
            onConfirm: () => {
              if (statusScreen == 'main') {
                goBack().then(() => {
                  navigate('AccountVerifyScreen');
                });
                return;
              }
              navigate('LoginScreen');
              return;
            },
          });
          return;
        } else {
          Alert.showError({
            type: 2,
            titleClose: `alert.dong`,
            content: `alert.ekycfail`,
            onConfirm() {
              countTime.current += 1;
            },
            onClose() {
              countTime.current += 1;
            },

          });
          return;
        }
      }
      navigate('ReviewInfoModal', {
        data: {
          ...obj,
          currentUser: {
            ...currentUser,
            ...(route.params?.data?.phone && {
              phone: route.params?.data?.phone,
            }),
            ...(route.params?.data?.password && {
              password: route.params?.data?.password,
            }),
            ...(route.params?.data?.name && {
              name: route.params?.data?.name,
            }),
            ...(route.params?.data?.email && {
              email: route.params?.data?.email,
            }),
          },
        },
      });
      return;
    }
  };

  return (
    <>
      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.cameraaa}
      />
      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.uncheckkk}
      />
      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.closeee}
      />
      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.checked}
      />

      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.iconid}
      />
      <ImageView
        height={1}
        resizeMode={'contain'}
        width={1}
        source={Icons.id_card}
      />
      <Div screen={true}>
        <HeaderBack
          isShowStep={true}
          step={3}
          type={4}
          title={`setpasswordscreen.titletrong`}
        />

        <Div paddingHorizontal={29}>
          <Div width={'100%'} alignItems={'center'}>
            <Label
              marginTop={30}
              textAlign={'center'}
              size={20}
              fontWeight={'700'}>
              {`controlekyc.xacthucthongtin`}
            </Label>
            <Label marginTop={6} textAlign={'center'} size={14} lineHeight={20}>
              {`controlekyc.content`}
            </Label>
          </Div>
          <Label marginTop={40} size={14} lineHeight={20}>
            {`controlekyc.chuphinhmattruoc`}
          </Label>
          <Div
            flexDirection={'row'}
            marginTop={17}
            alignItems={'center'}
            justifyContent={'flex-start'}>
            <ImageView
              width={132}
              height={100}
              resizeMode={'contain'}
              source={Icons.before}
              marginRight={11}
            />
            <ImageView
              resizeMode={'contain'}
              height={100}
              width={132}
              source={Icons.after}
            />
          </Div>

          <Label marginTop={18} size={14} lineHeight={20}>
            {`controlekyc.chuphinhchandung`}
          </Label>
          <Div>
            <ImageView
              marginTop={17}
              width={132}
              height={100}
              source={Icons.cmnd}
              resizeMode={'contain'}
            />
          </Div>
        </Div>
        <Div alignItems={'center'}>
          <ButtonBorder
            loading={loading}
            onPress={() => {
              // onGotoEKYC();
              gotoEKYC();
            }}
            marginTop={30}
            type={1}
            title={'controlekyc.batdau'}
          />
          <ButtonBorder
            marginTop={16}
            onPress={() => {
              if (statusScreen == 'main') {
                goBack();
                return;
              }
              navigate('LoginScreen');
            }}
            type={2}
            title={'controlekyc.thuchiensau'}
          />
        </Div>
        <View nativeID={'trueidsdk'} />
      </Div>
    </>
  );
}

export default React.memo(ControlEKYCScreen);
