import {Alert} from 'components';

// const rnBiometrics = new ReactNativeBiometrics({
//   allowDeviceCredentials: true,
// });

const checkBiometricType = async (
  callback: (t: {
    available: boolean;
    biometryType: 'FaceID' | 'TouchID';
  }) => void,
) => {
  return;
  const {biometryType, available} = await rnBiometrics.isSensorAvailable();
  const obj: any = {};
  obj['available'] = available;
  if (biometryType == 'FaceID') {
    obj['biometryType'] = 'FaceID';
  } else {
    obj['biometryType'] = 'TouchID';
  }
  callback(obj);
  return obj;
};

const checkKeysBiometric = async () => {
  return;
  return new Promise((rs, rj) => {
    return rnBiometrics
      .biometricKeysExist()
      .then(res => {
        if (res.keysExist) {
          rs(res.keysExist);
        } else {
          rj(res.keysExist);
        }
      })
      .catch(err => {
        rj(err);
      });
  });
};

const createKeysBiometric = () => {
  return;
  return new Promise((rs, rj) => {
    return rnBiometrics
      .createKeys()
      .then(async resultObject => {
        const {publicKey} = resultObject;
        if (!!publicKey) {
          rs(resultObject);
        } else {
          rj(resultObject);
        }
      })
      .catch(err => {
        rj(err);
      });
  });
};

const deleteKeysBiometric = () => {
  return;
  rnBiometrics.deleteKeys();
  return;
};

const doSimplePrompt = (props: {title: string}) => {
  return;
  return new Promise((resolve, reject) => {
    rnBiometrics
      .simplePrompt({promptMessage: props.title || ''})
      .then(res => {
        if (!!res) {
          resolve(res);
        } else {
          reject(null);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

const checkSignature = (props: {title: string}) => {
  return;
  return new Promise((resolve, reject) => {
    rnBiometrics
      .createSignature({
        promptMessage: props.title,
        payload: ``,
      })
      .then(async resultObject => {
        const {success, signature} = resultObject;
        if (success) {
          resolve(resultObject);
        } else {
          reject(null);
        }
      })
      .catch(err => {
        reject(err);
        Alert.showError({
          content: `component.thaydoivantay`,
        });
        deleteKeysBiometric();
      });
  });
};

export {
  checkBiometricType,
  checkKeysBiometric,
  checkSignature,
  createKeysBiometric,
  deleteKeysBiometric,
  doSimplePrompt,
};
