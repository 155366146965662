import {
  Button,
  ButtonBorder,
  Div,
  Dropdown,
  ImageView,
  Label,
} from 'components';
import {Ecolors, Icons, urlApp} from 'constant';
import React, {useEffect, useRef, useState} from 'react';
import {getListProduct} from 'reducer/investment';
import {useAppSelector} from 'store/hooks';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {downloadFile} from 'services';
import {convertTimestamp, joinObjectCalendar, widthScreen} from 'utils';

function ButtonCalendar(p: {title?: string; onPress: () => void; value?: any}) {
  return (
    <Button
      width={163}
      onPress={() => p.onPress && p.onPress()}
      height={48}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderRadius={5}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={10}
      borderWidth={0.8}>
      <Label
        multilanguage={!p.value}
        size={14}
        // color={p.value ? Ecolors.textColor : Ecolors.grayColor}>
        color={Ecolors.textColor}>
        {!p.value ? p.title : convertTimestamp(p.value.getTime(), '', true)}
      </Label>
      <ImageView
        heightWidth={17}
        source={Icons.calendar}
        resizeMode={'contain'}
      />
    </Button>
  );
}

function Statement(p: {activeTab: number}) {
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const listProduct = useAppSelector(state => getListProduct(state));
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const value = useRef<'from' | 'to'>('from');
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  useEffect(() => {
    if (p.activeTab == 3) {
      clearData();
    }
  }, [p.activeTab]);

  const clearData = () => {
    Promise.all([setProduct(null), setFromDate(null), setToDate(null)]);
  };

  const onAccept = async () => {
    let body = {
      fromDate: '20220807',
      productCode: 'LHBF',
      toDate: '20240813',
    };
    if (toDate) {
      body[`toDate`] = joinObjectCalendar({
        date: toDate.getDate(),
        month: toDate.getMonth(),
        year: toDate.getFullYear(),
        isPicker: true,
      });
    }
    if (fromDate) {
      body[`fromDate`] = joinObjectCalendar({
        date: fromDate.getDate(),
        month: fromDate.getMonth(),
        year: fromDate.getFullYear(),
        isPicker: true,
      });
    }
    if (product) {
      body['productCode'] = product?.code || 0;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const suffixUrl = `report/transactions-history`;
      const urll = `${urlApp.APIURL}api/${suffixUrl}`;
      downloadFile(urll, 'DownloadStatement', 'POST', body);
      return;
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const dudechange = a => {
    setIsShowCalendar(false);
    if (value.current == 'from') {
      setFromDate(a);
    } else {
      setToDate(a);
    }
  };

  return (
    <Div
      height={'100%'}
      style={{
        width: widthScreen,
      }}
      alignItems="center"
      paddingBottom={40}>
      <Div
        padding={16}
        paddingTop={0}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        flex={1}>
        <Label fontWeight={'700'}>{`transactionscreen.chonquy`}</Label>
        <Dropdown
          multiline={true}
          marginTop={6}
          paddingHorizontal={0}
          initData={listProduct}
          value={product}
          multilanguage={true}
          content={`createordermodal.chonsanpham`}
          isActive={true}
          onChange={setProduct}
        />
        <Label
          fontWeight={'700'}
          marginTop={14}>{`transactionscreen.theothoidiemgiaodich`}</Label>
        {isShowCalendar && (
          <DatePicker
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '0px, 10px',
              },
            }}
            autoFocus={true}
            onSelect={date => dudechange(date)} //when day is clicked
            onChange={date => dudechange(date)} //only when value has changed
            popperPlacement="bottom"
          />
        )}
        <Div
          paddingTop={16}
          paddingBottom={23}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <ButtonCalendar
            value={fromDate}
            onPress={() => {
              setIsShowCalendar(true);
              value.current = 'from';
            }}
            title={`transactionscreen.tungay`}
          />
          <ButtonCalendar
            value={toDate}
            onPress={() => {
              setIsShowCalendar(true);
              value.current = 'to';
            }}
            title={`transactionscreen.toingay`}
          />
        </Div>
      </Div>
      <ButtonBorder
        loading={loading}
        // isDisable={!fromDate || !toDate}
        onPress={() => {
          if (loading) {
            return;
          }
          onAccept();
        }}
        title={`transactionscreen.saoke`}
        width={343}
      />
    </Div>
  );
}

export default React.memo(Statement);
