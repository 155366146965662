import {useRoute} from '@react-navigation/native';
import {Alert, ButtonBorder, Div, HeaderBack, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useState} from 'react';
import {checkHashPinCode, useSoftOTP} from 'services';
import {widthScale} from 'utils';
import InputPin from './InputPin';

const radius = widthScale(120);
const innerRadius = radius - widthScale(12);

function SoftOTPScreen(props: {
  setOtp?: (t: any) => void;
  hideHeader?: boolean;
  loadingConfirm?: boolean;
  otpTransId: any;
}) {
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [stateTransId, setStateTransId] = useState<any>('');
  const [softOTP, timing] = useSoftOTP(stateTransId);
  const route = useRoute();

  useEffect(() => {
    if (!!props.otpTransId) {
      setStateTransId(props.otpTransId);
    }
    if (!!route.params.data && !!route.params.data.otpTransId) {
      setStateTransId(route.params.data.otpTransId);
    }
    return () => {};
  }, [props.otpTransId]);

  const handleCheck = async (otp: string) => {
    const check = await checkHashPinCode(otp);
    if (check) {
      setIsCheck(true);
      return;
    }
    Alert.showError({
      content: `alert.mapinkhongdung`,
    });
  };

  const onConfirmSoftOTP = () => {
    props.setOtp && props.setOtp(softOTP);
  };

  if (props.hideHeader) {
    return (
      <>
        {isCheck ? (
          <Div flex={1} alignItems="center">
            <Label
              marginVertical={37}
              fontWeight="bold">{`softotpscreen.smartotp`}</Label>
            <Div widthHeight={250} alignItems="center" justifyContent="center">
              <Div
                position="absolute"
                flex={1}
                padding={40}
                zIndex={999}
                elevation={999}
                alignItems="center"
                justifyContent="center">
                <Label
                  textAlign="center"
                  size={16}>{`softotpscreen.smartotptimeout`}</Label>
                <Label
                  size={40}
                  textAlign="center"
                  multilanguage={false}
                  fontWeight="bold">
                  {`00:${`${timing}`.length == 1 ? '0' : ''}${timing}`}
                </Label>
              </Div>
              {/* <Pie
                radius={radius}
                innerRadius={innerRadius}
                sections={[
                  {
                    percentage: (timing / 60) * 100,
                    color: Ecolors.mainColor,
                  },
                ]}
                backgroundColor="#ddd"
                dividerSize={20}
              /> */}
            </Div>
            <Div
              marginTop={25}
              width={256}
              height={54}
              backgroundColor={Ecolors.spaceColor}
              borderRadius={5}
              alignItems="center"
              justifyContent="center">
              <Label multilanguage={false} size={28}>
                {[...softOTP].join('    ')}
              </Label>
            </Div>
            <ButtonBorder
              width={256}
              loading={props.loadingConfirm}
              height={54}
              marginTop={30}
              title={`softotpscreen.xacnhan`}
              onPress={() => {
                if (props.loadingConfirm) {
                  return;
                }
                onConfirmSoftOTP();
              }}
            />
          </Div>
        ) : (
          <InputPin
            title="otprequestmodal.vuilongnhapmapinsmartotp"
            onSubmit={otp => {
              handleCheck(otp);
            }}
          />
        )}
      </>
    );
  }

  return (
    <Div height={'100%'} screen={true}>
      <HeaderBack type={2} title={`softotpscreen.maxacthucgiaodich`} />
      {isCheck ? (
        <Div flex={1} alignItems="center">
          <Label
            marginVertical={37}
            fontWeight="bold">{`softotpscreen.smartotp`}</Label>
          <Div widthHeight={250} alignItems="center" justifyContent="center">
            <Div
              position="absolute"
              flex={1}
              padding={40}
              zIndex={999}
              elevation={999}
              alignItems="center"
              justifyContent="center">
              <Label
                textAlign="center"
                size={16}>{`softotpscreen.smartotptimeout`}</Label>
              <Label
                size={40}
                textAlign="center"
                multilanguage={false}
                fontWeight="bold">
                {`00:${`${timing}`.length == 1 ? '0' : ''}${timing}`}
              </Label>
            </Div>
            <Pie
              radius={radius}
              innerRadius={innerRadius}
              sections={[
                {
                  percentage: (timing / 60) * 100,
                  color: Ecolors.mainColor,
                },
              ]}
              backgroundColor="#ddd"
              dividerSize={20}
            />
          </Div>
          <Div
            marginTop={25}
            width={256}
            height={54}
            backgroundColor={Ecolors.spaceColor}
            borderRadius={5}
            alignItems="center"
            justifyContent="center">
            <Label multilanguage={false} size={28}>
              {[...softOTP].join('    ')}
            </Label>
          </Div>
          {/* <ButtonBorder
            width={256}
            height={54}
            marginTop={30}
            title={`softotpscreen.xacnhan`}
            onPress={() => {
              onConfirmSoftOTP();
            }}
          /> */}
        </Div>
      ) : (
        <InputPin
          title="otprequestmodal.vuilongnhapmapinsmartotp"
          onSubmit={otp => {
            handleCheck(otp);
          }}
        />
      )}
    </Div>
  );
}

export default React.memo(SoftOTPScreen);
