import { useIsFocused } from '@react-navigation/native';
import {
  Alert,
  Button,
  ButtonBorder,
  Div,
  DropdownMultiLanguage,
  ImageView,
  InputItem,
  Label,
} from 'components';
import { Ecolors, Icons, stringApp } from 'constant';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import {
  changeBiometryType,
  changeIsSupport,
  doLogin,
  saveName,
} from 'reducer/authen';
import {
  apiAuth,
  checkBiometricType,
  checkSignature,
  doSimplePrompt,
  navigate,
} from 'services';
import { useAppSelector } from 'store/hooks';
import { LinkingToStore, checkLogin, parseMultilanguage } from 'utils';
import { getAccount, getStoreData, setStoreToken } from 'utils/storage';
import BannerBG from './BannerBG';

import 'react-datepicker/dist/react-datepicker.css';

function LoginScreen(props: {
  onLoginSuccess?: (t?: any) => void;
  onEndAction?: (t?: any) => void;
}) {
  const dispatch = useDispatch();
  const isFocus = useIsFocused();
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const userNameSaveRedux = useAppSelector(state => state.authen.userName);
  const isBio = useAppSelector(state => state.authen.isBio);

  const activeLanguage = useAppSelector(
    state => state.languages.activeLanguage,
  );

  const isSupport = useAppSelector(state => state.authen.isSupport);
  const biometryType = useAppSelector(state => state.authen.biometryType);
  //

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  // const [username, setUserName] = useState('0987589702');
  // const [password, setPassword] = useState('Nha123456');
  const isLoading = useAppSelector(state => state.authen.isLoading);
  const [isSaveName, setIsSaveName] = useState<boolean>(false);
  //
  // const [biometryType, setBioMetryType] = useState<string>('');
  // const [isSupported, setIsSupported] = useState<boolean>(false);
  const [isHaveAccount, setIsHaveAccount] = useState<boolean>(false);

  //
  const passwordRef = useRef<any>(null);

  const optionalConfigObject = {
    // title: 'Authentication Required', // Android
    imageColor: Ecolors.mainColor, // Android
    imageErrorColor: Ecolors.redColor, // Android
    sensorDescription: '', // Android
    fallbackLabel: '', // iOS (if empty, then label is hidden)
    unifiedErrors: true, // use unified error messages (default false)
    passcodeFallback: false, // iOS - allows the device to fall back to using the passcode, if faceid/touch is not available. this does not mean that if touchid/faceid fails the first few times it will revert to passcode, rather that if the former are not enrolled, then it will use the passcode.
  };

  useEffect(() => {
    if (!!userNameSaveRedux) {
      setUserName(userNameSaveRedux);
      setIsSaveName(true);
    } else {
      setIsSaveName(false);
    }
    return () => { };
  }, [isFocus, userNameSaveRedux]);

  useEffect(() => {
    if (isFocus) {
      setStoreToken('');
    }
    return () => { };
  }, [isFocus]);

  useEffect(() => {
    // actionGetVersionApp();
    return () => { };
  }, []);

  const actionGetVersionApp = async () => {
    const t = await apiAuth.getVersion();
    if (
      t?.data?.appVersion &&
      t?.data?.appVersion > stringApp.versionApp.number
    ) {
      Alert.show({
        content: `alert.capnhatungdung`,
        hideBack: true,
        type: 2,
        onConfirm: () => {
          LinkingToStore();
        },
        titleClose: 'alert.dongy',
      });
    }
  };

  useEffect(() => {
    try {
      checkBiometryType();
      return;
    } catch (error: any) { }
  }, []);

  useEffect(() => {
    if (isBio) {
      checkAccountStorage(true);
    }
    return () => { };
  }, [isBio]);

  const checkAccountStorage = async (t?: boolean) => {
    try {
      const res = await getStoreData('isAccountTouchID');
      if (res) {
        setIsHaveAccount(true);
        checkBiometryType();
        return;
      }
      setIsHaveAccount(false);
      return;
    } catch (error) {
      setIsHaveAccount(false);
      return;
    }
  };

  const checkBiometryType = () => {
    try {
      checkBiometricType(res => {
        if (res.available) {
          dispatch(changeBiometryType(res.biometryType));
          dispatch(changeIsSupport(true));
        } else {
          dispatch(changeBiometryType(''));
          dispatch(changeIsSupport(false));
        }
      });
    } catch (error: any) { }
  };

  const doLoginWithBiometry = (res?: string | boolean) => {
    return;
    try {
      // createKeysBiometric();

      // return;
      checkSignature({
        title: parseMultilanguage(`component.dangnhapvaoapp`),
      }).then(async (res: any) => {
        if (!!res.success) {
          const account = await getAccount();
          if (account) {
            setUserName(account.username);
            setPassword(account.password);
            onLogin({
              name: account.username,
              pass: account.password,
            });
          }
        }
      });
      return;
      doSimplePrompt({
        title: parseMultilanguage(`component.dangnhapvaoapp`),
      }).then(async (res: any) => {
        if (!!res?.success) {
          const account = await getAccount();
          if (account) {
            setUserName(account.username);
            setPassword(account.password);
            onLogin({
              name: account.username,
              pass: account.password,
            });
          }
        }
      });
      return;
    } catch (error: any) { }
  };

  // Log('tttt', t);

  const onLogin = async (p?: { name?: string; pass?: string }) => {
    try {
      if (
        !checkLogin({
          name: p?.name || '',
          pass: p?.pass || '',
        })
      ) {
        return;
      }
      if (isSaveName) {
        dispatch(saveName(p?.name || username));
      } else {
        dispatch(saveName(''));
      }
      const obj: IParamsLogin = {
        username: p?.name || username,
        password: p?.pass || password,
      };
      const res: any = await dispatch(doLogin(obj));
      if (res.payload.status != 200) {
        Alert.showError({
          content: `alert.dangnhapsai`,
        });
        return;
      }
      props.onLoginSuccess && props.onLoginSuccess(res);
    } catch (error: any) {
      Alert.showError({
        content: `alert.dangnhapsai`,
      });
    } finally {
      props.onEndAction && props.onEndAction();
    }
  };

  const gotoRegister = () => {
    navigate('RegisterScreen');
    return;
  };

  const gotoForgotPassword = () => {
    navigate('ForgotPasswordScreen');
  };

  return (
    <ScrollView keyboardShouldPersistTaps={'handled'}>
      <BannerBG />
      <Div
        flexDirection={'row'}
        alignItems={'center'}
        paddingHorizontal={29}
        paddingTop={26}
        justifyContent={'space-between'}>
        <Label size={20} fontWeight="700">
          {`loginscreen.login`}
        </Label>
        <DropdownMultiLanguage />
      </Div>
      {/* login form  */}
      <Div marginTop={33} paddingHorizontal={29} flexDirection={'column'}>
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}>
          <Label size={16}>{`loginscreen.tendangnhap`}</Label>
          {/* <DropdownWarningAlert /> */}
        </Div>
        <InputItem
          marginTop={6}
          value={username}
          marginHorizontal={0}
          onSubmitEditing={() => {
            if (passwordRef.current) {
              passwordRef.current.focus();
            }
          }}
          onChangeText={setUserName}
          placeholder={parseMultilanguage(`loginscreen.accountplacehoder`)}
          keyboardType={'number-pad'}
        />
        <Label marginTop={13} size={16}>{`loginscreen.matkhau`}</Label>
        <InputItem
          inputRef={passwordRef}
          isShowAndHide={true}
          value={password}
          onChangeText={setPassword}
          marginTop={6}
          keyboardType={'ascii-capable'}
          marginHorizontal={0}
          placeholder={parseMultilanguage(`loginscreen.passwordplacehoder`)}
        />
        <Div
          paddingTop={15}
          flexDirection={'row'}
          justifyContent={'space-between'}>
          <Button
            flexDirection={'row'}
            alignItems={'center'}
            onPress={() => setIsSaveName(a => !a)}>
            {isSaveName ? (
              <ImageView
                source={Icons.check}
                // tintColor={Ecolors.linkColor}
                widthHeight={20}
              />
            ) : (
              <Div
                widthHeight={20}
                borderColor={Ecolors.textColor}
                borderRadius={100}
                borderWidth={0.5}
              />
            )}
            <Label
              marginLeft={10}
              size={15}
              color={isSaveName ? Ecolors.mainColor : Ecolors.textColor}>
              {`loginscreen.saveusername`}
            </Label>
          </Button>
          <Button onPress={() => gotoForgotPassword()}>
            <Label size={15} color={Ecolors.linkColor}>
              {`loginscreen.forgotpass`}
            </Label>
          </Button>
        </Div>
      </Div>
      {/* login button  */}
      <Div
        paddingTop={23}
        paddingHorizontal={29}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <ButtonBorder
          // width={isSupport ? 252 : 317}
          width={317}
          loading={isLoading}
          title={`loginscreen.login`}
          onPress={() => {
            onLogin({
              name: username,
              pass: password,
            });
          }}
        />
        {/* {isSupport && (
          <Button
            heightWidth={48}
            borderWidth={0.5}
            borderColor={Ecolors.grayColor}
            borderRadius={5}
            alignItems={'center'}
            justifyContent={'center'}
            onPress={() => {
              if (!isHaveAccount || !isBio) {
                Alert.showError({
                  content: `alert.dangnhapvantaythatbai`,
                });
                return;
              }
              doLoginWithBiometry();
            }}>
            <ImageView
              tintColor={Ecolors.mainColor}
              source={biometryType == 'FaceID' ? Icons.faceid : Icons.finger}
              widthHeight={27}
              resizeMode={'contain'}
            />
          </Button>
        )} */}
      </Div>
      {/* goto register screens */}
      <Div
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingTop={60}
        paddingBottom={70}>
        {/* <Button
          onPress={() => {
            navigate('ActiveAccountModal');
          }}>
          <Label
            color={Ecolors.linkColor}>{`loginscreen.kichhoattaikhoan`}</Label>
        </Button> */}
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          marginTop={11}
          justifyContent={'center'}
          flexWrap={'wrap'}>
          <Label>{`loginscreen.signuptitle`}</Label>
          <Button
            onPress={() => {
              // return;
              gotoRegister();
            }}>
            <Label
              fontWeight={'700'}
              color={Ecolors.linkColor}>{`loginscreen.signup`}</Label>
          </Button>
        </Div>

        <Div height={100} />
      </Div>
    </ScrollView>
  );
}

export default React.memo(LoginScreen);
