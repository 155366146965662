import {Div, HeaderBack, TabViewComp} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {useDispatch} from 'react-redux';
import {loadTabArticle} from 'reducer/asset';
import {useAppSelector} from 'store/hooks';
import ListArticle from '../OverviewScreen/ListArticle';

function InvestmentLibraryScreen() {
  const category = useAppSelector(state => state.asset.category || []);
  const dispatch = useDispatch();
  return (
    <Div flex={1} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={`investmentlibraryscreen.thuviendautu`} />
      <TabViewComp
        onChange={e => {
          dispatch(
            loadTabArticle({
              pagination: {
                currentPage: 1,
              },
              queries: {
                categoriId: category[e]?.id || 1,
              },
              category: category[e],
            }),
          );
        }}
        multilanguage={false}
        data={category.map((item, index) => {
          return {
            component: (a: any) => (
              <ListArticle
                filter={item.code}
                // filter={'home'}
                scrollEnabled={true}
                isHeader={false}
              />
            ),
            title: item.name,
            key: item.name,
          };
        })}
      />
    </Div>
  );
}

export default React.memo(InvestmentLibraryScreen);
