export enum urlApp {
  DomainName = 'https://limo.mobile', //
  // -----------------------------------------
  SECRETKEY = 'JBSWY3c417821dd408d3Jk$DP#EHPK3?PXPJljTu%ssR23jaO', //test
  APIURL = 'https://apis.fplatform.fincorp.vn/web/v1/', //test//
  /////// uat
  // APIURL = 'https://oapis.fplatform-uat.fincorp.vn/web/v1/', //uat//
  // SECRETKEY = 'JBSWY3c417821dd408d3Jk$DP#EHPK3?PXPJljTu%ssR23jaO', //uat
  // ///////////// live
  // APIURL = 'https://oapis.fplatform-live.fincorp.vn/web/v1/', //live
  // SECRETKEY = 'kc63d*SwVaz3c417821dd408dgTScyk$hv9@dER0?Q2ssR6GKG8%6jlKI!z', //live
}
