import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';

interface Props {
  data: any;
  actionDelete?: boolean;
  onPressItem?: () => void;
  index: number;
}

function ItemNotification({data, actionDelete, onPressItem}: Props) {
  return (
    <Button
      onPress={() => {
        onPressItem && onPressItem();
      }}
      width={'100%'}
      paddingHorizontal={16}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Div
        width={'100%'}
        // minHeight={148}
        paddingBottom={10}
        // maxHeight={200}
        // overflow="hidden"
        borderWidth={0.8}
        borderColor={Ecolors.bordercolor}
        borderRadius={5}>
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          paddingVertical={13}
          borderBottomWidth={1}
          borderBottomColor={Ecolors.spaceColor}
          paddingHorizontal={11}
          justifyContent={'space-between'}>
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}>
            <ImageView
              source={
                Icons?.[`${data.notificationTypeCode}`] || Icons.notification
              }
              tintColor={Ecolors.mainColor}
              widthHeight={20}
              marginRight={11}
              resizeMode={'contain'}
            />
            <Label fontWeight={'700'} multilanguage={false}>
              {data.notificationTypeName}
            </Label>
          </Div>
          <Label size={14} multilanguage={false}>
            {data.createAt.split(' ')?.[0]}
          </Label>
        </Div>
        <Div paddingHorizontal={12} paddingVertical={8}>
          <Label numberOfLines={2} fontWeight="700" multilanguage={false}>
            {data.notificationHistorySubject}
          </Label>
          <Label numberOfLines={4} multilanguage={false} size={14}>
            {data.notificationHistoryMessage}
          </Label>
        </Div>
      </Div>
    </Button>
  );
}

export default React.memo(ItemNotification);
