import {Div, HeaderBack, TabViewComp} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {useDispatch} from 'react-redux';
import {getNotificationTable} from 'reducer/asset';
import {useAppSelector} from 'store/hooks';
import ListNotification from './ListNotification';

function NotificationScreen() {
  const dispatch = useDispatch();
  const notificationType = useAppSelector(
    state => state.asset.notificationType,
  );
  return (
    <Div flex={1} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={`notificationscreen.thongbao`} />
      <TabViewComp
        onChange={e => {
          dispatch(
            getNotificationTable({
              pagination: {
                currentPage: 1,
              },
              queries: {
                notificationTypeId: notificationType[e]?.id,
              },
              // notificationType: category[e],
            }),
          );
        }}
        multilanguage={false}
        data={notificationType.map((item, index) => {
          return {
            component: (a: any) => (
              <ListNotification
                filter={item.id}
                scrollEnabled={true}
                isHeader={false}
              />
            ),
            title: item.name,
            key: item.name,
          };
        })}
      />
    </Div>
  );
}

export default React.memo(NotificationScreen);
