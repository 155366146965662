import {useIsFocused} from '@react-navigation/native';
import {Div, HTMLView, ImageView} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useRef} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {changeFocusArticle} from 'reducer/asset';
import {useImageUrl} from 'services';
import {useAppSelector} from 'store/hooks';
import ListArticle from '../OverviewScreen/ListArticle';
import AniHeader from './AniHeader';

function InvestmentLibraryDetailScreen() {
  const articleFocus = useAppSelector(state => state.asset.articleFocus);
  const loadingArticleFocus = useAppSelector(
    state => state.asset.loadingArticleFocus,
  );
  const scrollviewRef = useRef();
  const isFocus = useIsFocused();
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const aniHeaderRef = useRef();

  const [imageSource, loading] = useImageUrl(
    `download/file-for-link`,
    'POST',
    `${articleFocus?.image || ''}`,
  );

  useEffect(() => {
    scrolltoheader();

    return () => {};
  }, [articleFocus?.id]);

  useEffect(() => {
    if (!isFocus) {
      unfocusArticle();
    }
    return () => {};
  }, [isFocus]);

  const scrolltoheader = () => {
    if (scrollviewRef && scrollviewRef.current) {
      scrollviewRef.current.scrollTo({
        offset: 0,
        animation: false,
      });
    }
  };

  const unfocusArticle = () => {
    dispatch(changeFocusArticle(null));
  };

  return (
    <Div height={'100%'} backgroundColor={Ecolors.whiteColor}>
      <ScrollView
        stickyHeaderIndices={[1]}
        scrollEventThrottle={10}
        onScroll={evt => {
          if (!!aniHeaderRef.current) {
            aniHeaderRef.current?.fade(evt.nativeEvent.contentOffset.y);
          }
        }}
        ref={scrollviewRef}>
        <Div height={insets.top - 10} width={'100%'} />
        <AniHeader ref={aniHeaderRef} />
        <Div
          borderTopWidth={1}
          borderTopColor={Ecolors.bordercolor}
          alignItems="center">
          <ImageView
            marginVertical={15}
            source={{
              uri: imageSource.url,
            }}
            width={343}
            height={177}
            borderRadius={8}
            resizeMode="cover"
          />
          <Div paddingHorizontal={24}>
            <HTMLView
              source={{
                html: articleFocus?.content || '',
              }}
            />
          </Div>
          <Div
            width={'100%'}
            marginTop={10}
            height={6}
            backgroundColor={Ecolors.spaceColor}
          />
          <ListArticle
            footerHeight={100}
            nonScaleFirst={true}
            isHeader={true}
            contentHeader={`investmentlibraryscreen.baivietlienquan`}
            isHideMore={true}
            filter="details"
            scrollEnabled={false}
          />
        </Div>
      </ScrollView>
    </Div>
  );
}

export default React.memo(InvestmentLibraryDetailScreen);
