import { Button, Div, ImageView, Label, LoadingIndicator } from 'components';
import { EStyle, Ecolors, Icons, stringApp } from 'constant';
import React, { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { parseMultilanguage, widthScale } from 'utils';
import { downloadFileEsignIPO, downloadFileEsignSignature } from './function';

function CardSignature(p: { data?: any }) {
  const currentUser = useAppSelector<any>(state => state.authen.currentUser);
  const hardProfile = !!currentUser.investmentProfile?.isReceivedHardProfile;
  const { name, investmentProfile } = currentUser;
  const [loadingIPO, setLoadingIPO] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const I18nState = useAppSelector(state => state.languages.I18nState);

  const switchStatusEsign = (p: { hardProfile: boolean }) => {
    var contentStatusEsign = '';
    if (p.hardProfile) {
      contentStatusEsign = `component.daky`;
    } else {
      contentStatusEsign = `component.chuaky`;
    }
    return parseMultilanguage(contentStatusEsign);
  };

  const downloadConfirm = async () => {
    if (p?.data) {
      return downloadFileEsignIPO({
        loading: loadingIPO,
        setLoading: setLoadingIPO,
      });
    }
    return downloadFileEsignSignature({
      loading,
      setLoading,
    });
  };

  if (p?.data?.product) {
    return (
      <>
        <Div
          width={343}
          marginVertical={15}
          paddingVertical={16}
          borderRadius={8}
          borderWidth={0.8}
          borderColor={Ecolors.bordercolor}
          flexDirection={'row'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          paddingLeft={20}
          paddingRight={17}
          backgroundColor={Ecolors.whiteColor}
          style={EStyle.shadowItem}>
          <Div
            widthHeight={40}
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={5}
            overflow={'hidden'}
            borderWidth={0.8}
            borderColor={Ecolors.bordercolor}>
            <ImageView
              source={Icons.iconapp}
              // widthHeight={30}
              style={{
                width: widthScale(40),
                height: widthScale(40),
              }}
              resizeMode={'cover'}
            />
          </Div>
          <Div
            flex={1}
            paddingHorizontal={10}
            flexDirection={'column'}
            alignItems={'flex-start'}>
            <Label
              size={16}
              color={Ecolors.mainColor}
              fontWeight={'700'}
              multilanguage={false}>
              {p?.data?.product?.name || ''}
            </Label>
            <Label
              marginTop={2}
              size={14}
              color={Ecolors.grayColor}
              multilanguage={false}>
              {p?.data?.product?.code || ''}
            </Label>
          </Div>

          <Div
            flexDirection={'row'}
            paddingTop={12}
            alignItems={'center'}
            justifyContent={'center'}>
            <Label
              size={14}
              color={
                currentUser?.userFileIPO ? Ecolors.greenColor : Ecolors.redColor
              }
              multilanguage={false}>
              {switchStatusEsign({
                hardProfile: currentUser?.userFileIPO,
                I18nState,
              })}
            </Label>
            <ImageView
              marginLeft={10}
              widthHeight={17}
              tintColor={
                currentUser?.userFileIPO
                  ? Ecolors.greenColor
                  : Ecolors.grayColor
              }
              resizeMode={'contain'}
              source={currentUser?.userFileIPO ? Icons.check : Icons.uncheck}
            />
          </Div>
        </Div>
        <Div
          alignItems={'center'}
          paddingHorizontal={20}
          justifyContent={'center'}>
          <Label textAlign={'center'} size={14}>
            {currentUser?.userFileIPO
              ? `digitalsignatureipo.contentdownload`
              : `digitalsignatureipo.taigiaydangkymuaipo2`}
          </Label>
        </Div>
        <Div
          width={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          paddingBottom={20}>
          <Button
            width={340}
            height={48}
            flexDirection={'row'}
            marginTop={18}
            onPress={() => {
              downloadConfirm();
            }}
            borderRadius={5}
            borderWidth={0.8}
            borderColor={Ecolors.mainColor}
            backgroundColor={Ecolors.spaceColor}
            alignItems={'center'}
            justifyContent={'center'}>
            {loadingIPO ? (
              <LoadingIndicator color={Ecolors.mainColor} />
            ) : (
              <>
                <ImageView
                  source={Icons.download}
                  widthHeight={18}
                  resizeMode={'contain'}
                  marginRight={10}
                />
                <Label fontWeight={'700'} size={15}>
                  {`digitalsignatureipo.taigiaydangkymuaipo`}
                </Label>
              </>
            )}
          </Button>
        </Div>
        {p?.data?.merge && (
          <>
            <Div
              alignItems={'center'}
              paddingHorizontal={20}
              justifyContent={'center'}>
              <Label textAlign={'center'} size={14}>
                {hardProfile
                  ? `digitalsignature.contentdownload2`
                  : `digitalsignature.contentdownload`}
              </Label>
            </Div>
            <Div
              marginTop={18}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
              paddingBottom={20}>
              <Button
                width={340}
                height={48}
                flexDirection={'row'}
                onPress={() => {
                  downloadFileEsignSignature({
                    loading,
                    setLoading,
                  });
                }}
                borderRadius={5}
                borderWidth={0.8}
                borderColor={Ecolors.mainColor}
                backgroundColor={Ecolors.spaceColor}
                alignItems={'center'}
                justifyContent={'center'}>
                {loading ? (
                  <LoadingIndicator color={Ecolors.mainColor} />
                ) : (
                  <>
                    <ImageView
                      source={Icons.download}
                      widthHeight={18}
                      resizeMode={'contain'}
                      marginRight={10}
                    />
                    <Label fontWeight={'700'} size={15}>
                      {hardProfile
                        ? `digitalsignature.taihopdongdaky`
                        : `digitalsignature.taihopdongmotaikhoan`}
                    </Label>
                  </>
                )}
              </Button>
            </Div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Div
        width={343}
        marginVertical={15}
        paddingVertical={16}
        borderRadius={8}
        borderWidth={0.8}
        borderColor={Ecolors.bordercolor}
        flexDirection={'row'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        paddingLeft={20}
        paddingRight={17}
        backgroundColor={Ecolors.whiteColor}
        style={EStyle.shadowItem}>
        <Div
          widthHeight={40}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={5}
          overflow={'hidden'}
          borderWidth={0.8}
          borderColor={Ecolors.bordercolor}>
          <ImageView
            source={Icons.iconapp}
            // widthHeight={30}
            style={{
              width: widthScale(40),
              height: widthScale(40),
            }}
            resizeMode={'cover'}
          />
        </Div>

        <Div
          flex={1}
          paddingHorizontal={10}
          flexDirection={'column'}
          alignItems={'flex-start'}>
          <Label
            size={16}
            color={Ecolors.mainColor}
            fontWeight={'700'}
            multilanguage={false}>
            {stringApp.companyName}
          </Label>
          <Label
            marginTop={2}
            size={14}
            color={Ecolors.grayColor}
            multilanguage={false}>
            {investmentProfile?.number || ''}
          </Label>
        </Div>
        <Div
          flexDirection={'row'}
          paddingTop={12}
          alignItems={'center'}
          justifyContent={'center'}>
          <Label
            size={14}
            color={hardProfile ? Ecolors.greenColor : Ecolors.redColor}
            multilanguage={false}>
            {switchStatusEsign({ hardProfile })}
          </Label>
          <ImageView
            marginLeft={10}
            widthHeight={17}
            // tintColor={hardProfile ? Ecolors.greenColor : Ecolors.grayColor}
            resizeMode={'contain'}
            source={hardProfile ? Icons.check : Icons.uncheck}
          />
        </Div>
      </Div>
      <Div
        alignItems={'center'}
        paddingHorizontal={20}
        justifyContent={'center'}>
        <Label
          color={hardProfile ? Ecolors.textColor : Ecolors.redColor}
          fontWeight="600"
          textAlign={'center'}
          size={14}>
          {hardProfile
            ? `digitalsignature.contentdownload2`
            : `digitalsignature.contentdownload`}
        </Label>
      </Div>
      <Div
        width={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingBottom={20}>
        <Button
          width={340}
          height={48}
          flexDirection={'row'}
          marginTop={18}
          onPress={() => {
            downloadConfirm();
          }}
          borderRadius={5}
          borderWidth={0.8}
          borderColor={Ecolors.mainColor}
          backgroundColor={Ecolors.spaceColor}
          alignItems={'center'}
          justifyContent={'center'}>
          {loading ? (
            <LoadingIndicator color={Ecolors.mainColor} />
          ) : (
            <>
              <ImageView
                source={Icons.download}
                widthHeight={18}
                resizeMode={'contain'}
                marginRight={10}
              />
              <Label fontWeight={'700'} size={15}>
                {hardProfile
                  ? `digitalsignature.taihopdongmotaikhoan`
                  : `digitalsignature.taihopdongmotaikhoan`}
              </Label>
            </>
          )}
        </Button>
      </Div>
    </>
  );
}

export default React.memo(CardSignature);
