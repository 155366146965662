import {urlApp} from 'constant';
import md5 from 'md5';
import {PostMSG} from 'utils';
import {getStoreData, getStoreToken} from 'utils/storage';

export const downloadFileEsignSignature = async ({loading, setLoading}) => {
  try {
    setLoading(true);
    const url = `esignature/download-contract`;
    const bburl = `${urlApp.APIURL}api/${url}`;
    return downloadFile(bburl, 'DownloadFileContract');
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const downloadFileEsignIPO = async ({loading, setLoading}) => {
  setLoading(true);
  try {
    const url = `esignature/ipo-subscription/download`;
    const bburl = `${urlApp.APIURL}api/${url}`;
    return downloadFile(bburl, 'DownloadFileEsignIPO');
  } catch (error) {
  } finally {
    setLoading(false);
  }
};
export const downloadFile = async (
  url,
  msgString,
  method?: 'GET' | 'POST',
  body?: any,
) => {
  const token = await getStoreToken();
  const idNo = await getStoreData('idNo');
  const phone = await getStoreData('phone');
  const refCode = await getStoreData('refCode');
  const agentCode = await getStoreData('agentCode');
  const secretKey = await getStoreData('secretKey');
  const headers = {
    idNo,
    phone,
    refCode,
    agentCode,
    secretKey,
    Authorization: token
      ? `Bearer ${token}`
      : `Bearer ${md5(`${idNo}${phone}${refCode}${agentCode}${secretKey}`)}`,
    'Content-Type': 'application/json',
  };
  var data;
  if (method == 'POST') {
    data = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body || {}),
    });
  } else {
    data = await fetch(url, {
      method: method || 'GET',
      headers,
    });
  }
  data.blob().then((t: any) => {
    const reader = new FileReader();
    let dataurl = window.URL.createObjectURL(t);
    let a = document.createElement('a');
    a.href = dataurl;
    a.download = 'File';
    reader.readAsDataURL(t);
    reader.onloadend = () => {
      const base64data: any = reader.result;
      PostMSG(
        msgString,
        `${base64data.replace('data:application/pdf;base64,', '') || ''}`,
      );
    };
    a.click();
  });
};
