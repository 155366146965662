import {Button, ButtonBorder, Div, ImageView, Label} from 'components';
import {Ecolors, EStyle, Icons} from 'constant';
import React, {useImperativeHandle, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {changeStatusScreen} from 'reducer/authen';
import {navigate} from 'services';
import BottomSheetDialog from './BottomSheetDialog';

interface p {
  title: string;
  content: string;
  titleClose: string;
  type?: string | number;
  titleCancel: string;
  multilanguage?: boolean;
  component?: any;
  titleConfirm: string;
  is401?: boolean;
  error: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
}

function AlertCp({}, ref: React.Ref<unknown> | undefined) {
  const [state, setState] = useState<p>({
    title: '',
    type: 1,
    content: '',
    is401: false,
    error: false,
    titleClose: '',
    titleConfirm: '',
    titleCancel: '',
    component: null,
    multilanguage: false,
    onCancel: () => {},
    onClose: () => {},
    onConfirm: () => {},
  });
  const dispatch = useDispatch();
  const sheetRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    show: (e: p) => {
      sheetRef.current.show();
      setState(a => {
        return {
          ...a,
          ...e,
        };
      });
    },
    hide: () => {
      sheetRef.current.hide();
    },
  }));

  const onCloseAlert = () => {
    if (state.is401) {
      dispatch(changeStatusScreen('unAuthorized'));
      setTimeout(() => {
        navigate('RegisterScreen');
      }, 400);
    }
  };

  if (state.component) {
    return (
      <BottomSheetDialog
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        ref={sheetRef}>
        {state.component()}
      </BottomSheetDialog>
    );
  }
  return (
    <BottomSheetDialog
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={sheetRef}>
      <Div
        width={337}
        borderRadius={5}
        style={EStyle.shadow}
        backgroundColor={Ecolors.whiteColor}
        minHeight={193}>
        {/* header */}
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderBottomWidth={1}
          borderBottomColor={Ecolors.spaceColor}>
          <Div heightWidth={46} />
          <Div
            flexDirection={'row'}
            flex={1}
            alignItems={'center'}
            justifyContent={'center'}>
            <Label fontWeight={'700'}>{state?.title || ''}</Label>
          </Div>
          <Button
            onPress={() => {
              state?.onClose && state?.onClose();
              sheetRef.current.hide();
              onCloseAlert();
            }}
            widthHeight={46}
            alignItems={'center'}
            justifyContent={'center'}>
            <ImageView
              source={Icons.close}
              widthHeight={20}
              resizeMode={'contain'}
            />
          </Button>
        </Div>
        {/* content */}
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop={28}
          marginBottom={39}
          paddingHorizontal={35}>
          <Label
            multilanguage={state?.multilanguage ?? true}
            textAlign={'center'}>
            {state?.content || ''}
          </Label>
        </Div>
        {state?.error ? (
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            paddingHorizontal={14}
            paddingBottom={13}>
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleClose}
              onPress={() => {
                sheetRef.current.hide();
                // route?.params?.onCancel && route?.params?.onCancel();
              }}
            />
          </Div>
        ) : state?.type == 1 ? (
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            paddingHorizontal={14}
            paddingBottom={13}>
            <ButtonBorder
              type={2}
              width={148}
              title={state?.titleCancel}
              onPress={() => {
                sheetRef.current.hide();
                state?.onCancel && state?.onCancel();
              }}
            />
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleConfirm}
              onPress={() => {
                sheetRef.current.hide();
                state?.onConfirm && state?.onConfirm();
              }}
            />
          </Div>
        ) : (
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            paddingHorizontal={14}
            paddingBottom={13}>
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleClose}
              onPress={() => {
                sheetRef.current.hide();
                state?.onConfirm && state?.onConfirm();
                onCloseAlert();
              }}
            />
          </Div>
        )}
      </Div>
    </BottomSheetDialog>
  );
}

export default React.forwardRef(AlertCp);
