import {Alert} from 'components';
import {Ecolors, stringApp} from 'constant';
import {Linking, Platform} from 'react-native';

import {cacheNotification, getNotificationDetails} from 'reducer/asset';
import {changeIsSmartOTP, changeNoti} from 'reducer/authen';
import {apiAuth, navigate} from 'services';
import {getUuid} from 'utils';
import {getStoreToken, setStoreData} from 'utils/storage';
var localnoti = {};

export async function requestNotification() {
  return;
  return requestNotifications(['alert', 'sound']).then(({status, settings}) => {
    console.log('request notification', {
      status,
      settings,
    });
    if (status == 'blocked') {
      return false;
    } else {
      return true;
    }
  });
}

export async function handleNotification(store?: any) {
  return;
  requestNotifications(['alert', 'sound']).then(({status, settings}) => {
    // …
    console.log('request notification', {
      status,
      settings,
    });
    if (status == 'blocked') {
      Alert.show({
        content: 'Không có quyền truy cập thông báo\nVui lòng vào cài đặt',
        multilanguage: false,
        onConfirm: () => {
          Linking.openSettings();
        },
      });
      return;
    }
  });

  if (Platform.OS === 'android') {
    PushNotification.channelExists(
      stringApp.notification.channelId,
      function (exists) {
        if (!exists) {
          PushNotification.createChannel(
            {
              ...stringApp.notification,
              playSound: false, // (optional) default: true
              soundName: 'default', // (optional) See `soundName` parameter of `localNotification` function
              importance: Importance.HIGH, // (optional) default: Importance.HIGH. Int value of the Android notification importance
              vibrate: true, // (optional) default: true. Creates the default vibration pattern if true.
            },
            created => console.log(`createChannel returned '${created}'`), // (optional) callback returns whether the channel was created, false means it already existed.
          );
        }
        console.log(exists); // true/false
      },
    );
    PushNotification.configure({
      onRegister: async function (token: {token: string; os: string}) {
        onRegisterWithToken(token.token, token.os, store);
      },
      // (required) Called when a remote is received or opened, or local notification is opened
      onNotification: function (notification: any) {
        actionWithNotification(store, notification);
        PushLocalNotificationAndroid(notification);
        if (notification.userInteraction) {
          PushNotification.cancelAllLocalNotifications();
          return;
        }
      },

      // (optional) Called when Registered Action is pressed and invokeApp is false, if true onNotification will be called (Android)
      onAction: function (notification) {
        console.log('ACTION:', notification.action);
        console.log('NOTIFICATION:', notification);
        actionWithNotification(store, notification);
        PushLocalNotificationAndroid(notification);
        // process the action
      },
      // (optional) Called when Registered Action is pressed and invokeApp is false, if true onNotification will be called (Android)
      // on invoke app
      // invokeApp: function (notification) {
      //   console.log('ACTION:', notification.action);
      //   console.log('NOTIFICATION:', notification);
      //   actionWithNotification(store, notification);
      //   // process the action
      // },
      // click_action: 'OPEN_ACTIVITY_1',
      // (optional) Called when the user fails to register for remote notifications. Typically occurs when APNS is having issues, or the device is a simulator. (iOS)
      onRegistrationError: function (err) {
        console.error(err.message, err);
      },

      // IOS ONLY (optional): default: all - Permissions to register.
      permissions: {
        alert: true,
        badge: true,
        sound: true,
      },

      // Should the initial notification be popped automatically
      // default: true
      popInitialNotification: true,
      // invokeApp: true,
      /**
       * (optional) default: true
       * - Specified if permissions (ios) and token (android and ios) will requested or not,
       * - if not, you must call PushNotificationsHandler.requestPermissions() later
       * - if you are not using remote notification or do not have Firebase installed, use this:
       *     requestPermissions: Platform.OS === 'ios'
       */
      requestPermissions: true,
    });
    return;
  }

  if (Platform.OS === 'ios') {
    PushNotificationIOS.setApplicationIconBadgeNumber(0);
    await messaging().registerDeviceForRemoteMessages();
    const token = await messaging().getToken();
    onRegisterWithToken(token, 'ios', store);

    messaging().onMessage(noti => {
      actionWithNotification(store, noti);
      PushLocalNotificationIOS(noti);
    });

    messaging().onNotificationOpenedApp(async noti => {
      actionWithNotification(store, {...noti, userInteraction: 1});
    });

    messaging().setBackgroundMessageHandler(async (noti: any) => {
      actionWithNotification(store, {...noti, userInteraction: 1});
    });

    const localNotification = 'localNotification';
    const typeNoti = 'notification';

    PushNotificationIOS.addEventListener(localNotification, e => {
      const data = e.getData();
      actionWithNotification(store, {...localnoti, ...data});
    });

    PushNotificationIOS.addEventListener(typeNoti, e => {
      const data = e.getData();
      actionWithNotification(store, data);
    });
    PushNotificationIOS.requestPermissions();
  }
}

const PushLocalNotificationAndroid = async notification => {
  PushNotification.localNotification({
    ...stringApp.notification,
    title: notification.title, // '1231231321312',
    message: notification.message, // 'My Notification Message', // (required)
    autoCancel: false,
    largeIcon: '@mipmap/ic_launcher_round',
    smallIcon: '@drawable/ic_notification',
    // largeIcon: 'ic_notification',
    // smallIcon: 'ic_notification',
    color: Ecolors.mainColor,

    // data: notification.data,
    /* Android Only Properties */
    repeatTime: 1, // (optional) Increment of configured repeatType. Check 'Repeating Notifications' section for more info.
    data: notification.data,
  });
};

const PushLocalNotificationIOS = async notification => {
  const obj = {
    id: notification.messageId || getUuid(),
    title: notification.notification.title,
    body: notification.notification.body,
    ...(notification.data || {}),
    data: notification.data,
    badge: 0,
    applicationIconBadgeNumber: 0,
  };
  PushNotificationIOS.addNotificationRequest(obj);
  localnoti = obj;
  PushNotificationIOS.setApplicationIconBadgeNumber(0);
};

const onRegisterWithToken = async (
  deviceToken: string,
  os?: any,
  store?: any,
) => {
  return;
  const deviceName = await DeviceInfo.getDeviceName();
  const deviceId = await DeviceInfo.getUniqueId();
  const osVersion = await DeviceInfo.getSystemVersion();
  const accountToken = await getStoreToken();

  const objDataDevice = {
    appVersion: stringApp.versionApp.number,
    deviceId: deviceId,
    deviceName: deviceName,
    deviceOS: os || 'ios',
    osVersion: osVersion,
    deviceToken: deviceToken,
  };
  setStoreData('objDataDevice', objDataDevice);
  if (!accountToken) {
    return;
  }
  const check: any = await apiAuth
    .checkWarningDeviceNotification({
      deviceId: deviceId,
      deviceToken: deviceToken,
    })
    .then((res: any) => {})
    .catch(async (err: any) => {
      store.dispatch(changeIsSmartOTP(false));
      if (err.keyName == 'USING_OTHER_DEVICE') {
        Alert.show({
          content: `alert.USING_OTHER_DEVICE`,
          type: 1,
          titleCancel: `alert.huybo`,
          titleConfirm: `alert.dongy`,
          onCancel: () => {},
          onConfirm: async () => {
            const res = await apiAuth.uploadNewDeviceNotification(
              objDataDevice,
            );
            store.dispatch(changeNoti(true));
          },
        });
      }
      if (err.keyName == 'USING_OTHER_DEVICE_SMART_OTP') {
        Alert.show({
          content: `ERROR_BE.USING_OTHER_DEVICE_SMART_OTP`,
          type: 1,
          titleCancel: `alert.huybo`,
          titleConfirm: `alert.dongy`,
          onCancel: () => {},
          onConfirm: async () => {
            navigate('RegisterSmartOTP');
          },
        });
      }
      if (err.keyName == 'USING_FIRST_DEVICE') {
        const res = await apiAuth.uploadNewDeviceNotification(objDataDevice);
        store.dispatch(changeNoti(true));
      }
    });
};

// const actionWithNotification = async (store, notification) => {
//   if (Platform.OS === 'ios') {
//     PushNotificationIOS.setApplicationIconBadgeNumber(0);
//   }

//   const statusScreen = store.getState().authen.statusScreen;
//   if (notification && notification.userInteraction) {
//     const noti = { ...notification, ...(notification.data || {}), };
//     Alert.show({
//       content: JSON.stringify(noti),
//       multilanguage: false
//     })
//     // const typeCode = noti.typeCode;
//     if (statusScreen == 'main') {
//       navigate('NotificationDetailsModal');
//       store.dispatch(
//         getNotificationDetails({
//           id: noti.notificationSendId,
//         }),
//       );
//       return;
//     } else {
//       store.dispatch(cacheNotification(noti));
//       Alert.show({
//         content: `alert.vuilongdangnhapdexemthongbao`,
//         type: 2,
//         onClose: () => {
//           navigate('LoginScreen');
//         },
//       });
//     }
//   }
// };

const actionWithNotification = (store, notification) => {
  if (Platform.OS === 'ios') {
    PushNotificationIOS.setApplicationIconBadgeNumber(0);
  }
  const statusScreen = store.getState().authen.statusScreen;
  if (notification && !!notification.userInteraction) {
    const noti = {...notification, ...(notification.data || {})};
    const typeCode = noti.typeCode || 'NONE';
    if (statusScreen == 'main') {
      switch (typeCode) {
        case 'OTPCODE':
          Alert.show({
            content: noti.detail,
            isHtml: true,
            onConfirm: () => {
              navigate('SoftOTPScreen', {
                data: noti,
              });
            },
          });
          return;
        default:
          navigate('NotificationDetailsModal');
          store.dispatch(
            getNotificationDetails({
              id: noti.notificationSendId,
            }),
          );
          return;
      }
    } else {
      switch (typeCode) {
        case 'OTPCODE':
          store.dispatch(cacheNotification(noti));
          Alert.show({
            content: `alert.vuilongdangnhapdexemthongbao`,
            type: 2,
            onClose: () => {
              navigate('LoginScreen');
            },
          });
          return;
        default:
          store.dispatch(
            cacheNotification({
              id: noti.notificationSendId,
            }),
          );
          Alert.show({
            content: `alert.vuilongdangnhapdexemthongbao`,
            type: 2,
            onClose: () => {
              navigate('LoginScreen');
            },
          });
      }
      return;
    }
  }
};

export {PushLocalNotificationAndroid, PushLocalNotificationIOS};
