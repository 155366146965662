const currency = `(VNĐ)`;
const currencyEn = `(VND)`;

const support = {
  phonesupport: `028 7301 7786`,
  hotline: `028 7301 7786`,
  emailsupport: `info@lighthousecapital.com.vn`,
};
const versionApp = {
  chplayID: 'com.fplatform.LightHouse',
  appStoreLocale: 'vn',
  appName: 'lhcapital-limo',
  appStoreID: 'id6449667502',
  number: 5,
};

const notification = {
  channelId: `fcm_fallback_notification_channel`,
  channelName: `fcm_fallback_notification_channel`,
  channelDescription: `fcm_fallback_notification_channel`,
};

const gender = {
  nam: 1,
  nu: 0,
};

const riskAssessment = [
  {
    id: 'holdingTime',
    content: `1. Thời gian đầu tư dự kiến`,
    data: [
      {
        id: 1,
        name: 'Dưới 12 tháng',
        nameEn: 'Dưới 12 tháng',
      },
      {
        id: 2,
        name: 'Từ 12 - 24 tháng',
        nameEn: 'Từ 12 - 24 tháng',
      },
      {
        id: 3,
        name: 'Trên 24 tháng',
        nameEn: 'Trên 24 tháng',
      },
    ],
  },
  {
    id: 'realized',
    content: `2. Mức độ chấp nhận rủi ro`,
    data: [
      {
        id: 1,
        name: 'Cao',
        nameEn: 'Cao',
      },
      {
        id: 2,
        name: 'Trung bình',
        nameEn: 'Trung bình',
      },
      {
        id: 3,
        name: 'Vừa phải',
        nameEn: 'Vừa phải',
      },
      {
        id: 4,
        name: 'Thấp',
        nameEn: 'Thấp',
      },
    ],
  },
  {
    id: 'experienceInvestment',
    content: `3. Kinh nghiệm trong đầu tư Chứng Khoán`,
    data: [
      {
        id: 1,
        name: 'Trên 5 năm',
        nameEn: 'Trên 5 năm',
      },
      {
        id: 2,
        name: 'Từ 1 - 5 năm',
        nameEn: 'Từ 1 - 5 năm',
      },
      {
        id: 3,
        name: 'Dưới 1 năm',
        nameEn: 'Dưới 1 năm',
      },
      {
        id: 4,
        name: 'Chưa có kinh nghiệm',
        nameEn: 'Chưa có kinh nghiệm',
      },
    ],
  },
  // {
  //   id: 'dividend',
  //   content: `Chính sách cổ tức`,
  //   data: [
  //     {
  //       id: 'REINVESTMENT',
  //       name: 'Tái đầu tư',
  //       nameEn: 'Tái đầu tư',
  //     },
  //     {
  //       id: 'PAID_TO_INVESTOR',
  //       name: 'Trả vào tài khoản',
  //       nameEn: 'Trả vào tài khoản',
  //     },
  //   ],
  // },
];

const riskAssessmentEn = [
  {
    id: 'holdingTime',
    content: `1. Estimated investment time`,
    data: [
      {
        id: 1,
        name: 'Under 12 months',
        nameEn: 'Under 12 months',
      },
      {
        id: 2,
        name: 'From 12 - 24 months',
        nameEn: 'From 12 - 24 months',
      },
      {
        id: 3,
        name: 'Over 24 months',
        nameEn: 'Over 24 months',
      },
    ],
  },
  {
    id: 'realized',
    content: `2. Level of risk tolerance`,
    data: [
      {
        id: 1,
        name: 'High',
        nameEn: 'High',
      },
      {
        id: 2,
        name: 'Medium',
        nameEn: 'Medium',
      },
      {
        id: 3,
        name: 'Medium',
        nameEn: 'Medium',
      },
      {
        id: 4,
        name: 'Low',
        nameEn: 'Low',
      },
    ],
  },
  {
    id: 'experienceInvestment',
    content: `3. Experience in Stock investment`,
    data: [
      {
        id: 1,
        name: 'Over 5 years',
        nameEn: 'Over 5 years',
      },
      {
        id: 2,
        name: 'From 1 - 5 years',
        nameEn: 'From 1 - 5 years',
      },
      {
        id: 3,
        name: 'Less than 1 year',
        nameEn: 'Less than 1 year',
      },
      {
        id: 4,
        name: 'No experience yet',
        nameEn: 'No experience yet',
      },
      // {
      //   id: 'dividend',
      //   content: `Dividend policy`,
      //   data: [
      //     {
      //       id: 'REINVESTMENT',
      //       name: 'Reinvestment',
      //       nameEn: 'Reinvestment',
      //     },
      //     {
      //       id: 'PAID_TO_INVESTOR',
      //       name: 'Pay into account',
      //       nameEn: 'Pay into account',
      //     },
      //   ],
      // },
    ],
  },
];

const Dividend = {
  PAID_TO_INVESTOR: {
    key: 'PAID_TO_INVESTOR',
    name: 'Trả vào tài khoản',
  },
  REINVESTMENT: {
    key: 'REINVESTMENT',
    name: 'Tái đầu tư',
  },
};
const DividendEn = {
  PAID_TO_INVESTOR: {
    key: 'PAID_TO_INVESTOR',
    name: 'Pay into account',
  },
  REINVESTMENT: {
    key: 'REINVESTMENT',
    name: 'Reinvestment',
  },
};

const monthlyIncom = [
  {
    id: `1`,
    name: `< 30,000,000 ${currency}`,
    nameEn: `< 30,000,000 ${currency}`,
  },
  {
    id: `2`,
    name: `30,000,000 VNĐ - 80,000,000 ${currency}`,
    nameEn: `30,000,000 VNĐ - 80,000,000 ${currency}`,
  },
  {
    id: `3`,
    name: `81,000,000 VNĐ - 150,000,000 ${currency}`,
    nameEn: `81,000,000 VNĐ - 150,000,000 ${currency}`,
  },
  {
    id: `4`,
    name: `> 150,000,000 ${currency}`,
    nameEn: `> 150,000,000 ${currency}`,
  },
];

const monthlyIncomEn = [
  {
    id: `1`,
    name: `< 30,000,000 ${currencyEn}`,
    nameEn: `< 30,000,000 ${currencyEn}`,
  },
  {
    id: `2`,
    name: `30,000,000 VNĐ - 80,000,000 ${currencyEn}`,
    nameEn: `30,000,000 VNĐ - 80,000,000 ${currencyEn}`,
  },
  {
    id: `3`,
    name: `81,000,000 VNĐ - 150,000,000 ${currencyEn}`,
    nameEn: `81,000,000 VNĐ - 150,000,000 ${currencyEn}`,
  },
  {
    id: `4`,
    name: `> 150,000,000 ${currencyEn}`,
    nameEn: `> 150,000,000 ${currencyEn}`,
  },
];

const source = [
  {
    id: 'SOURCE_SALARY',
    name: 'Lương',
    nameEn: 'Salary',
  },
  {
    id: 'SOURCE_BUSINESS',
    name: 'Kinh doanh',
    nameEn: 'Business Income',
  },
  {
    id: 'SOURCE_OTHER',
    name: 'Khác',
    nameEn: 'Other',
  },
];
const sourceEn = [
  {
    id: 'SOURCE_SALARY',
    name: 'Salary',
    nameEn: 'Salary',
  },
  {
    id: 'SOURCE_BUSINESS',
    name: 'Business',
    nameEn: 'Business',
  },
  {
    id: 'SOURCE_OTHER',
    name: 'Other',
    nameEn: 'Other',
  },
];
export const stringApp = {
  appName: 'LHCapital LiMO',
  appLink: 'LHCapital_LiMO',
  companyName: 'LHCapital_LiMO',
  tellerCode: 'LHCapital_LiMO',
  riskAssessment: riskAssessment,
  riskAssessmentEn: riskAssessmentEn,
  source: source,
  sourceEn: sourceEn,
  monthlyIncom: monthlyIncom,
  monthlyIncomEn: monthlyIncomEn,
  currency: currency,
  currencyEn: currencyEn,
  versionApp: versionApp,
  hotline: support.hotline,
  phonesupport: support.phonesupport,
  emailsupport: support.emailsupport,
  gender: gender,
  notification,
  Dividend,
  DividendEn,
};
