import {Button, Div, Label} from 'components';
import {Ecolors, EStyle} from 'constant';
import React from 'react';
import {useDispatch} from 'react-redux';
import {focusProduct} from 'reducer/investment';
import {navigate} from 'services';
import {useAppSelector} from 'store/hooks';
import {convertPercent} from 'utils';
interface IData {
  name: string;
  nameEn: string;
  tradingDayOfNavCurrently: string;
  valuationdate: string;
  navPre: string | number;
  navCurrently: string | number;
  navPercent: string | number;
  id: string | number;
  productType: any;
  color: string;
}
interface IProps {
  data: IData;
}
function ItemListInvestment({data}: IProps) {
  const dispatch = useDispatch();
  const I18nState = useAppSelector(state => state.languages.I18nState);

  const {
    name,
    id,
    color,
    productType,
    tradingDayOfNavCurrently,
    valuationdate,
    navPercent,
    navCurrently,
    nameEn,
  } = data;
  const isNewFund = productType?.code == 'NEW_FUND';

  return (
    <Button
      isScale={false}
      onPress={() => {
        dispatch(
          focusProduct({
            data,
          }),
        );
        navigate('InvestmentDetailsScreen');
      }}
      paddingVertical={16}
      paddingHorizontal={21}
      minHeight={isNewFund ? 60 : 100}
      marginHorizontal={16}
      borderRadius={8}
      borderColor={Ecolors.bordercolor}
      style={EStyle.shadowItem}
      backgroundColor={Ecolors.whiteColor}
      borderWidth={0.8}>
      <Div flexDirection="row" alignItems="center">
        <Label multilanguage={false} fontWeight={'700'}>
          {I18nState == 'vi' ? name : nameEn}
        </Label>
        {isNewFund && (
          <Div
            backgroundColor={Ecolors.mainColor}
            paddingHorizontal={5}
            paddingVertical={2}
            marginLeft={5}
            marginRight={10}
            borderRadius={5}>
            <Label
              multilanguage={false}
              size={14}
              color={Ecolors.whiteColor}
              fontWeight={'700'}>
              {'IPO'}
            </Label>
          </Div>
        )}
      </Div>
      {!isNewFund && (
        <Div
          paddingTop={10}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Div>
            <Label size={14}>{`investmentscreen.thaydoisovoidaunam`}</Label>
            <Div
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}>
              <Label size={14}>{`investmentscreen.taingay`}</Label>
              <Label multilanguage={false}>{` ${
                valuationdate || tradingDayOfNavCurrently || ''
              }`}</Label>
            </Div>
          </Div>
          <Div>
            <Label
              multilanguage={false}
              color={
                navPercent && navPercent < 0
                  ? Ecolors.redColor
                  : Ecolors.greenColor
              }
              size={18}
              fontWeight={'700'}>
              {navPercent >= 0 && '+'}
              {`${convertPercent(navPercent)}`}
            </Label>
          </Div>
        </Div>
      )}
    </Button>
  );
}

export default React.memo(ItemListInvestment);
