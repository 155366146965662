import {useRoute} from '@react-navigation/native';
import {Div, HeaderBack, Label} from 'components';
import {EStyle, Ecolors} from 'constant';
import React from 'react';
import {ScrollView} from 'react-native';
import ComBankContent from 'screens/MainScreen/CreateOrderModal/ComBankContent';
import {useAppSelector} from 'store/hooks';
import {convertNumber, convertTimestamp, parseMultilanguage} from 'utils';

function RowSpace(p: {title: string; content: string}) {
  return (
    <Div
      flexDirection={'row'}
      alignItems={'center'}
      marginTop={9}
      justifyContent={'space-between'}>
      <Label size={14}>{p.title}</Label>
      <Label size={14} multilanguage={false}>
        {p.content}
      </Label>
    </Div>
  );
}
const ComL = (p: {
  nextClosedBookTime: any;
  nextCycleNumber: any;
  I18nState: any;
}) => {
  const {I18nState, nextCycleNumber, nextClosedBookTime} = p;
  return (
    <Div flex={1} padding={16}>
      <Label multilanguage={false} size={14}>
        {I18nState == 'vi' ? (
          <Label size={14} multilanguage={false}>
            {`Thời điểm nhận tiền cuối cùng của kỳ tháng `}
            <Label size={14} fontWeight={'700'} multilanguage={false}>
              {nextCycleNumber}
            </Label>
            {` trước `}
            <Label size={14} fontWeight={'700'} multilanguage={false}>
              {nextClosedBookTime}
              {`.`}
            </Label>
            <Label
              fontWeight={'700'}
              size={14}
              multilanguage={false}>{`\n\nLưu ý:\n`}</Label>
            <Label
              multilanguage={false}
              size={
                14
              }>{`- Nếu quý khách chuyển tiền sớm, lệnh sẽ được khớp với các phiên giao dịch sớm hơn trong tháng.\n- Quý khách có thể chọn một ngày bất kỳ trong tháng làm ngày thanh toán Chương trình Đầu tư Định kỳ và cố gắng đảm bảo việc duy trì đóng tiền theo kế hoạch đã đăng ký.\n- Quý khách không cần tạo lệnh mua mà chỉ thực hiện chuyển tiền vào tài khoản nhận tiền của Quỹ để thực hiện lệnh mua định kỳ.`}</Label>
          </Label>
        ) : (
          <Label size={14} multilanguage={false}>
            {`You need to transfer money to invest for SIP of term `}
            <Label size={14} fontWeight={'700'} multilanguage={false}>
              {nextCycleNumber}
            </Label>
            {` before `}
            <Label size={14} fontWeight={'700'} multilanguage={false}>
              {nextClosedBookTime}
              {`.`}
            </Label>
            <Label
              fontWeight={'700'}
              size={14}
              multilanguage={false}>{`\n\nNotice: \n`}</Label>
            <Label
              multilanguage={false}
              size={
                14
              }>{`- If you transfer money early, the order will be matched with trading sessions earlier in the month.\n- You can choose any day of the month as your Recurring Investment Program payment date and try to ensure ensure the maintenance of payment according to the registered plan.\n- Quý khách không cần tạo lệnh mua mà chỉ thực hiện chuyển tiền vào tài khoản nhận tiền của Quỹ để thực hiện lệnh mua định kỳ.`}</Label>
          </Label>
        )}
      </Label>
    </Div>
  );
};

function AutoInvestOrderDetails() {
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const route = useRoute<any>();
  const {
    productProgramName,

    supervisoryBankAccountName,
    productProgramNameEn,
    supervisoryBankAccountBranch,
    investmentNumber,
    supervisoryBankAccountNumber,
    minAmount,
    dataBank,
    nextCycleNumber,
    enable,
    nextClosedBookTime,
    ordersInfo,
    transferContent,
  } = route.params.data;

  const p = {
    bankSuperVisory: {
      dataBank,
      name: supervisoryBankAccountName,
      number: supervisoryBankAccountNumber,
      branch: supervisoryBankAccountBranch,
    },
    excuseTempVolumn: {
      transferContent: transferContent,
    },
    amount: `${minAmount}`,
  };

  return (
    <Div height={'100%'} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack
        // loading={loading}
        type={2}
        multilanguage={false}
        title={productProgramName}
        // iconRight={Icons.delete}
        onRightPress={() => {}}
      />
      <ScrollView>
        {!!enable && (
          <Div paddingHorizontal={16}>
            <Label
              marginVertical={16}
              fontWeight={
                '700'
              }>{`transactionscreen.thongtinchuyenkhoan`}</Label>
            <ComBankContent {...p} />
            <Div
              marginTop={20}
              backgroundColor={Ecolors.bgtime}
              borderRadius={5}
              minHeight={100}
              overflow={'hidden'}
              flexDirection={'row'}>
              <Div
                width={4}
                height={'100%'}
                backgroundColor={Ecolors.yellowColor}
              />
              <ComL
                I18nState={I18nState}
                nextClosedBookTime={nextClosedBookTime}
                nextCycleNumber={nextCycleNumber}
                key={'121'}
              />
            </Div>
          </Div>
        )}
        <Div
          width={'100%'}
          marginTop={20}
          height={0.7}
          backgroundColor={Ecolors.grayColor}
        />
        <Div paddingHorizontal={16}>
          <Label
            marginVertical={16}
            fontWeight={'700'}>{`transactionscreen.lichsukhoplenh`}</Label>
          {!!ordersInfo?.length &&
            ordersInfo.map((item: any, index: number) => {
              const {
                cycleNumber,
                statusName,
                lockAmount,
                sessionTime,
                statusCode,
              } = item;
              return (
                <Div
                  marginBottom={10}
                  borderRadius={8}
                  key={index}
                  borderWidth={0.8}
                  borderColor={Ecolors.bordercolor}
                  backgroundColor={Ecolors.whiteColor}
                  style={EStyle.shadowItem}
                  paddingHorizontal={15}
                  paddingTop={4}
                  paddingBottom={18}
                  minHeight={100}>
                  <RowSpace
                    title={'transactionscreen.kydautu'}
                    content={cycleNumber}
                  />
                  <RowSpace
                    title={'transactionscreen.sotiendautu'}
                    content={convertNumber(lockAmount)}
                  />
                  <RowSpace
                    title={'transactionscreen.trangthai'}
                    content={parseMultilanguage(
                      `transactionscreen.${statusCode}`,
                    )}
                  />
                  <RowSpace
                    title={'transactionscreen.ngaykhoplenh'}
                    content={convertTimestamp(sessionTime)}
                  />
                </Div>
              );
            })}
        </Div>
        <Div height={100} />
      </ScrollView>
    </Div>
  );
}

export default React.memo(AutoInvestOrderDetails);
