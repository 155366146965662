import {Alert, Div} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useRef, useState} from 'react';
import {apiInvestment} from 'services/apis/apiInvestment';
import {useAppSelector} from 'store/hooks';
import {widthScreen} from 'utils';
import OrderSellStep1 from './OrderSellStep1';
import OrderSellStep2 from './OrderSellStep2';
import OrderSellStep3 from './OrderSellStep3';

interface Props {
  setStepTimeLine: (a: number) => void;
  stepTimeLine?: number;
  initData?: any;
}

function OrderSell({setStepTimeLine, stepTimeLine, initData}: Props) {
  // state order buy
  const I18nState = useAppSelector(state => state.languages.I18nState);

  const [product, setProduct] = useState<any>(null);
  // const listProduct = useAppSelector(state => getListProduct(state));
  const listProduct = useAppSelector(state => state.asset.asset.productList);
  const [scheme, setScheme] = useState<any>(null);
  const [listScheme, setListScheme] = useState<Array<any>>([]);
  const [amount, setAmount] = useState<string>('');
  const [currentSession, setCurrentSession] = useState<any>(null);
  const [excuseTempVolumn, setExcuseTempVolumn] = useState<any>(null);
  const [bankSuperVisory, setBankSuperVisory] = useState<any>(null);
  const [orderData, setOrderData] = useState<any>({});

  const [tellerCode, setTellerCode] = useState<string>('');
  // control header
  const [loading, setLoading] = useState<boolean>(false);

  const scrollViewRef = useRef<any>(null);

  useEffect(() => {
    if (initData?.product) {
      onChangeProduct(initData?.product);
    }
    if (initData?.scheme) {
      setScheme(initData?.scheme);
    }
    return () => {};
  }, [initData]);

  const onScroll = (e: any) => {
    const offset = Math.round(e.nativeEvent.contentOffset.x / widthScreen) + 1;
    if (offset != stepTimeLine) {
      setStepTimeLine(offset);
    }
  };

  const onNext = () => {
    setStepTimeLine((t: number) => t + 1);
    return;
  };

  const onPre = (step?: number) => {
    setStepTimeLine((t: number) => (step ? step : t == 1 ? t : t - 1));
    if (step) {
      Promise.all([
        setProduct(null),
        setScheme(null),
        setExcuseTempVolumn(null),
        setBankSuperVisory(null),
        setCurrentSession(null),
        setAmount(''),
        // setBeginBuyAutoStartDate(''),
      ]);
    }
  };

  const onExcuseTempVolumn = async () => {
    if (!product || !scheme || !amount) {
      return;
    }
    try {
      const res = await apiInvestment.investmentExcuseTempMoney({
        volume: amount.replace(/[,]/g, ''),
        productId: product?.id,
        productProgramId: scheme?.id,
      });
      if (res.status == 200) {
        setExcuseTempVolumn(res.data);
        return;
      }
      setExcuseTempVolumn(null);
      return;
    } catch (error: any) {
      setExcuseTempVolumn(null);
      Alert.showError({
        content: I18nState == 'vi' ? error.message : error.messageEn,
        multilanguage: false,
      });
    }
  };

  const onChangeProduct = async (e: any) => {
    setLoading(true);
    setProduct(e);
    setScheme(null);
    setAmount('');
    try {
      const productDetails = await apiInvestment.loadProductDetails({
        id: e.id,
      });

      const res = await apiInvestment.investmentLoadScheme({
        productId: e.id,
      });
      const ses = await apiInvestment.checkOverCurrentSession({
        productId: e.id,
      });

      if (productDetails.status == 200) {
        setProduct(productDetails.data);
      }
      if (res.status == 200) {
        setListScheme(res.data);
      }
      if (ses.status == 200) {
        setCurrentSession(ses.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const switchStep = step => {
    switch (step) {
      case 1:
        return (
          <OrderSellStep1
            onNext={onNext}
            tellerCode={tellerCode}
            setTellerCode={setTellerCode}
            loading={loading}
            product={product}
            listScheme={listScheme}
            setListScheme={setListScheme}
            onExcuseTempVolumn={onExcuseTempVolumn}
            onChangeProduct={onChangeProduct}
            listProduct={listProduct}
            setProduct={setProduct}
            scheme={scheme}
            setScheme={setScheme}
            amount={amount}
            setAmount={setAmount}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            setExcuseTempVolumn={setExcuseTempVolumn}
            excuseTempVolumn={excuseTempVolumn}
            bankSuperVisory={bankSuperVisory}
            setBankSuperVisory={setBankSuperVisory}
          />
        );
      case 2:
        return (
          <OrderSellStep2
            tellerCode={tellerCode}
            stepTimeLine={stepTimeLine}
            onNext={onNext}
            onPre={onPre}
            product={product}
            setOrderData={setOrderData}
            scheme={scheme}
            amount={amount}
            currentSession={currentSession}
            excuseTempVolumn={excuseTempVolumn}
            bankSuperVisory={bankSuperVisory}
          />
        );
      case 3:
        return (
          <OrderSellStep3
            currentSession={currentSession}
            stepTimeLine={stepTimeLine}
            orderData={orderData}
            amount={amount}
            onPre={onPre}
            product={product}
            scheme={scheme}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Div backgroundColor={Ecolors.whiteColor} width={'100%'}>
      {switchStep(stepTimeLine)}
    </Div>
  );
}

export default React.memo(OrderSell);
