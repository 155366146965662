import SearchModal from './SearchModal';
// profile
import OtpRequestModal from 'screens/UnAuthorized/OtpRequestModal';
import ChangeAddressScreen from './ProfileScreen/ChangeAddressScreen';
import ChangeEmailScreen from './ProfileScreen/ChangeEmailScreen';
import ChangePasswordScreen from './ProfileScreen/ChangePasswordScreen';
import LoginInfoScreen from './ProfileScreen/LoginInfoScreen';
// account verify
import AccountVerifyScreen from './AccountVerifyScreen';
import AccountInfoModal from './AccountVerifyScreen/AccountInfoModal';
import AddressInfoModal from './AccountVerifyScreen/AddressInfoModal';
import BankInfoModal from './AccountVerifyScreen/BankInfoModal';
import ConfirmModal from './AccountVerifyScreen/ConfirmModal';
import EditAccountInfoModal from './AccountVerifyScreen/EditAccountInfoModal';
import EditAddressInfoModal from './AccountVerifyScreen/EditAddressInfoModal';
import EditBankInfoModal from './AccountVerifyScreen/EditBankInfoModal';
import RiskConfirmModal from './AccountVerifyScreen/RiskConfirmModal';
// investment manage
// tab
import AssetScreen from './AssetScreen';
import ListAssetDetailsModal from './AssetScreen/ListAssetDetailsModal';
import InvestmentScreen from './InvestmentScreen';
import OverviewScreen from './OverviewScreen';
import ProfileScreen from './ProfileScreen';
import TransactionScreen from './TransactionScreen';
// setting screen
import SettingScreen from './SettingScreen';
import ActiveBiometricModal from './SettingScreen/ActiveBiometricModal';
import DeleteAccountModal from './SettingScreen/DeleteAccountModal';
import SettingLanguageModal from './SettingScreen/SettingLanguageModal';

import NotificationScreen from './NotificationScreen';
import NotificationDetailsModal from './NotificationScreen/NotificationDetailsModal';
import SupportScreen from './SupportScreen';

// orrder transaction
import AutoInvestOrderDetailsModal from './TransactionScreen/AutoInvestOrderDetailsModal';
import OrderBuyDetailsModal from './TransactionScreen/OrderBuyDetailsModal';
import OrderHistoryDetailsModal from './TransactionScreen/OrderHistoryDetailsModal';
import OrderSellDetailsModal from './TransactionScreen/OrderSellDetailsModal';
import OrderTransferDetailsModal from './TransactionScreen/OrderTransferDetailsModal';

//invest ment
import InvestmentDetailsScreen from './InvestmentScreen/InvestmentDetailsScreen';
import MiniEkycModal from './InvestmentScreen/MiniEkycModal';
// create order
import CreateOrderModal from './CreateOrderModal';
import FeeTableModal from './CreateOrderModal/FeeTableModal';
import TransferContentStepModal from './CreateOrderModal/TransferContentStepModal';
import InvestmentLibraryScreen from './InvestmentLibraryScreen';
import InvestmentLibraryDetailScreen from './InvestmentLibraryScreen/InvestmentLibraryDetailScreen';
import OnlineTradingModal from './OnlineTradingModal/OnlineTradingModal';
import SoftOTPScreen from './SoftOTPScreen';
import RegisterSmartOTP from './SoftOTPScreen/RegisterSmartOTP';
// digital signaturescreen
// import DigitalSignatureScreen from './DigitalSignature/DigitalSignatureScreen';

import ControlEKYCScreen from '../UnAuthorized/ControlEKYCScreen';
import ReviewInfoModal from '../UnAuthorized/ControlEKYCScreen/ReviewInfoModal';
const screens: any = {
  //   ChatScreen,
  SearchModal,

  //   ProfileScreen,
  ChangeEmailScreen,
  LoginInfoScreen,
  ChangePasswordScreen,
  ChangeAddressScreen,
  OnlineTradingModal,
  //
  OtpRequestModal,
  // accoutn verify
  AccountVerifyScreen,
  AccountInfoModal,
  BankInfoModal,
  // ProgramDetailsModal,
  EditBankInfoModal,
  EditAddressInfoModal,
  EditAccountInfoModal,
  AddressInfoModal,
  ConfirmModal,
  RiskConfirmModal,
  ReviewInfoModal,
  // setting screen
  SettingScreen,
  DeleteAccountModal,
  SupportScreen,
  SettingLanguageModal,
  ActiveBiometricModal,
  NotificationScreen,
  NotificationDetailsModal,
  ListAssetDetailsModal,
  // order transaction
  OrderBuyDetailsModal,
  AutoInvestOrderDetailsModal,
  OrderSellDetailsModal,
  OrderTransferDetailsModal,
  OrderHistoryDetailsModal,
  // FilterHistoryModal,
  ControlEKYCScreen,
  // invest ment
  InvestmentDetailsScreen,
  // create order
  CreateOrderModal,
  TransferContentStepModal,
  FeeTableModal,
  // add more function ádasdada in unauthen
  MiniEkycModal,

  InvestmentLibraryScreen,
  InvestmentLibraryDetailScreen,
  // softotp
  SoftOTPScreen,
  RegisterSmartOTP,
  // digital signaturescreen
  // DigitalSignatureScreen,
};

const mainStackScreen = Object.keys(screens)
  .filter(a => !a.includes('modal') && !a.includes('Modal'))
  .map(item => {
    return {
      name: item,
      component: screens?.[item],
    };
  });

const mainStackModal = Object.keys(screens)
  .filter(a => a.includes('modal') || a.includes('Modal'))
  .map(item => {
    return {
      name: item,
      component: screens?.[item],
    };
  });
const mainTab = [
  {
    name: 'OverviewScreen',
    component: OverviewScreen,
  },
  {
    name: 'TransactionScreen',
    component: TransactionScreen,
  },
  {
    name: 'InvestmentScreen',
    component: InvestmentScreen,
  },
  {
    name: 'AssetScreen',
    component: AssetScreen,
  },
  {
    name: 'ProfileScreen',
    component: ProfileScreen,
  },
];

export {mainStackModal, mainStackScreen, mainTab};
