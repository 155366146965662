import {BottomSheetDialog, Button, Div, ImageView, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useRef, useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {changeLanguage} from 'reducer/languages';
import {useAppDispatch, useAppSelector} from 'store/hooks';

const Item = (p: {onPress: (t: any) => void; data: any}) => {
  return (
    <Button
      onPress={() => {
        p.onPress(p.data);
      }}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      paddingLeft={4}
      maxWidth={63}
      minWidth={55}
      paddingVertical={5}
      backgroundColor={Ecolors.mainColor}>
      <ImageView
        source={p.data.icons}
        height={22}
        width={22}
        resizeMode={'contain'}
      />
      <Label
        marginBottom={3}
        size={14}
        color={Ecolors.whiteColor}
        multilanguage={false}>
        {p.data.name}
      </Label>
    </Button>
  );
};

function DropdownMultiLanguage(P: {}) {
  const buttonRef = useRef();
  const bottomsheet = useRef();
  const [style, setStyle] = useState({});
  const insets = useSafeAreaInsets();
  const activeLanguage = useAppSelector(
    state => state.languages.activeLanguage,
  );
  const listLanguage = useAppSelector(state => state.languages.listLanguage);
  const dispatch = useAppDispatch();

  const doChangeLanguage = () => {
    if (buttonRef.current) {
      buttonRef.current.measure((x, y, width, height, px, py) => {
        bottomsheet.current.show();
        setStyle({
          top: py - insets.top,
          left: px,
        });
      });
    }
  };

  return (
    <>
      <BottomSheetDialog
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        ref={bottomsheet}>
        <Div
          position="absolute"
          zIndex={999}
          elevation={999}
          style={style}
          overflow="hidden"
          borderRadius={13}>
          {listLanguage.map((item, index) => {
            return (
              <Div
                key={index}
                borderBottomWidth={index < listLanguage.length - 1 ? 0.8 : 0}
                borderBottomColor={Ecolors.bordercolor}>
                <Item
                  data={item}
                  onPress={res => {
                    dispatch(changeLanguage(res));
                    bottomsheet.current.hide();
                  }}
                />
              </Div>
            );
          })}
        </Div>
      </BottomSheetDialog>
      <Button
        buttonRef={buttonRef}
        onPress={() => doChangeLanguage()}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        paddingLeft={4}
        maxWidth={63}
        minWidth={55}
        borderRadius={30}
        paddingVertical={5}
        backgroundColor={Ecolors.mainColor}>
        <ImageView
          source={activeLanguage.icons}
          height={22}
          width={22}
          resizeMode={'contain'}
        />
        <Label
          marginBottom={3}
          size={14}
          color={Ecolors.whiteColor}
          multilanguage={false}>
          {activeLanguage.name}
        </Label>
      </Button>
    </>
  );
}

export default React.memo(DropdownMultiLanguage);
