import {createAsyncThunk} from '@reduxjs/toolkit';
import {apiAsset} from './../../services/apis/apiAsset';

export const loadAsset = createAsyncThunk(
  `asset@/load`,
  async (params: {}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.loadDashboard(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const loadBanner = createAsyncThunk(
  `banner@/load`,
  async (params: {}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.getBanner(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const loadHomeArticle = createAsyncThunk(
  `articlehome@/load`,
  async (params: {}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.getHomeArticle();
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadTabArticle = createAsyncThunk(
  `articletable@/load`,
  async (
    params: {
      pagination: {
        currentPage: number;
        itemsPerPage?: number;
        sort?: any;
        totalItemCount?: number;
      };
      queries: {
        categoriId: number;
      };
      category?: any;
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiAsset.getArticletab(params);
      if (res.status == 200) {
        return {...res.data, category: params.category};
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const loadCategory = createAsyncThunk(
  `category@/load`,
  async (params: {}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.getCategory();
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
export const focusArticle = createAsyncThunk(
  `article@/focus`,
  async (params: {id: number}, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.getArticleDetails({id: params.id});
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const getNotificationType = createAsyncThunk(
  `notificationType@/load`,
  async (params: any, {rejectWithValue, dispatch}) => {
    try {
      const res = await apiAsset.getNotificationType();
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const getNotificationTable = createAsyncThunk(
  `notificationTable@/load`,
  async (
    params: {
      pagination: {
        currentPage: number;
        itemsPerPage?: number;
        sort?: any;
        totalItemCount?: number;
      };
      queries: {
        notificationTypeId: any;
      };
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiAsset.getNotificationTable(params);
      if (res.status == 200) {
        return {
          ...res.data,
          notificationTypeId: params.queries.notificationTypeId,
        };
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const getNotificationDetails = createAsyncThunk(
  `notificationDetails@/load`,
  async (
    params: {
      id: any;
    },
    {rejectWithValue, dispatch},
  ) => {
    try {
      const res = await apiAsset.getNotificationLoadByID(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
