import { Button, Div, ImageView, Label } from 'components';
import { Ecolors, Icons } from 'constant';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { navigate } from 'services';
import { widthScreen } from 'utils';

const size = 55;

interface IPropsFooterApp {
  state: {
    routes: Array<any>;
    index: number;
  };
}

interface IPropsItemButotn {
  isFocus: boolean;
  index: number;
  title: string;
  icon: any;
  iconColor: any;
  onPress: () => void;
}

function ItemButton(props: IPropsItemButotn) {
  if (props.index == 2) {
    return (
      <Div
        alignItems="center"
        justifyContent="center"
        backgroundColor={Ecolors.greenColor}
        style={{
          width: widthScreen / 5,
        }}>
        <Div
          style={{
            ...StyleSheet.absoluteFillObject,
            width: widthScreen / 5,
            height: 100,
            top: Platform.OS === 'android' ? -71 : -65,
          }}
          alignItems="center"
          justifyContent="center"
          elevation={999}
          zIndex={999}>
          <Div
            width={size}
            alignItems="center"
            justifyContent="center"
            borderTopLeftRadius={size}
            paddingTop={8}
            backgroundColor={Ecolors.whiteColor}
            borderTopRightRadius={size}>
            <Button
              widthHeight={size - 10}
              onPress={() => {
                if (props.isFocus) {
                  return;
                }
                props.onPress && props.onPress();
              }}
              borderRadius={size - 10}
              alignItems="center"
              justifyContent="center"
              backgroundColor={
                props.isFocus ? Ecolors.mainColor : Ecolors.bordercolor
              }>
              <ImageView
                heightWidth={26}
                // tintColor={
                //   props.isFocus ? Ecolors.whiteColor : Ecolors.mainColor
                // }
                source={props.isFocus ? props.iconColor : props.icon}
                resizeMode={'contain'}
              />
            </Button>
            <Label
              marginTop={3}
              marginBottom={4}
              size={12}
              color={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}>
              {props.title}
            </Label>
          </Div>
        </Div>
      </Div>
    );
    return (
      <Button
        onPress={() => {
          if (props.isFocus) {
            return;
          }
          props.onPress && props.onPress();
        }}
        // flex={1}
        style={{
          width: widthScreen / 5,
        }}
        alignItems={'center'}
        justifyContent={'center'}>
        <ImageView
          heightWidth={22}
          tintColor={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
          source={props.icon}
          resizeMode={'contain'}
        />
        <Label
          marginTop={3}
          marginBottom={4}
          size={12}
          color={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}>
          {props.title}
        </Label>
      </Button>
    );
  }
  return (
    <Button
      onPress={() => {
        if (props.isFocus) {
          return;
        }
        props.onPress && props.onPress();
      }}
      style={{
        width: widthScreen / 5,
      }}
      alignItems={'center'}
      justifyContent={'center'}>
      <ImageView
        heightWidth={22}
        // tintColor={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
        source={props.isFocus ? props.iconColor : props.icon}
        resizeMode={'contain'}
      />
      <Label
        marginTop={3}
        marginBottom={4}
        size={12}
        color={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}>
        {props.title}
      </Label>
    </Button>
  );
}

function FooterApp(props: IPropsFooterApp) {
  const insest = useSafeAreaInsets();

  const ObjectAction: any = {
    0: {
      title: `footerapp.OverviewScreen`,
      icon: Icons.overview,
      iconColor: Icons.overview_color,
      onPress: () => {
        navigate('OverviewScreen', {}, true);
      },
    },
    1: {
      title: `footerapp.TransactionScreen`,
      icon: Icons.transaction,
      iconColor: Icons.transaction_color,
      onPress: () => {
        navigate('TransactionScreen', {}, true);
      },
    },
    2: {
      title: `footerapp.InvestmentScreen`,
      icon: Icons.investment,
      iconColor: Icons.investment_color,
      onPress: () => {
        navigate('InvestmentScreen', {}, true);
      },
    },
    3: {
      title: `footerapp.AssetScreen`,
      icon: Icons.asset,
      iconColor: Icons.asset_color,
      onPress: () => {
        navigate('AssetScreen', {}, true);
      },
    },
    4: {
      title: `footerapp.ProfileScreen`,
      icon: Icons.profile,
      iconColor: Icons.profile_color,
      onPress: () => {
        navigate('ProfileScreen', {}, true);
      },
    },
  };
  return (
    <Div
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-evenly'}
      backgroundColor={Ecolors.whiteColor}
      borderTopColor={Ecolors.grayColor}
      borderTopWidth={0.5}
      style={{
        paddingBottom: insest.bottom,
      }}
      paddingTop={10}>
      {props.state.routes?.map((item: any, index: number) => {
        return (
          <ItemButton
            key={index}
            onPress={() => ObjectAction[index].onPress()}
            isFocus={index == props.state.index}
            index={index}
            title={ObjectAction[index].title}
            icon={ObjectAction[index].icon}
            iconColor={ObjectAction[index].iconColor}
          />
        );
      })}
    </Div>
  );
}

export default React.memo(FooterApp);
