import axios, { AxiosError, AxiosResponse } from 'axios';
import { Alert } from 'components';
import { urlApp } from 'constant';
import md5 from 'md5';
import { PostMSG } from 'utils';
import { getStoreData, getStoreToken } from 'utils/storage';

const baseURL = urlApp.APIURL;
const axiosApp = axios.create({
  baseURL: baseURL + 'api/',
  timeout: 10000,
});

const aa = axios.create({
  baseURL: baseURL + 'api/',
  timeout: 5000,
});

const functionAuthen = err => {
  if (err.status == 401) {
    Alert.showError({
      content: err.message,
      multilanguage: false,
      type: 2,
      is401: true,
      onClose: () => {
        PostMSG('RequestAuthenFailure', {});
      },
      onConfirm: () => {
        PostMSG('RequestAuthenFailure', {});
      },
    });
    return;
  }
};

axiosApp.interceptors.request.use(
  async (config: any) => {
    const token = await getStoreToken();
    const idNo = await getStoreData('idNo');
    const phone = await getStoreData('phone');
    const refCode = await getStoreData('refCode');
    const agentCode = await getStoreData('agentCode');
    const secretKey = await getStoreData('secretKey');
    if (idNo && phone && refCode && agentCode && secretKey) {
      config.headers['Authorization'] = `Bearer ${md5(
        `${idNo}${phone}${refCode}${agentCode}${secretKey}`,
      )}`;
      config.headers['idNo'] = idNo;
      config.headers['phone'] = phone;
      config.headers['refCode'] = refCode;
      config.headers['agentCode'] = agentCode;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = `Bearer `;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error: any) => Promise.reject(error),
);

aa.interceptors.request.use(
  async (config: any) => {
    const token = await getStoreToken();
    const idNo = await getStoreData('idNo');
    const phone = await getStoreData('phone');
    const refCode = await getStoreData('refCode');
    const agentCode = await getStoreData('agentCode');
    const secretKey = await getStoreData('secretKey');
    if (idNo && phone && refCode && agentCode && secretKey) {
      config.headers['Authorization'] = `Bearer ${md5(
        `${idNo}${phone}${refCode}${agentCode}${secretKey}`,
      )}`;
      config.headers['idNo'] = idNo;
      config.headers['phone'] = phone;
      config.headers['refCode'] = refCode;
      config.headers['agentCode'] = agentCode;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = `Bearer `;
    }
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['Accept'] = 'application/json';
    return config;
  },
  (error: any) => Promise.reject(error),
);

aa.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        messageEn?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>,
  ) => {
    throw (
      err.response?.data || {
        message: 'Lỗi mạng',
        messageEn: 'Lỗi mạng',
        content: null,
        result: -1,
        data: null,
      }
    );
  },
);

axiosApp.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>,
  ) => {
    throw (
      err.response?.data || {
        message: '',
        content: null,
        result: -1,
        data: null,
      }
    );
  },
);

export async function doGetAxios(url: string): Promise<any> {
  try {
    const res: AxiosResponse = await axiosApp.get(url);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}

export async function doPostAxios(url: string, params: object): Promise<any> {
  try {
    const res: AxiosResponse = await axiosApp.post(url, JSON.stringify(params));
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}

export async function axiosMultipart(
  url: string,
  params: any,
): Promise<AxiosResponse> {
  try {
    const res: AxiosResponse = await aa.post(url, params);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error) {
    throw error;
  }
}
