import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  focusArticle,
  getNotificationDetails,
  getNotificationTable,
  getNotificationType,
  loadAsset,
  loadBanner,
  loadCategory,
  loadHomeArticle,
  loadTabArticle,
} from './action';

interface IinitialState {
  asset: {
    productList?: Array<any>;
    sumOfInterestOrHole?: number | string;
    sumOfValueCurrently?: number;
    sumOfValueInvested?: number;
  };
  banner: Array<any>;
  article: any;
  articleFocus: any;
  category: Array<any>;
  categoryFocus: any;
  loadingArticleFocus: boolean;
  loading: boolean;
  pagination: any;
  notificationType: Array<any>;
  notificationTable: any;
  notificationFocus: any;
  notificationCache: any;
  paginationNotification: any;
  loadingNotification: {
    refresh: boolean;
    loadmore: boolean;
  };
}
const initialState = {
  loading: false,
  asset: {},
  banner: [],
  article: {},
  articleFocus: null,
  loadingArticleFocus: false,
  category: [],
  categoryFocus: null,
  pagination: {},
  notificationType: [],
  notificationTable: {},
  notificationFocus: null,
  notificationCache: null,
  paginationNotification: {},
  loadingNotification: {
    refresh: false,
    loadmore: false,
  },
} as IinitialState;

const asset = createSlice({
  name: 'asset',
  initialState: initialState,
  reducers: {
    clearAsset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.asset = {};
    },
    changeFocusCategory(state, action: PayloadAction<any>) {
      state.categoryFocus = action.payload;
    },

    changeFocusArticle(state, action: PayloadAction<any>) {
      state.articleFocus = action.payload;
    },
    changeFocusNotification(state, action: PayloadAction<any>) {
      state.notificationFocus = action.payload;
    },
    cacheNotification(state, action: PayloadAction<any>) {
      state.notificationCache = action.payload;
    },
  },
  extraReducers: builder => {
    //load morehistore
    builder.addCase(loadAsset.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadAsset.fulfilled, (state, action) => {
      state.loading = false;
      state.asset = action.payload;
    });
    builder.addCase(loadAsset.rejected, (state, action) => {
      state.loading = false;
    });
    //load banner
    builder.addCase(loadBanner.pending, (state, action) => {});
    builder.addCase(loadBanner.fulfilled, (state, action) => {
      state.banner = action.payload;
    });
    builder.addCase(loadBanner.rejected, (state, action) => {});
    //load hgome article
    builder.addCase(loadHomeArticle.pending, (state, action) => {});
    builder.addCase(loadHomeArticle.fulfilled, (state, action) => {
      state.article = {
        ...state.article,
        [`home`]: action.payload,
      };
    });
    builder.addCase(loadHomeArticle.rejected, (state, action) => {});
    //load article tab
    builder.addCase(loadTabArticle.pending, (state, action) => {});
    builder.addCase(loadTabArticle.fulfilled, (state, action) => {
      state.article = {
        ...state.article,
        [`${action.payload.category.code}`]: action.payload.items,
      };
      state.pagination = {
        ...state.pagination,
        [`${action.payload.category.code}`]: action.payload.pagination,
      };
    });
    builder.addCase(loadTabArticle.rejected, (state, action) => {});
    //load hgome category
    builder.addCase(loadCategory.pending, (state, action) => {});
    builder.addCase(loadCategory.fulfilled, (state, action) => {
      state.category = action.payload;
    });
    builder.addCase(loadCategory.rejected, (state, action) => {});
    //load focus article
    builder.addCase(focusArticle.pending, (state, action) => {
      state.loadingArticleFocus = true;
    });
    builder.addCase(focusArticle.fulfilled, (state, action) => {
      state.articleFocus = action.payload.details;
      state.article = {
        ...state.article,
        [`details`]: action.payload.lstArticle,
      };
      state.loadingArticleFocus = false;
    });
    builder.addCase(focusArticle.rejected, (state, action) => {
      state.loadingArticleFocus = false;
    });
    //load notification type
    builder.addCase(getNotificationType.pending, (state, action) => {
      // state.loadingArticleFocus = true;
    });
    builder.addCase(getNotificationType.fulfilled, (state, action) => {
      state.notificationType = action.payload;
    });
    builder.addCase(getNotificationType.rejected, (state, action) => {
      // state.loadingArticleFocus = false;
    });
    //load notification table
    builder.addCase(getNotificationTable.pending, (state, action) => {
      // state.loadingArticleFocus = true;
    });
    builder.addCase(getNotificationTable.fulfilled, (state, action) => {
      state.notificationTable = {
        ...state.notificationTable,
        [action.payload.notificationTypeId]:
          action.payload.pagination.currentPage == 1
            ? action.payload.items
            : [
                ...state.notificationTable[action.payload.notificationTypeId],
                ...action.payload.items,
              ],
      };
      state.paginationNotification = {
        ...state.paginationNotification,
        [action.payload.notificationTypeId]: action.payload.pagination,
      };
      // state.loadingNotification = {
      //   ...state.loadingNotification,
      //   refresh: false,
      // };
    });
    builder.addCase(getNotificationTable.rejected, (state, action) => {
      // state.loadingArticleFocus = false;
    });
    //load notification detaisl
    builder.addCase(getNotificationDetails.pending, (state, action) => {
      // state.loadingArticleFocus = true;
    });
    builder.addCase(getNotificationDetails.fulfilled, (state, action) => {
      state.notificationFocus = action.payload;
    });
    builder.addCase(getNotificationDetails.rejected, (state, action) => {
      // state.loadingArticleFocus = false;
    });
  },
});
const {actions, reducer} = asset;
export const {
  clearAsset,
  changeFocusCategory,
  changeFocusArticle,
  changeFocusNotification,
  cacheNotification,
} = actions;
export default reducer;
