import {Button, Div, HTMLView, ImageView, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {FlatList} from 'react-native-gesture-handler';
import {useDispatch} from 'react-redux';
import {focusArticle} from 'reducer/asset';
import {navigate, useImageUrl} from 'services';
import {useAppSelector} from 'store/hooks';

const Item = (p: {data: any; index: number; nonScaleFirst?: boolean}) => {
  const {data, index, nonScaleFirst} = p;
  const [imageSource] = useImageUrl(
    `download/file-for-link`,
    'POST',
    `${data.image}`,
  );

  const dispatch = useDispatch();
  const gotoDetails = () => {
    navigate('InvestmentLibraryDetailScreen');
    dispatch(
      focusArticle({
        id: p.data.id,
      }),
    );
  };

  if (!index && !nonScaleFirst) {
    return (
      <Button
        isScale={false}
        onPress={() => {
          gotoDetails();
        }}
        marginTop={10}
        width={343}>
        <ImageView
          source={{
            uri: imageSource.url,
          }}
          width={343}
          height={177}
          borderRadius={8}
          resizeMode="cover"
        />
        <Label
          size={14}
          marginTop={11}
          marginBottom={5}
          multilanguage={false}
          color={Ecolors.linkColor}>
          {data.categoryName}
        </Label>
        <HTMLView
          size={14}
          fontWeight="700"
          paddingHorizontal={0}
          source={{
            html: data.title,
          }}
        />
      </Button>
    );
  }
  return (
    <Button
      width={343}
      isScale={false}
      onPress={() => {
        gotoDetails();
      }}
      flexDirection="row"
      paddingVertical={11}
      alignItems="flex-start"
      justifyContent="space-between">
      <Div flex={1}>
        <Label
          size={14}
          marginBottom={5}
          multilanguage={false}
          color={Ecolors.linkColor}>
          {data.categoryName}
        </Label>
        <HTMLView
          size={14}
          fontWeight="700"
          paddingHorizontal={0}
          source={{
            html: data.title,
          }}
        />
      </Div>
      <ImageView
        width={103}
        height={73}
        source={{
          uri: imageSource.url,
        }}
        marginLeft={21}
        borderRadius={8}
        resizeMode="cover"
      />
    </Button>
  );
};

const ListArticle = (p: {
  filter: string;
  isHeader: boolean;
  scrollEnabled?: boolean;
  nonScaleFirst?: boolean;
  isHideMore?: boolean;
  footerHeight?: number;
  contentHeader?: string;
}) => {
  const {filter, scrollEnabled, isHeader, nonScaleFirst, footerHeight} = p;
  const list = useAppSelector(
    state => state.asset.article?.[`${filter || 'home'}`] || [],
  );
  const keyExtractor = (item: any, index: number) => `key${index}`;
  const renderItem = (p: {item: any; index: number}) => {
    return <Item data={p.item} index={p.index} nonScaleFirst={nonScaleFirst} />;
  };

  const ListHeaderComponent = () => {
    if (!isHeader) return <Div height={10} />;
    return (
      <Div
        paddingVertical={11}
        width={343}
        marginTop={10}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label size={16} fontWeight="bold">
          {p.contentHeader || `investmentlibraryscreen.thuviendautu`}
        </Label>
        {!p.isHideMore && (
          <Button
            onPress={() => {
              navigate('InvestmentLibraryScreen');
            }}>
            <Label
              size={14}
              color={
                Ecolors.placeHoderColor
              }>{`investmentlibraryscreen.xemthem`}</Label>
          </Button>
        )}
      </Div>
    );
  };
  const ListFooterComponent = () => {
    return <Div height={footerHeight || 10} />;
  };
  const onLoadMore = async () => {};

  const ItemSeparatorComponent = () => {
    return (
      <Div
        height={1}
        marginVertical={5}
        width={'100%'}
        backgroundColor={Ecolors.bordercolor}
      />
    );
  };
  if (!list.length) {
    return <></>;
  }
  return (
    <Div width={'100%'} alignItems="center">
      <FlatList
        data={list || []}
        scrollEnabled={scrollEnabled ?? true}
        extraData={list}
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListFooterComponent={ListFooterComponent}
        onEndReachedThreshold={0.4}
        onEndReached={onLoadMore}
      />
    </Div>
  );
};

export default React.memo(ListArticle);
