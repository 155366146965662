import {createRef} from 'react';
export const alertRef = createRef<propsAlert>();
interface p {
  title?: string;
  content?: string;
  titleCancel?: string;
  titleClose?: string;
  titleConfirm?: string;
  is401?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onPress?: () => void;
  multilanguage?: boolean;
  error?: boolean;
  type?: number;
  component?: () => any;
}

interface propsAlert {
  show: (p?: p) => void;
  hide: (p?: any) => void;
}
export default class Alert {
  static show(p: p) {
    alertRef.current?.show({
      multilanguage: true,
      error: false,
      titleCancel: 'alert.huybo',
      titleConfirm: 'alert.dongy',
      titleClose: 'alert.dong',
      title: 'alert.thongbao',
      type: 1,
      onConfirm: () => {
        // goBack();
      },
      onCancel: () => {
        // goBack();
      },
      onPress: () => {},
      ...p,
    });
  }
  static showError(p: p) {
    alertRef.current?.show({
      multilanguage: true,
      error: false,
      titleCancel: 'alert.huybo',
      titleConfirm: 'alert.dongy',
      titleClose: 'alert.dong',
      title: 'alert.thongbao',
      type: 1,
      is401: false,
      onConfirm: () => {
        // goBack();
      },
      onCancel: () => {
        // goBack();
      },
      onPress: () => {},
      ...p,
    });
  }
  static hide(p?: any) {
    alertRef.current?.hide(p);
  }
  static showComponent(p: p) {
    alertRef.current?.show({
      multilanguage: true,
      error: false,
      titleCancel: 'alert.huybo',
      titleConfirm: 'alert.dongy',
      titleClose: 'alert.dong',
      title: 'alert.thongbao',
      type: 1,
      onConfirm: () => {
        // goBack();
      },
      onCancel: () => {
        // goBack();
      },
      onPress: () => {},
      ...p,
    });
  }
}
