import { Alert, ButtonBorder, Div, HeaderBack, HTMLView } from 'components';
import { Ecolors } from 'constant';
import React, { useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { apiAuth, navigate } from 'services';
import { parseMultilanguage } from 'utils';

const T = (props: {title: string}) => {
  return (
    <Div
      flexDirection={'row'}
      alignItems={'center'}
      paddingLeft={22}
      paddingRight={15}
      justifyContent={'flex-start'}>
      <Div
        widthHeight={7}
        backgroundColor={Ecolors.textColor}
        borderRadius={10}
      />
      <HTMLView source={{html: props.title}} />
    </Div>
  );
};

function DeleteAccountModal() {
  const insests = useSafeAreaInsets();
  const [loading, setLoading] = useState<boolean>(false);

  const onDelete = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await apiAuth.deleteAccount();
      if (res.status == 200) {
        navigate('OtpRequestModal', {
          data: {
            requestOnSendOtp: res.data,
            flowApp: 'DeleteAccount',
          },
        });
      } else {
        Alert.showError({
          multilanguage: false,
          content: res.message,
        });
      }
    } catch (error: any) {
      Alert.showError({
        multilanguage: false,
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div
      height={'100%'}
      backgroundColor={Ecolors.whiteColor}
      flexDirection={'column'}
      alignItems={'center'}>
      <HeaderBack type={2} title={`settingscreen.yeucauxoataikhoan`} />
      <Div flex={1}>
        <T title={parseMultilanguage(`settingscreen.content1`)} />
        <T title={parseMultilanguage(`settingscreen.content2`)} />
        <T title={parseMultilanguage(`settingscreen.content3`)} />
      </Div>
      <ButtonBorder
        loading={loading}
        title={`settingscreen.yeucauxoa`}
        onPress={() => {
          onDelete();
        }}
      />
      <Div height={insests.bottom + 30} />
    </Div>
  );
}

export default React.memo(DeleteAccountModal);
