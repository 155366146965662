import {Button, Div, HTMLView, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {changeFocusNotification} from 'reducer/asset';
import {goBack} from 'services';
import {useAppSelector} from 'store/hooks';

function NotificationDetailsModal() {
  const insets = useSafeAreaInsets();
  const notificationFocus = useAppSelector(
    state => state.asset.notificationFocus,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(changeFocusNotification({}));
    };
  }, []);

  return (
    <Div height={'100%'}>
      <Button
        isScale={false}
        width={'100%'}
        height={insets.top + 20}
        onPress={() => {
          goBack();
        }}
      />
      <Div
        height={'100%'}
        width={'100%'}
        backgroundColor={Ecolors.whiteColor}
        borderTopLeftRadius={10}
        padding={8}
        paddingLeft={18}
        borderTopRightRadius={10}>
        <Div
          flexDirection="row"
          alignItems="center"
          borderBottomWidth={1}
          paddingBottom={12}
          borderBottomColor={Ecolors.bordercolor}
          justifyContent="space-between">
          <Div paddingLeft={5} flex={1}>
            <Label fontWeight="700" multilanguage={false}>
              {`${notificationFocus?.notificationHistorySubject}`}
            </Label>
          </Div>
          <Button
            padding={10}
            onPress={() => {
              goBack();
            }}>
            <ImageView
              tintColor={Ecolors.textColor}
              widthHeight={20}
              source={Icons.close}
              resizeMode="contain"
            />
          </Button>
        </Div>
        {/* content */}
        <ScrollView showsVerticalScrollIndicator={false}>
          <Div paddingTop={12} paddingBottom={insets.bottom + 80}>
            <HTMLView
              paddingHorizontal={0}
              size={15}
              source={{
                html:
                  notificationFocus?.notificationHistoryMessageDetails || '',
              }}
            />
            {/* <Label multilanguage={false}>
            {notificationFocus?.notificationHistoryMessage}{' '}
          </Label> */}
          </Div>
        </ScrollView>
        {/* content */}
      </Div>
    </Div>
  );
}

export default React.memo(NotificationDetailsModal);
