import { createStackNavigator } from '@react-navigation/stack';
import { toastRef } from 'components/Toast';
import ToastRoot from 'components/Toast/ToastRoot';
import React, { useEffect } from 'react';
import { enableFreeze, enableScreens } from 'react-native-screens';
import { useDispatch } from 'react-redux';
import { changeStatusScreen, getInfo } from 'reducer/authen';
import { changeLanguage } from 'reducer/languages/reducer';
import {
  componentAction,
  mainStackModal,
  mainStackScreen,
  stackSplash,
  stackUnAuthorizedModal,
  stackUnAuthorizedScreen,
} from 'screens';
import { navigate, navigationRef } from 'services/navigation';
import { useAppSelector } from 'store/hooks';
import LinkingConfiguration from './LinkingConfiguration';
import {
  screenOptions,
  translateXOptionsScreen,
  translateYModal,
} from './optionsNavigate';
import TabContainer from './TabContainer';
// import {handleNotification} from 'services';
import { NavigationContainer } from '@react-navigation/native';
import { alertRef } from 'components/Alert';
import AlertCp from 'components/BottomSheetDialog/AlertCp';
import { Ecolors } from 'constant';
import { Linking, StatusBar } from 'react-native';
import {
  getNotificationType,
  loadCategory,
  loadHomeArticle,
} from 'reducer/asset';
import { loadProduct } from 'reducer/investment';
import { detectLinkData } from 'utils';
import { setStoreData } from 'utils/storage';

enableScreens(false);
enableFreeze(true);

const Stack = createStackNavigator();

function Navi() {
  const { statusScreen } = useAppSelector(state => state.authen);
  const activeLanguage = useAppSelector(
    state => state.languages.activeLanguage,
  );
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const currentUser = useAppSelector<any>(state => state.authen.currentUser);
  const dispatch = useDispatch();
  React.useEffect(() => {
    bindDataUrl();
    return () => { };
  }, []);

  const bindDataUrl = async () => {
    dispatch(changeLanguage(activeLanguage));
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        const { idNo, refCode, agentCode, secretKey, phone } = obj;
        if (idNo && phone && refCode && agentCode && secretKey) {
          dispatch(getInfo({}));
          dispatch(changeStatusScreen('main'));
        } else {
          setTimeout(() => {
            navigationRef.current?.reset({
              routes: [{ name: 'LoginScreen' }],
            });
          }, 0);
        }
        setStoreData('idNo', obj?.idNo || '');
        setStoreData('phone', obj?.phone || '');
        setStoreData('refCode', obj?.refCode || '');
        setStoreData('agentCode', obj?.agentCode || '');
        setStoreData('secretKey', obj?.secretKey || '');
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    if (statusScreen == 'main') {
      getInfoAndEKYC();
      dispatch(loadHomeArticle({}));
      dispatch(getNotificationType({}));
      dispatch(loadCategory({}));
      dispatch(loadProduct({}));
    }
    return () => { };
  }, [statusScreen]);

  const getInfoAndEKYC = async () => {
    const r: any = await dispatch(getInfo({}));
    if (!!r) {
      gotoEKYC(r.payload);
    }
  };

  const gotoEKYC = (currentUser: any) => {
    // navigate('ControlEKYCScreen', {
    //   onBack: () => {
    //     navigate('OverviewScreen');
    //   },
    // });
    if (currentUser?.accessOnline) {
      navigate('OnlineTradingModal');
      return;
    }
    if (!currentUser?.investmentProfile?.status) {
      const { userInfoIsFull, bankAccountIsFull, userAddressIsFull } =
        currentUser;
      if (!userInfoIsFull && !bankAccountIsFull && !userAddressIsFull) {
        navigate('ControlEKYCScreen', {
          onBack: () => {
            navigate('OverviewScreen');
          },
        });
      } else {
        navigate('AccountVerifyScreen');
      }
    }
  };

  const switchStatusStack = () => {
    switch (statusScreen) {
      case 'unAuthorized': //unauthorized
        return (
          <>
            {stackUnAuthorizedScreen.map((item, _) => (
              <Stack.Screen
                options={translateXOptionsScreen}
                key={item.name}
                name={item.name}
                component={item.component}
              />
            ))}
            {stackUnAuthorizedModal.map((item, _) => (
              <Stack.Screen
                options={translateYModal}
                key={item.name}
                name={item.name}
                component={item.component}
              />
            ))}
          </>
        );

      case 'main': //main
        return (
          <>
            <Stack.Screen
              options={translateXOptionsScreen}
              key={'TabContainer'}
              name={'TabContainer'}
              component={TabContainer}
            />
            {mainStackScreen.map((item, _) => (
              <Stack.Screen
                options={translateXOptionsScreen}
                key={item.name}
                name={item.name}
                component={item.component}
              />
            ))}
            {mainStackModal.map((item, _) => (
              <Stack.Screen
                options={translateYModal}
                key={item.name}
                name={item.name}
                component={item.component}
              />
            ))}
          </>
        );
      case 'splash':
      default:
        // splash
        return stackSplash.map((item, _) => (
          <Stack.Screen
            options={translateXOptionsScreen}
            key={item.name}
            name={item.name}
            component={item.component}
          />
        ));
    }
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      independent={true}>
      <StatusBar
        backgroundColor={Ecolors.transparent}
        barStyle={statusScreen == 'main' ? 'light-content' : 'light-content'}
        hidden={false}
      />
      <ToastRoot ref={toastRef} />

      <AlertCp ref={alertRef} />
      <Stack.Navigator
        screenOptions={screenOptions}
        headerShown={false}
        initialRouteName={'LoginScreen'}>
        {switchStatusStack()}
        {componentAction.map((item: any, index: number) => {
          return (
            <Stack.Screen
              options={item.options || translateYModal}
              key={item.name}
              name={item.name}
              component={item.component}
            />
          );
        })}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default React.memo(Navi);
