import {Button, Div, Input, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {StyleSheet} from 'react-native';

const Item = (p: {value: string; isFocus: boolean; isShow: boolean; isPre}) => {
  const borderBottomColor = !p.value
    ? p.isFocus
      ? Ecolors.mainColor
      : Ecolors.spaceColor
    : Ecolors.mainColor;
  return (
    <Div
      width={46}
      height={46}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      marginHorizontal={4}
      borderBottomWidth={4}
      borderBottomColor={borderBottomColor}>
      {!p.isFocus || !p.isShow ? (
        <Div
          widthHeight={11}
          backgroundColor={p.value ? Ecolors.mainColor : Ecolors.spaceColor}
          borderRadius={20}
        />
      ) : (
        <Label size={30} multilanguage={false}>
          {p.value}
        </Label>
      )}
    </Div>
  );
};

function InputPin(
  p: {title: string; onSubmit?: (otp: string) => void},
  ref: any,
) {
  const inputRef = useRef<any>(null);
  const [otp, setOtp] = useState<string>('');
  const [isShow, setIsShow] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      focusInput();
    },
    unFocusInput: () => {
      unFocusInput();
    },
    clearOTP: () => {
      clearOTP();
    },
  }));

  useEffect(() => {
    if (otp.length == 6) {
      unFocusInput();
      p.onSubmit && p.onSubmit(otp);
    }
    return () => {};
  }, [otp]);

  const focusInput = () => {
    setOtp('');
    if (inputRef.current) {
      setIsShow(true);
      inputRef.current.focus();
    }
  };

  const clearOTP = () => {
    setOtp('');
  };

  const unFocusInput = () => {
    setIsShow(false);
  };

  return (
    <Div margin={33} alignItems="center" justifyContent="center">
      <Label marginBottom={20} fontWeight="bold">
        {p.title}
      </Label>
      <Input
        caretHidden={true}
        textContentType={'oneTimeCode'}
        inputRef={inputRef}
        value={otp}
        padding={0}
        margin={0}
        onChangeText={(e: string) => {
          if (e.length == 0) {
            setOtp(e);
            return;
          }
          const t: string = e[e.length ? e.length - 1 : 0] || '';
          const reg = /^[0-9]*$/;
          if (reg.test(t)) {
            setOtp(e);
          }
        }}
        color={Ecolors.transparent}
        maxLength={6}
        keyboardType={'number-pad'}
        width={'100%'}
        textAlign={'center'}
        fontSize={20}
        style={{
          ...StyleSheet.absoluteFillObject,
        }}
      />
      <Button
        onPress={() => focusInput()}
        width={'100%'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}>
        {Array(6)
          .fill(0)
          .map((item: any, index: number) => {
            return (
              <Item
                key={index}
                value={otp?.[index] || ''}
                isFocus={
                  index == otp?.length || (index == 5 && otp?.length == 6)
                }
                isShow={isShow}
                isPre={otp?.length - 1 == index}
              />
            );
          })}
      </Button>
    </Div>
  );
}

export default React.forwardRef(InputPin);
