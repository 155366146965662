import {useRoute} from '@react-navigation/native';
import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, EStyle, Icons} from 'constant';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {changeStatusScreen} from 'reducer/authen';
import {goBack, navigationRef} from 'services';
import {useAppSelector} from 'store/hooks';

function AlertError() {
  const route = useRoute<any>();
  const {statusScreen} = useAppSelector(state => state.authen);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (route?.params?.onPress) {
        route?.params?.onPress();
      }
    };
  }, []);

  return (
    <Div screen={true} alignItems={'center'} justifyContent={'center'}>
      <Div
        width={337}
        borderRadius={5}
        style={EStyle.shadow}
        backgroundColor={Ecolors.whiteColor}
        minHeight={193}>
        <Div
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}>
          <ImageView
            marginTop={31}
            source={Icons.erroricon}
            widthHeight={57}
            resizeMode={'contain'}
          />
          <Label
            margin={20}
            multilanguage={route?.params?.multilanguage ?? true}
            textAlign={'center'}>
            {route?.params?.content ||
              'Đã hết phiên giao dịch. Vui lòng đăng nhập lại !'}
          </Label>
        </Div>
        <Button
          width={'100%'}
          height={46}
          borderTopWidth={1}
          borderTopColor={Ecolors.spaceColor}
          alignItems={'center'}
          justifyContent={'center'}
          onPress={() => {
            goBack();
            if (!route?.params?.content?.length) {
              if (statusScreen == 'main') {
                dispatch(changeStatusScreen('unAuthorized'));
                return;
              }
              navigationRef.current?.reset({
                routes: [{name: 'LoginScreen'}],
              });
              return;
            }
          }}>
          <Label
            size={16}
            color={Ecolors.linkColor}
            fontWeight={'500'}>{`alert.thulai`}</Label>
        </Button>
      </Div>
    </Div>
  );
}

export default React.memo(AlertError);
