import {doGetAxios, doPostAxios} from './axios';

export const apiAsset = {
  loadDashboard: async (params: {}) => {
    const url = 'home/dashboard';
    return doGetAxios(url);
  },
  loadAssetManagement: async (params: {id: string | number}) => {
    const url = `asset-management/statistic/${params.id}`;
    return doPostAxios(url, {
      productId: params.id,
    });
  },
  loadTableAsset: async (params: {
    pagination: {
      currentPage: number;
      itemsPerPage: number;
    };
    queries: {
      productId: number;
    };
  }) => {
    const url = 'asset-management/table';
    return doPostAxios(url, params);
  },
  getBanner: (params?: {}) => {
    const url = `banner/load`;
    return doGetAxios(url);
  },
  getCategory: () => {
    const url = `category/load`;
    return doGetAxios(url);
  },
  getHomeArticle: () => {
    const url = `home/article`;
    return doGetAxios(url);
  },
  getArticletab: (params: {
    pagination: {
      currentPage: number;
      itemsPerPage?: number;
      sort?: any;
      totalItemCount?: number;
    };
    queries: {
      categoriId: number;
    };
  }) => {
    const url = `article/table`;
    return doPostAxios(url, {
      pagination: {
        sort: null,
        itemsPerPage: 10,
        totalItemCount: 0,
        ...params.pagination,
      },
      queries: {
        categoryId: params.queries.categoriId,
      },
    });
  },
  getArticleDetails: (params: {id: number}) => {
    const url = `article/detail/${params.id}`;
    return doGetAxios(url);
  },
  getNotificationType: (params?: any) => {
    const url = `notification-type/load`;
    return doGetAxios(url);
  },
  getNotificationTable: (params: {
    pagination: {
      currentPage: number;
      itemsPerPage?: number;
      sort?: any;
      totalItemCount?: number;
    };
    queries: {
      notificationTypeId: number;
    };
  }) => {
    const url = `notification/send/table`;
    return doPostAxios(url, {
      pagination: {
        sort: null,
        itemsPerPage: 10,
        totalItemCount: 0,
        ...params.pagination,
      },
      queries: {
        notificationTypeId: params.queries.notificationTypeId,
      },
    });
  },
  getNotificationLoadByID: (params: {id: number}) => {
    const url = `notification/send/load-by-id/${params.id}`;
    return doGetAxios(url);
  },
};
