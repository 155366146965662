import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React, {useImperativeHandle, useRef} from 'react';
import {Animated, StatusBar} from 'react-native';
import {goBack} from 'services';
import {useAppSelector} from 'store/hooks';

const AniHeader = (p: {}, ref) => {
  const articleFocus = useAppSelector(state => state.asset.articleFocus);
  const Ani = useRef(new Animated.Value(0)).current;
  useImperativeHandle(ref, () => ({
    fade: t => {
      const value = t / 100;
      Ani.setValue(value >= 1 ? 1 : value <= 0 ? 0 : value);
    },
  }));

  return (
    <Div
      paddingBottom={10}
      backgroundColor={Ecolors.whiteColor}
      width={'100%'}
      alignItems="center"
      justifyContent="center">
      <StatusBar barStyle={'dark-content'} />
      <Div
        paddingHorizontal={16}
        width={'100%'}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start">
        <Animated.View
          style={{
            opacity: Ani.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 0],
            }),
          }}>
          <Button
            onPress={() => goBack()}
            height={40}
            flexDirection={'row'}
            alignItems={'center'}>
            <ImageView
              source={Icons.back}
              width={8}
              height={14}
              resizeMode={'contain'}
              marginRight={16}
              tintColor={Ecolors.textColor}
            />
          </Button>
        </Animated.View>
      </Div>
      <Div
        borderTopColor={Ecolors.bordercolor}
        width={'100%'}
        borderTopWidth={1}
      />
      <Div width={343} alignItems="flex-start">
        <Div
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start">
          <Label
            marginTop={7}
            size={14}
            color={Ecolors.linkColor}
            multilanguage={false}>
            {articleFocus?.categoryName || ''}
          </Label>
        </Div>
        <Label marginTop={7} multilanguage={false} fontWeight="700">
          {articleFocus?.title || ''}
        </Label>
      </Div>
    </Div>
  );
};

export default React.forwardRef(AniHeader);
