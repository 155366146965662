import {Alert} from 'components';
import {security} from 'constant';
import {BackHandler} from 'react-native';

const checkEmulator = async () => {
  return;
  if (!security.checkEmulator) {
    return;
  }
  DeviceInfo.isEmulator().then(isEmulator => {
    if (!!isEmulator) {
      Alert.show({
        content: `alert.thietbikhonghople`,
        type: 2,
        onClose: () => {
          BackHandler.exitApp();
        },
      });
    }
  });
  return;
};

const checkRoot = async () => {
  if (!security.checkRoot) {
    return;
  }
  // action checkroot in here
  return;
};

export {checkEmulator, checkRoot};
