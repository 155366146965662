import { Alert } from 'components';
import { Platform } from 'react-native';
import { apiAuth, navigate } from 'services';
import { getUuid } from 'utils';
export const doUploadFileSignature = async (p: {
  link: string;
  setLoading: (t: boolean) => void;
  I18nState: 'en' | 'vi';
  isbase64?: boolean
}) => {
  try {
    const formdata = new FormData();
    const id = getUuid();
    p.setLoading(true);
    if (!p.isbase64) {
      const file: any = {
        uri: Platform.OS !== 'android' ? p.link.replace('file://', '') : p.link,
        name: `${id}.png`,
        filename: `${id}.png`,
        type: 'image/png',
      };
      formdata.append('file', file)
    } else {
      const blob = await b64toBlob(p.link)
      const file = new File([blob], `${id}.png`);
      formdata.append('file', file);
    }
    const res: any = await apiAuth.createEsignature(formdata);
    if (res.status == 200 && !!res.data.otpInfo) {
      navigate('OtpRequestModal', {
        data: {
          requestOnSendOtp: res.data.otpInfo,
          flowApp: 'CreateEsignature',
        },
      });
      return;
    }
    Alert.showError({
      content: p.I18nState == 'vi' ? res.message : res.messageEn,
      multilanguage: false,
    });
    return;
  } catch (error: any) {
    Alert.showError({
      content: p.I18nState == 'vi' ? error.message : error.messageEn,
      multilanguage: false,
    });
  } finally {
    p.setLoading(false);
  }
};

export const doUploadFileEsignatureRisk = async (p: {
  link: string;
  setLoading: (t: boolean) => void;
  I18nState: 'en' | 'vi';
  isbase64?: boolean

}) => {
  try {
    const formdata = new FormData();
    const id = getUuid();
    p.setLoading(true);
    if (!p.isbase64) {
      const file: any = {
        uri: Platform.OS !== 'android' ? p.link.replace('file://', '') : p.link,
        name: `${id}.png`,
        filename: `${id}.png`,
        type: 'image/png',
      };
      formdata.append('file', file)
    } else {
      const blob = await b64toBlob(p.link)
      const file = new File([blob], `${id}.png`);
      formdata.append('file', file);
    }
    const res: any = await apiAuth.createEsignatureRisk(formdata);
    if (res.status == 200 && !!res.data.otpInfo) {
      navigate('OtpRequestModal', {
        data: {
          requestOnSendOtp: res.data.otpInfo,
          flowApp: 'CreateEsignatureRisk',
        },
      });
      return;
    }
    Alert.showError({
      content: p.I18nState == 'vi' ? res.message : res.messageEn,
      multilanguage: false,
    });
    return;
  } catch (error: any) {
    Alert.showError({
      content: p.I18nState == 'vi' ? error.message : error.messageEn,
      multilanguage: false,
    });
  } finally {
    p.setLoading(false);
  }
};

export const doUploadFileEsignatureIPO = async (p: {
  link: string;
  setLoading: (t: boolean) => void;
  I18nState: 'en' | 'vi';
  isbase64?: boolean
}) => {
  try {
    const formdata = new FormData();
    const id = getUuid();
    p.setLoading(true);
    if (!p.isbase64) {
      const file: any = {
        uri: Platform.OS !== 'android' ? p.link.replace('file://', '') : p.link,
        name: `${id}.png`,
        filename: `${id}.png`,
        type: 'image/png',
      };
      formdata.append('file', file)
    } else {
      const blob = await b64toBlob(p.link)
      const file = new File([blob], `${id}.png`);
      formdata.append('file', file);
    }
    const res: any = await apiAuth.createEsignatureIPO(formdata);
    if (res.status == 200 && !!res.data.otpInfo) {
      navigate('OtpRequestModal', {
        data: {
          requestOnSendOtp: res.data.otpInfo,
          flowApp: 'CreateEsignatureIPO',
        },
      });
      return;
    }
    Alert.showError({
      content: p.I18nState == 'vi' ? res.message : res.messageEn,
      multilanguage: false,
    });
    return;
  } catch (error: any) {
    Alert.showError({
      content: p.I18nState == 'vi' ? error.message : error.messageEn,
      multilanguage: false,
    });
  } finally {
    p.setLoading(false);
  }
};


export const b64toBlob = async (base64Data) => {
  const base64Response = await fetch(base64Data);
  const blob = await base64Response.blob();
  return blob
}