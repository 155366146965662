import {Alert, Button, Div, HeaderBack, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';
import {Linking} from 'react-native';
import {useDispatch} from 'react-redux';
import {changeBio, changeIsSmartOTP, changeNoti} from 'reducer/authen';
import {
  apiAuth,
  checkKeysBiometric,
  checkSignature,
  createKeysBiometric,
  deleteKeysBiometric,
  navigate,
  requestNotification,
  useAppState,
} from 'services';
import {useAppSelector} from 'store/hooks';

import {Log, gotoAppSetting, parseMultilanguage} from 'utils';

const optionalConfigObject = {
  // title: 'Authentication Required', // Android
  imageColor: Ecolors.mainColor, // Android
  imageErrorColor: Ecolors.redColor, // Android
  sensorDescription: '', // Android
  fallbackLabel: '', // iOS (if empty, then label is hidden)
  unifiedErrors: true, // use unified error messages (default false)
  passcodeFallback: false, // iOS - allows the device to fall back to using the passcode, if faceid/touch is not available. this does not mean that if touchid/faceid fails the first few times it will revert to passcode, rather that if the former are not enrolled, then it will use the passcode.
};

function RowItem(p: {
  title: string;
  icon?: any;
  titleColor?: any;
  isActive?: boolean;
  onPress?: () => void;
}) {
  return (
    <>
      <Button
        onPress={() => p.onPress && p.onPress()}
        width={'100%'}
        height={50}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingLeft={16}
        paddingRight={20}>
        <Label color={p.titleColor || Ecolors.textColor}>{p.title}</Label>
        {p.icon ? (
          <ImageView
            source={p.icon}
            width={8}
            height={14}
            resizeMode={'contain'}
          />
        ) : (
          <Div
            backgroundColor={
              p.isActive ? Ecolors.mainColor : Ecolors.spaceColor
            }
            borderRadius={50}
            paddingHorizontal={3}
            paddingVertical={2}>
            {/* <Switch
              activeText={''}
              inActiveText={''}
              onValueChange={r => {
                p.onPress && p.onPress();
              }}
              value={p.isActive}
              circleSize={24}
              circleActiveColor={Ecolors.whiteColor}
              circleInActiveColor={Ecolors.whiteColor}
              circleBorderWidth={0}
              backgroundInactive={Ecolors.spaceColor}
              backgroundActive={Ecolors.mainColor}
            /> */}
          </Div>
        )}
      </Button>
      <Div width={'100%'} height={1} backgroundColor={Ecolors.spaceColor} />
    </>
  );
}

function SettingScreen() {
  const dispatch = useDispatch();
  const isBio = useAppSelector(state => state.authen.isBio);
  const isNoti = useAppSelector(state => state.authen.isNoti);
  const isSmartOTP = useAppSelector(state => state.authen.isSmartOTP);
  const isSupport = useAppSelector(state => state.authen.isSupport);
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const appState = useAppState();

  React.useEffect(() => {
    checkNotificationStatus();
    checkKeysBiometricStatus();
    return () => {};
  }, [appState]);

  const checkKeysBiometricStatus = async () => {
    checkKeysBiometric()
      .then((res: any) => {
        dispatch(changeBio(res));
      })
      .catch(err => {
        dispatch(changeBio(false));
      });
  };

  const checkBio = async () => {
    try {
      await createKeysBiometric().then((res: any) => {
        checkSignature({
          title: parseMultilanguage(`component.kichhoatvantay`),
        }).then((res: any) => {
          Log('resss', res);
          if (!!res.success) {
            navigate('ActiveBiometricModal');
          }
        });
      });
      return;
    } catch (error: any) {}
  };

  const onDeleteBio = () => {
    dispatch(changeBio(false));
    deleteKeysBiometric();
  };

  const checkNotificationStatus = async () => {
    const res = await requestNotification();
    dispatch(changeNoti(res));
    if (!res) {
      dispatch(changeIsSmartOTP(false));
    }
  };

  const deleteSmartOTP = () => {
    try {
      checkSignature({
        title: parseMultilanguage(`component.huysmartotp`),
      }).then(async (res: any) => {
        if (res.success) {
          return;
          const deviceId = await DeviceInfo.getUniqueId();
          const res = await apiAuth.cancelSoftOTP({deviceId});
          if (res.status == 200) {
            Alert.show({
              type: 2,
              content: `alert.huythanhcongsmartotp`,
              onClose: () => {
                dispatch(changeIsSmartOTP(false));
              },
            });
          }
        }
      });
      return;
    } catch (error: any) {}
  };

  const handleSmartOTPButton = () => {
    if (!isNoti) {
      Alert.show({
        content: `alert.sudungchucnangsmartotp`,
        onConfirm: () => {
          Linking.openSettings();
        },
      });
      return;
    }
    if (isSmartOTP) {
      deleteSmartOTP();
      return;
    }
    navigate('RegisterSmartOTP');
  };

  const handleNotificationButton = () => {
    gotoAppSetting();
  };

  return (
    <Div height={'100%'} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={`settingscreen.caidatvabaomat`} />
      <RowItem
        onPress={() => {
          navigate('SettingLanguageModal');
        }}
        title={`settingscreen.ngonngu`}
        icon={Icons.forward}
      />
      <RowItem
        onPress={() => {
          handleNotificationButton();
        }}
        title={`settingscreen.nhanthongbao`}
        isActive={isNoti}
      />
      {isSupport && (
        <RowItem
          onPress={() => {
            if (isBio) {
              Alert.show({
                content: `settingscreen.xacnhanhuykichhoat`,
                onConfirm: () => {
                  onDeleteBio();
                },
              });
              return;
            } else {
              checkBio();
            }
          }}
          title={`settingscreen.faceid`}
          isActive={isBio}
        />
      )}
      <RowItem
        onPress={() => {
          handleSmartOTPButton();
        }}
        title={`softotpscreen.dangkysmartotp`}
        isActive={isSmartOTP && isNoti}
      />
      <RowItem
        titleColor={Ecolors.redColor}
        onPress={() => {
          navigate('DeleteAccountModal');
        }}
        title={`settingscreen.yeucauxoataikhoan`}
        icon={Icons.forward}
      />
    </Div>
  );
}

export default React.memo(SettingScreen);
