import {urlApp} from 'constant';
// import {sha256} from 'js-sha256';
import moment from 'moment';
import {getStorePinCode, setStorePinCode} from 'utils/storage';

const generateSoftOTP = (idOrder: string, callback: (t: number) => void) => {
  return ``;
  let date = new Date(moment(new Date()).utcOffset('+07:00'));
  let fulldate = Math.floor(date.getTime() / 60000);
  callback(60 - date.getSeconds());
  let string = `${idOrder}${fulldate}${urlApp.SECRETKEY}`;
  let sharender = sha256(string);
  let last6string = `0000${parseInt(
    sharender.slice(sharender.length - 6),
    16,
  )}`;
  return `${last6string.slice(last6string.length - 6)}`;
};

const hashPinCode = (pincode: string) => {
  return;
  const stringHashPinCode = sha256.hmac(urlApp.SECRETKEY, scriptMerge(pincode));
  setStorePinCode(stringHashPinCode);
};

const checkHashPinCode = async (pincode: string) => {
  return false;
  const stringHashPinCode = sha256.hmac(urlApp.SECRETKEY, scriptMerge(pincode));
  const pinCodeStorage = await getStorePinCode();
  return stringHashPinCode === pinCodeStorage;
};

const scriptMerge = (pincode: any) => {
  let string = [...urlApp.SECRETKEY];
  [...`${pincode}`].map((item: any, index: number) => {
    string[index * 2 + 1] = item;
  });
  return string.join('');
};

export {checkHashPinCode, generateSoftOTP, hashPinCode};
