import {Button, Div, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {FlatList} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {getNotificationDetails, getNotificationTable} from 'reducer/asset';
import {navigate} from 'services';
import {useAppSelector} from 'store/hooks';
import ItemNotification from './ItemNotification';
import {RefreshControl} from 'react-native';

const ListNotification = (p: {
  filter: string;
  isHeader: boolean;
  scrollEnabled?: boolean;
  footerHeight?: number;
}) => {
  const {filter, scrollEnabled, isHeader, footerHeight} = p;
  const insets = useSafeAreaInsets();
  const list = useAppSelector(
    state => state.asset.notificationTable?.[`${filter}`] || [],
  );
  const pagination = useAppSelector(
    state => state.asset.paginationNotification[`${p.filter}`],
  );
  const refresh = useAppSelector(
    state => state.asset.loadingNotification.refresh,
  );

  const dispatch = useDispatch();

  const keyExtractor = (item: any, index: number) => `key${index}`;

  const renderItem = (p: {item: any; index: number}) => {
    return (
      <ItemNotification
        onPressItem={() => {
          onPressItem(p.item);
        }}
        data={p.item}
        index={p.index}
      />
    );
  };

  const onPressItem = item => {
    navigate('NotificationDetailsModal');
    dispatch(
      getNotificationDetails({
        id: item.id,
      }),
    );
  };

  const ListHeaderComponent = () => {
    if (!isHeader) return <Div height={10} />;
    return (
      <Div
        paddingVertical={11}
        width={343}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label fontWeight="600">{`investmentlibraryscreen.thuviendautu`}</Label>
        <Button
          onPress={() => {
            navigate('InvestmentLibraryScreen');
          }}>
          <Label
            size={14}
            color={
              Ecolors.grayColor
            }>{`investmentlibraryscreen.xemthem`}</Label>
        </Button>
      </Div>
    );
  };
  const ListFooterComponent = () => {
    return <Div height={footerHeight || insets.bottom + 30} />;
  };
  const onLoadMore = async () => {
    dispatch(
      getNotificationTable({
        pagination: {
          ...pagination,
          currentPage: pagination.currentPage + 1,
        },
        queries: {
          notificationTypeId: filter || 0,
        },
        // notificationType: category[e],
      }),
    );
  };

  const onRefresh = async () => {
    dispatch(
      getNotificationTable({
        pagination: {
          currentPage: 1,
        },
        queries: {
          notificationTypeId: filter || 0,
        },
        // notificationType: category[e],
      }),
    );
  };
  const ItemSeparatorComponent = () => {
    return (
      <Div
        height={15}
        width={'100%'}
        // backgroundColor={Ecolors.bordercolor}
      />
    );
  };
  return (
    <Div width={'100%'} alignItems="center">
      <FlatList
        data={list}
        refreshControl={
          <RefreshControl
            refreshing={refresh}
            onRefresh={onRefresh}
            tintColor={Ecolors.mainColor}
          />
        }
        scrollEnabled={scrollEnabled ?? true}
        extraData={list}
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListFooterComponent={ListFooterComponent}
        onEndReachedThreshold={0.4}
        onEndReached={onLoadMore}
      />
    </Div>
  );
};

export default React.memo(ListNotification);
