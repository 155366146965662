import {Ecolors, Efonts} from 'constant';
import React from 'react';
import {fontScale, heightScale, widthScale, widthScreen} from 'utils';
function HTMLView(Props: {
  source: {
    html: string;
  };
  paddingHorizontal?: number;
  size?: number;
  textAlign?: 'center' | 'left' | 'right' | 'auto' | 'justify' | undefined;
  fontWeight?:
    | 'bold'
    | '400'
    | 'normal'
    | '100'
    | '200'
    | '300'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | undefined;
}) {
  return <></>;
  return (
    <RenderHtml
      contentWidth={widthScreen}
      source={Props.source}
      tagsStyles={{
        body: {
          color: Ecolors.textColor,
          fontSize: fontScale(Props.size ?? 16),
          fontFamily: Efonts.medium,
          paddingHorizontal: widthScale(Props.paddingHorizontal ?? 15),
          fontWeight: Props.fontWeight || '400',
          textAlign: Props.textAlign,
        },
        h3: {
          lineHeight: heightScale(18),
          fontSize: fontScale(Props.size ?? 16),
          fontFamily: Efonts.medium,
          fontWeight: Props.fontWeight || '400',
        },
        p: {
          lineHeight: heightScale(18),
          fontSize: fontScale(Props.size ?? 16),
          fontFamily: Efonts.medium,
          fontWeight: Props.fontWeight || '400',
        },
        strong: {
          lineHeight: heightScale(18),
          fontSize: fontScale(Props.size ?? 16),
          fontWeight: Props.fontWeight || '400',
        },
      }}
    />
  );
}

export default React.memo(HTMLView);
